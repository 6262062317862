import SchoolSelection from "../SchoolSelection/SchoolSelection";
import MenuCategories from "./MenuCategories";
import { MenuModel } from "./MenuModel";

const Menu:React.FC=()=>
{
const viewModel=MenuModel()


    return( <>  <div className="container-fluid">
    <div className="row">
        <div className="col-12">
            <div className="header-nav black">
                <div className="header-nav-left">

                    {

viewModel.selectedSchool==null?

(
<button className="dropdown-category" data-bs-toggle="modal" data-bs-target="#locationModal">
                    <span><i className="fas fa-school"></i> Select School</span>
                    </button>

)
:

(


<button className="dropdown-category p-0" data-bs-toggle="modal" data-bs-target="#locationModal">
                              
                              
                              <img src={`${viewModel.schoolImagePath}${viewModel.selectedSchool.image}.webp?${new Date().getTime()}`} title={viewModel.selectedSchool.school_name} alt={viewModel.selectedSchool.school_name} 
                             className="selected-school-image"
                              />
                          <span style={{    margin: '5px'}}> {viewModel.selectedSchool.school_name}</span>
                          </button>
    

    // <button className="dropdown-category" data-bs-toggle="modal" data-bs-target="#locationModal">
    //        <span><img   src={`${viewModel.schoolImagePath}${viewModel.selectedSchool.image}.webp?${new Date().getTime()}`} title={viewModel.selectedSchool.school_name} alt={viewModel.selectedSchool.school_name} style={{width:'22px'}}/>{viewModel.selectedSchool.school_name}</span>
      
    //                 </button>
)
                    }
                    
                
                </div>

                <div className="header-nav-middle">
                    <div className="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
                        <div className="offcanvas offcanvas-collapse order-xl-2" id="primaryMenu">
                            <div className="offcanvas-header navbar-shadow">
                                <h5>Menu</h5>
                                <button className="btn-close lead" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                            <ul className="navbar-nav">
                            <li className="nav-item">
                                        <a className="nav-links ps-0" href="/"><i className="fas fa-home"></i> Home</a>
                                    </li>
                             
                                {
viewModel.lstMainCategories.map((citem:any)=>
{

    return(        <li className="nav-item dropdown">
    <a className="nav-link dropdown-toggle" onClick={()=>viewModel.onCategoryClick(citem.category_name)} data-bs-toggle="dropdown">{citem.category_name}</a>
 < >
    
    
{
    
    <MenuCategories lstMainCategories={ viewModel.getChildDetails(citem.category_id)} />
  

// (viewModel.getChildDetails(citem.category_id)).map((item:any)=>
// {

//     return (item.is_parent=='true'?(
    
//         <li className="sub-dropdown-hover">
//         <a href="javascript:void(0)" className="dropdown-item">{item.category_name}</a>
       
//     </li>
    
//         ):
    
    
//     ( <li><a className="dropdown-item" href="#">{citem.category_name}</a> </li>))
// })

}

     
 </>
</li>)


})

                                }
                                           
                            </ul>
                                {/* <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a className="nav-links ps-0" href="index.html"><i className="fas fa-home"></i> Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-links ps-0" href="index.html"><i className="fas fa-user"></i> About Us</a>
                                    </li>

                                    <li className="nav-item dropdown dropdown-mega">
                                        <a className="nav-link dropdown-toggle ps-xl-2 ps-0" href="javascript:void(0)" data-bs-toggle="dropdown"><i className="fas fa-book"></i>&nbsp; Text Books </a>

                                        <div className="dropdown-menu dropdown-menu-2">
                                            <div className="row">
                                                <div className="dropdown-column col-xl-3">
                                          
                                                <ul className="category-list">
                                                                <li className="onhover-category-list">
                                                                    <a href="javascript:void(0)" className="category-name">
                                                                        <h6>Pre-school Textbooks  <i className="fa-solid fa-angle-right"></i></h6>
                                                                    </a>
                                                                    <div className="onhover-category-box">
                                                                        <div className="list-1">
                                                                           
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Potato &amp; Tomato</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Cucumber &amp; Capsicum</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                               
                                                                            </ul>
                                                                        </div>
                            
                                                                        <div className="list-2">
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Banana &amp; Papaya</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Kiwi, Citrus Fruit</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Apples &amp; Pomegranate</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                             
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>

                                                                <li className="onhover-category-list">
                                                                    <a href="javascript:void(0)" className="category-name">
                                                                        <h6>Nursery Textbooks  <i className="fa-solid fa-angle-right"></i></h6>
                                                                    </a>
                                                                    <div className="onhover-category-box">
                                                                        <div className="list-1">
                                                                            
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Potato &amp; Tomato</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Cucumber &amp; Capsicum</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                               
                                                                            </ul>
                                                                        </div>
                            
                                                                        <div className="list-2">
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Banana &amp; Papaya</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Kiwi, Citrus Fruit</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Apples &amp; Pomegranate</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                             
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>

                                                                <li className="onhover-category-list">
                                                                    <a href="javascript:void(0)" className="category-name">
                                                                        <h6>L.K.G Textbooks  <i className="fa-solid fa-angle-right"></i></h6>
                                                                    </a>
                                                                    <div className="onhover-category-box">
                                                                        <div className="list-1">
                                                                           
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Potato &amp; Tomato</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Cucumber &amp; Capsicum</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                               
                                                                            </ul>
                                                                        </div>
                            
                                                                        <div className="list-2">
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Banana &amp; Papaya</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Kiwi, Citrus Fruit</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Apples &amp; Pomegranate</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                             
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>

                                                                <li className="onhover-category-list">
                                                                    <a href="javascript:void(0)" className="category-name">
                                                                        <h6>UKG Textbooks  <i className="fa-solid fa-angle-right"></i></h6>
                                                                    </a>
                                                                    <div className="onhover-category-box">
                                                                        <div className="list-1">
                                                                           
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Potato &amp; Tomato</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Cucumber &amp; Capsicum</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                               
                                                                            </ul>
                                                                        </div>
                            
                                                                        <div className="list-2">
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Banana &amp; Papaya</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Kiwi, Citrus Fruit</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Apples &amp; Pomegranate</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="javascript:void(0)">Leafy Vegetables</a>
                                                                                </li>
                                                                             
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                            
                                                             
                                                            </ul>
                                                </div>

                                                <div className="dropdown-column col-xl-3">
                                                  
                                                    <a className="dropdown-item" href="product.html">Textbooks</a>

                                                    <a className="dropdown-item" href="product.html">Textbooks</a>

                                                    <a href="product.html" className="dropdown-item">Textbooks</a>

                                                    <a className="dropdown-item" href="product.html">Textbooks</a>

                                                    <a className="dropdown-item" href="product.html">Textbooks</a>

                                                    <a className="dropdown-item" href="product.html">Textbooks</a>

                                                    <a href="product.html" className="dropdown-item">Textbooks</a>
                                                </div>

                                                <div className="dropdown-column col-xl-3">
                                                 
                                                    <a className="dropdown-item" href="product.html">Textbooks</a>

                                                    <a className="dropdown-item" href="product.html">Textbooks</a>

                                                    <a href="product.html" className="dropdown-item">Textbooks</a>

                                                    <a className="dropdown-item" href="product.html">Textbooks</a>

                                                    <a className="dropdown-item" href="product.html">Textbooks</a>

                                                    <a className="dropdown-item" href="product.html">Textbooks</a>

                                                    <a href="product.html" className="dropdown-item">Textbooks</a>
                                                </div>

                                                <div className="dropdown-column dropdown-column-img col-3"></div>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="nav-item dropdown dropdown-mega">
                                        <a className="nav-link dropdown-toggle ps-xl-2 ps-0" href="javascript:void(0)" data-bs-toggle="dropdown"><i className="fas fa-pencil-ruler"></i>&nbsp; Stationery</a>

                                        <div className="dropdown-menu dropdown-menu-2">
                                            <div className="row">
                                                <div className="dropdown-column col-xl-3">
                                                  
                                                    <a className="dropdown-item" href="product.html">Pencil</a>

                                                    <a className="dropdown-item" href="product.html">Pencil</a>

                                                    <a href="product.html" className="dropdown-item">Pencil</a>

                                                    <a className="dropdown-item" href="product.html">Pencil</a>

                                                    <a className="dropdown-item" href="product.html">Pencil</a>

                                                    <a className="dropdown-item" href="product.html">Pencil</a>

                                                    <a href="product.html" className="dropdown-item">Pencil</a>
                                                </div>

                                                <div className="dropdown-column col-xl-3">
                                                   
                                                     <a className="dropdown-item" href="product.html">Pencil</a>

                                                     <a className="dropdown-item" href="product.html">Pencil</a>

                                                     <a href="product.html" className="dropdown-item">Pencil</a>

                                                     <a className="dropdown-item" href="product.html">Pencil</a>

                                                     <a className="dropdown-item" href="product.html">Pencil</a>

                                                     <a className="dropdown-item" href="product.html">Pencil</a>

                                                     <a href="product.html" className="dropdown-item">Pencil</a>
                                                </div>

                                                <div className="dropdown-column col-xl-3">
                                                   
                                                    <a className="dropdown-item" href="product.html">Pencil</a>

                                                    <a className="dropdown-item" href="product.html">Pencil</a>

                                                    <a href="product.html" className="dropdown-item">Pencil</a>

                                                    <a className="dropdown-item" href="product.html">Pencil</a>

                                                    <a className="dropdown-item" href="product.html">Pencil</a>

                                                    <a className="dropdown-item" href="product.html">Pencil</a>

                                                    <a href="product.html" className="dropdown-item">Pencil</a> 
                                                </div>

                                                <div className="dropdown-column dropdown-column-img col-3"></div>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="nav-item dropdown dropdown-mega">
                                        <a className="nav-link dropdown-toggle ps-xl-2 ps-0" href="javascript:void(0)" data-bs-toggle="dropdown"><i className="fas fa-tshirt"></i>&nbsp; School Uniform</a>

                                        <div className="dropdown-menu dropdown-menu-2">
                                            <div className="row">
                                                <div className="dropdown-column col-xl-3">
                                                  
                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a href="product.html" className="dropdown-item">Uniform</a>

                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a href="product.html" className="dropdown-item">Uniform</a>
                                                </div>

                                                <div className="dropdown-column col-xl-3">
                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a href="product.html" className="dropdown-item">Uniform</a>

                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a href="product.html" className="dropdown-item">Uniform</a>
                                                </div>

                                                <div className="dropdown-column col-xl-3">
                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a href="product.html" className="dropdown-item">Uniform</a>

                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a className="dropdown-item" href="product.html">Uniform</a>

                                                    <a href="product.html" className="dropdown-item">Uniform</a>
                                                </div>

                                                <div className="dropdown-column dropdown-column-img col-3"></div>
                                            </div>
                                        </div>
                                    </li>

                                   
                                <li className="nav-item">
                                    <a className="nav-links ps-0" href="contact-us"><i className="fas fa-map-marker-alt"></i> Contact Us</a>
                                </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-nav-right">
                    {/* <button className="btn deal-button" data-bs-toggle="modal" data-bs-target="#deal-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
                        <span>Deals</span>
                    </button> */}
                </div>
            </div>
        </div>
    </div>
</div>


<div className="modal location-modal fade theme-modal" id="locationModal" tabIndex={-1}
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  >
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="modal-body">
                   <SchoolSelection></SchoolSelection>
                </div>
            </div>
        </div>
    </div>


</>

);
}
export default Menu;