 
import { UserState } from "../../repository/UserState";
import { CartModel } from "../Cart/CartModel";
import CartPopUp from "../Cart/CartPopUp";
import Menu from "../Menu/Menu";

const Header:React.FC=()=>
{
    const userState=UserState();
    const viewModel=CartModel();
return (<>
   
    <header className="pb-md-1 pb-0 white">
        <div className="header-top">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-3 d-xxl-block d-none">
                        <div className="top-left-header">
                            {/* <i className="iconly-Location icli text-white"></i>
                            <span className="text-white">1418 Riverwood Drive, CA 96052, US</span> */}
                        </div>
                    </div>

                    <div className="col-xxl-6 col-lg-9 d-lg-block d-none my-auto">
                        <div className="header-offer">
                            <h6 className="welcome">Welcome to : Education Today India! </h6>
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <ul className="social-icon text-end">
                            <li className="light-bg">
                                <a href="https://www.facebook.com/" target="_blank" className="footer-link-color">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li className="light-bg">
                                <a href="https://twitter.com/i/flow/login" target="_blank" className="footer-link-color">
                                    <i className="fab fa-twitter"></i>
                                </a>
                            </li>
                            <li className="light-bg">
                                <a href="https://www.instagram.com/" target="_blank" className="footer-link-color">
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </li>
                            <li className="light-bg">
                                <a href="https://in.pinterest.com/" target="_blank" className="footer-link-color">
                                    <i className="fab fa-pinterest-p"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="top-nav top-header sticky-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        <div className="navbar-top">
                            <button className="navbar-toggler d-xl-none d-inline navbar-menu-button" type="button" data-bs-toggle="offcanvas" data-bs-target="#primaryMenu">
                                <span className="navbar-toggler-icon">
                                    <i className="fa-solid fa-bars"></i>
                                </span>
                            </button>
                            <a href="/" className="web-logo nav-logo">
                                <img src="/images/logo/1.png" className="img-fluid blur-up lazyloaded" alt=""/>
                            </a>

                            <div className="middle-box">
                                <div className="search-box" data-bs-toggle="modal" data-bs-target="#search-box">
                                    <div className="input-group">
                                        <input type="search" className="form-control" placeholder="I'm searching for..." aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                        <button className="btn" type="button" id="button-addon2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="rightside-box">
                                <div className="search-full">
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-search font-light"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        </span>
                                        <input type="text" className="form-control search-type" placeholder="Search here.."/>
                                        <span className="input-group-text close-search">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x font-light"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                        </span>
                                    </div>
                                </div>
                                <ul className="right-side-menu">
                                    <li className="right-side">
                                        <div className="delivery-login-box">
                                            <div className="delivery-icon">
                                                <div className="search-box">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="right-side">
                                        <a href="contact-us.html" className="delivery-login-box">
                                            <div className="delivery-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-phone-call"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                            </div>
                                            <div className="delivery-detail">
                                                <h6>24/7 Delivery</h6>
                                                <h5>+91 888 104 2340</h5>
                                            </div>
                                        </a>
                                    </li>
                                    {userState.loggedUser.customer_id>0?
                                            (
                                    <li className="right-side">
                                        <a href="/wishlist" className="btn p-0 position-relative header-wishlist">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>

                                            <span id="wishListProducts" className="position-absolute top-0 start-100 translate-middle badge">0</span>

                                        </a>
                                    </li>):''}
                                    <li className="right-side">
                                        <div className="onhover-dropdown header-badge">
                                            <button type="button" className="btn p-0 position-relative header-wishlist" onClick={()=>window.location.href = '/cart'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                                                <span id="cartTotal" className="position-absolute top-0 start-100 translate-middle badge">0
                                                    <span className="visually-hidden">unread messages</span>
                                                </span>
                                            </button>

                                            {/* <div className="onhover-div">
                                               <CartPopUp></CartPopUp>
                                            </div> */}
                                        </div>
                                    </li>
                                    <li className="right-side onhover-dropdown">
                                        <div className="delivery-login-box">
                                            <div className="delivery-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                            </div>
                                            <div className="delivery-detail">
                                                <h6>Hello,</h6>
                                                <h5>{userState.loggedUser.customer_id==0?'Guest':(userState.loggedUser.first_name+' '+userState.loggedUser.last_name)}</h5>
                                            </div>
                                        </div>

                                        <div className="onhover-div onhover-div-login">



                                            {userState.loggedUser.customer_id>0?
                                            (
                                            
                                               <ul className="user-box-name">
                                                <li className="product-box-contain">
                                                    <i></i>
                                                    <a href="/myorders">My Orders</a>
                                                </li>

                                                <li className="product-box-contain">
                                                    <a href="account.html">My Account</a>
                                                </li>

                                                <li className="product-box-contain">
                                                    <a onClick={()=>userState.onLogOut()}>Logout</a>
                                                </li>
                                                </ul>
                                            
                                                )
                                            :
                                            ( <ul className="user-box-name">
                                                <li className="product-box-contain">
                                                    <i></i>
                                                    <a onClick={()=>userState.onLogin()}>Log In</a>
                                                </li>

                                                <li className="product-box-contain">
                                                    <a onClick={()=>userState.onSignup()}>Register</a>
                                                </li>

                                                
                                            </ul>)
                                            
                                            }
                                           
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<Menu></Menu>
     
    </header>
    
    <div className="mobile-menu d-md-none d-block mobile-cart">
        <ul>
            <li className="active">
                <a href="/">
                    <i className="iconly-Home icli"></i>
                    <span>Home</span>
                </a>
            </li>

            <li className="mobile-category">
                <a href="javascript:void(0)">
                    <i className="iconly-Category icli js-link"></i>
                    <span>Category</span>
                </a>
            </li>

            <li>
                <a href="search.html" className="search-box">
                    <i className="iconly-Search icli"></i>
                    <span>Search</span>
                </a>
            </li>

            <li>
                <a href="/wishlist" className="notifi-wishlist">
                    <i className="iconly-Heart icli"></i>
                    <span>My Wish</span>
                </a>
            </li>

            <li>
                <a href="cart.html">
                    <i className="iconly-Bag-2 icli fly-cate"></i>
                    <span>Cart</span>
                </a>
            </li>
        </ul>
    </div>
    <div className="fixed-tabs-Whats">
        <div className="aba-whatsphone">
          <div className="aba-whatsphone-icone">
            <a target="_blank" href="https://api.whatsapp.com/send?phone=00 00 999999999">Education Today India<br/><strong>How Can I Help you?</strong></a>          
          </div>
        </div>          
      </div>

</>);

}
export default Header;