import { useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { SignupModel } from "./SignupModel";

const Signup:React.FC=()=>
{
    
    const { returnPath } = useParams<{ returnPath: string }>();

const viewModel=SignupModel(typeof(returnPath)!='undefined'?returnPath:'');
    return (<> <Header></Header>
    
    <section className="log-in-section section-b-space white">
        <div className="container-fluid w-100">
            <div className="row">
                <div className="col-xxl-4 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                    <div className="image-contain">
                        <img src="/images/inner-page/sign-up.png" className="img-fluid" alt=""/>
                    </div>
                </div>

                <div className="col-xxl-6 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                    <div className="log-in-box">
                        <div className="log-in-title">
                            <h3>Welcome To Education Today India</h3>
                            <h4>Create New Account</h4>
                        </div>
                        {/* onkeyup="reg_first_name1(this.value)" */}
                        <div className="input-box">
                            <div   className="row g-4"> 
                                <div className="col-md-6 col-12">
                                    <div className="form-floating theme-form-floating">
                                        <input type="text" className="form-control" 
                                        
                                        name="first_name" value={viewModel.userDetails.first_name} onChange={viewModel.handleChange} 

    
                                          placeholder="First Name"/>
                                        <label >First Name</label>
                                        <label id="firstname_error" className="error-show">Please Enter First Name!</label>
                                    </div>
                                    {((viewModel.userDetails.first_name=='' ) && viewModel.submited) ?(<label  className="m-0 text-danger">First Name required.</label>):''}
                                </div>

                                <div className="col-md-6 col-12">
                                    <div className="form-floating theme-form-floating">
                                        <input type="text" className="form-control"  

name="last_name" value={viewModel.userDetails.last_name} onChange={viewModel.handleChange} 


                                          placeholder="Last Name"/>
                                        <label >Last Name</label>
                                        <label id="firstname_error" className="error-show">Please Enter Last Name!</label>
                                    </div>
                                    {((viewModel.userDetails.last_name=='' ) && viewModel.submited) ?(<label  className="m-0 text-danger">Last Name required.</label>):''}
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="form-floating theme-form-floating">
                                        <input type="email" className="form-control" 
                                        
                                        name="email_id" value={viewModel.userDetails.email_id} onChange={viewModel.handleChange} 


                                        //  onkeyup="reg_email1(this.value)" 
                                         
                                         placeholder="Email"/>
                                        <label >Email</label>
                                        <label id="email_error" className="error-show">Please Enter Your Email Id!</label>

                                        
                                    </div>
                                    {((viewModel.userDetails.email_id!='' &&  !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(viewModel.userDetails.email_id) ) && viewModel.submited) ?(<label  className="m-0 text-danger">Invalid email.</label>):''}

                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="form-floating theme-form-floating">
                                        <input className="form-control" 
                                        type="number" maxLength={11}
                                        name="mobile_number" value={viewModel.userDetails.mobile_number} 
                                        onChange={viewModel.handleNumberChange} 
                                        // onkeyup="reg_pnumber1(this.value)"
                                        placeholder="Phone Number"/>

                                        <label >Phone Number</label>
                                        <label id="pnumber_error" className="error-show">Please Enter Your Phone Number!</label>
                                    </div>
                                    {((viewModel.userDetails.mobile_number=='' ) && viewModel.submited) ?(<label  className="m-0 text-danger"> Phone Number required.</label>):''}

                                    {((!viewModel.validateMobile(viewModel.userDetails.mobile_number) ) && viewModel.submited && viewModel.userDetails.mobile_number!='') ?(<label  className="m-0 text-danger">invalid Phone Number.</label>):''}

                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="form-floating theme-form-floating">
                                        <input type="password" className="form-control"  

name="password" value={viewModel.userDetails.password} onChange={viewModel.handleChange} 

                                        //  onkeyup="reg_password1(this.value)"
                                          placeholder="Password"/>
                                        <label >Password</label>
                                        <label id="password_error" className="error-show">Please Enter Password!</label>
                                    </div>
                                    {((viewModel.userDetails.password=='' ) && viewModel.submited) ?(<label  className="m-0 text-danger">Password required.</label>):''}
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="form-floating theme-form-floating">
                                        <input type="password" className="form-control"  
                                        
                                        name="re_password" value={viewModel.userDetails.re_password} onChange={viewModel.handleChange} 

                                        //  onkeyup="reg_password1(this.value)"
                                          placeholder="Password"/>
                                        <label >Re-Password</label>
                                        <label id="password_error" className="error-show">Please Enter Password!</label>
                                    </div>
                                    {((viewModel.userDetails.re_password=='' ) && viewModel.submited) ?(<label  className="m-0 text-danger">Re-Password required.</label>):''}
                                    {((viewModel.userDetails.password!='' &&  viewModel.userDetails.re_password!='' && viewModel.userDetails.password!=viewModel.userDetails.re_password ) && viewModel.submited) ?(<label  className="m-0 text-danger">Password not matched.</label>):''}

                                </div>


                                

                                {/* <div className="col-md-6 col-12">
                                    <div className="forgot-box">
                                        <div className="form-check ps-0 m-0 remember-box">
                                            <input className="checkbox_animated check-box" type="checkbox" id="flexCheckDefault"/>
                                            <label className="form-check-label"  >I agree with
                                                <span>Terms</span> and <span>Privacy</span></label>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-md-6">
                                    <h4> have an account? <a onClick={()=>viewModel.onLoginClick()}>Log In</a></h4>
                                </div>

                                <div className="col-12">
                                    <button className="btn btn-animation w-100"  onClick={()=>viewModel.onSignup()} >Sign Up</button>
                                </div>
                               
                               
                            </div>
                        </div>

                  
                    </div>
                </div>

                <div className="col-xxl-7 col-xl-6 col-lg-6"></div>
            </div>
        </div>
    </section>
    
    <Footer></Footer>
    </>)
}
export default Signup;