import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './pages/Home/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProductSearchAndFilter from './pages/ProductSearchAndFilter/ProductSearchAndFilter';
import ProductDetails from './pages/ProductDetails/ProductDetails';
import Cart from './components/Cart/Cart';

import Signup from './pages/Signup/Signup';
import Login from './pages/Login/Login';
import Checkout from './pages/Checkout/Checkout';
import MyOrders from './pages/MyOrders/MyOrders';
import OrderResult from './pages/OrderResult/OrderResult';
import WishList from './pages/Wishlist/Wishlist';
import OrderDetails from './pages/MyOrders/OrderDetails';
import PaymentGateway from './pages/PaymentGateway/PaymentGateway';
import NotFound from './pages/NotFound';

const App: React.FC = () => {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/products" element={<ProductSearchAndFilter />} />
      <Route path="/products/:filterData" element={<ProductSearchAndFilter />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/wishlist" element={<WishList />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/signup/:returnpath" element={<Signup />} />
      <Route path="/login/:returnpath" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/productdetails" element={<ProductDetails />} />
      <Route path="/productdetails/:sku" element={<ProductDetails />} />
      <Route path="/:schoolname" element={<Home />} />
      <Route path="/myorders" element={<MyOrders />} />
      <Route path="/orderdetails" element={<OrderDetails />} />
      <Route path="/paymentprocess" element={<PaymentGateway />} />
      
      <Route path="/orderresult/:transactionid" element={<OrderResult />} />
      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  </Router>
    );
  };
  
  export default App;
  