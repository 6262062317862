import { SearchProductsModel } from "./SearchProductsModel";

const SearchProducts:React.FC=()=>
{
const viewModel=SearchProductsModel();

    return (<>
    <div className="modal-dialog modal-fullscreen">
        <div className="modal-content  page-width">
            <div className="modal-header01">
               
              <div className="input-group">
                <span className="input-group-text b-n bg-n" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i></span>
                <input type="text" className="form-control b-n" placeholder="Search here" aria-label="Username" aria-describedby="basic-addon1" onChange={(e)=>viewModel.onProductSearch(e)}/>
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="ctrProductSearchClose"></button>
            </div>
            <div className="modal-body" style={{overflowX: 'inherit'}}>
                <div className="row">
                    <div className="col-md-12"  style={viewModel.lstSearchProducts.length==0?{display:'block'}:{display:'none'}}>
                    <div className="autocomplete-item-section">
                    <div className="autocomplete-item-head Categories" data-index="1" >
                     Categories 
                    </div>
                </div>
                    <div className="row mb-30 cat-bgs">
                      <h4 className="mb-2 d-block d-md-none"  style={viewModel.lstSearchProducts.length==0?{display:'block'}:{display:'none'}}>Categories</h4>

                      {
                       viewModel.lstSearchProducts.length==0?

viewModel.lstCategories.map((categories:any)=>


{
return ( <div className="col-md-1 col-4" onClick={()=>viewModel.onCategoryClick(categories.category_name)}>
<img src={viewModel.imagePath+categories.category_image+'.webp'} className="categories-img"/>  
<div className="grid-product__meta">
    <div className="grid-product__title">{categories.category_name}</div>
</div>  
</div>)


}

):''
                      }
                       
                       
    
                    </div> 
                    </div>

                    <div className="col-md-12" style={viewModel.lstSearchProducts.length==0?{display:'block'}:{display:'none'}}>
                    <div className="autocomplete-item-section-class">
                        <div className="autocomplete-item-head-class Categories" data-index="1"  >
                            Select Class 
                        </div>
                    </div> 
                    <div className="row mb-50 class-row-bg">
                        <h4 className="mb-2 d-block d-md-none" style={viewModel.lstSearchProducts.length==0?{display:'block'}:{display:'none'}}>Select Class</h4>

                        {
 viewModel.lstSearchProducts.length==0?

                            viewModel.lstClasses.map((classDetails:any,i)=>{

                                return (  <div className="col-md-1 col-4" onClick={()=>viewModel.onClassClicked(classDetails.classes)}>
                                <div className="class-bg">
                                <img src={`/images/class/${((i+1) % 6) + 1}.png`} className="categories-img"/>  
                                <div className="grid-product__meta">
                                    <div className="grid-product__title class-search">{classDetails.classes}</div>
                                </div>  
                            </div>
                            </div>)
                            }):''
                        }
                      
                      
    
                    </div>  
                </div>
                </div>

                <div className="row"> 
                    <div className="col-md-2">
                        <ul>
                            <li className="autocomplete-item-head-main" data-index="0"  style={viewModel.lstSearchProducts.length>0?{display:'block'}:{display:'none'}}  > Categories</li>
                            {
                                viewModel.lstSearchProducts.length>0?
                                viewModel.lstResultCategories.map((categories:any)=>
                                {

                                    return (  <li className="autocomplete-link">{categories.category_name}</li>)
                                }):''
                            }
                          
                          
                             
                            <li className="autocomplete-item-head-main" data-index="0"  style={viewModel.lstSearchProducts.length>0?{display:'block'}:{display:'none'}}> Class</li><br/>

                            {
                                viewModel.lstSearchProducts.length>0?
                                viewModel.lstResultClasses.map((classD:any)=>
                                {

                                    return (  <li className="autocomplete-link">{classD.class}</li>)
                                }):''
                            }
                          


                           

                        </ul>
                    </div>
                    <div className="col-md-10">
                        
                        <div className="row">

                            {

                                viewModel.lstSearchProducts.map((product:any)=>
                                {

                                    return (  <div className="col-md-2 col-6" onClick={()=>viewModel.onProductClick(product)}>



                                    <img 
                                    src={ product.product_resources.length>0? ((viewModel.imagePath+product.image)+'.webp?crop=0.79555xw:1xh;center,top&resize=2048:*'):''}
                                    className="search-img"/>  
                                    <div className="grid-product__meta" >
                                        <div className="grid-product__title">{product.title}</div>
                                        
                                        <div className="grid-product__price">
                                          <span className="visually-hidden">Regular price</span>
                                          <span className="grid-product__price--original text-danger" style={((+product.mrp)-(+product.selling_price))>0? {display:'unset'}:   {display:'none'}}>Rs.{(+product.mrp)}</span>
                                          <span className="visually-hidden">Sale price</span>
                                          Rs.{(+product.selling_price)}
                                          
                                        </div>
                                     </div> 
                                   </div>)
                                })
                            }
                       
                         
                        </div>
                        
                    </div>
                </div>
            </div>

          </div>
    
</div></>)
}
export default SearchProducts;