import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { CartModel } from "./CartModel";
import { CartViewModel } from "./CartViewModel";

const Cart:React.FC=()=>
{
const viewCart=CartViewModel();
    const viewModel=CartModel();
return (<> <Header></Header>

<section className="cart-section section-b-space white">
        <div className="container-fluid">
            <div className="row g-sm-5 g-3">
                <div className="col-xxl-9">
                    <div className="cart-table">
                        <div className="table-responsive-xl">
                            <table className="table">
                                <tbody>
{
    viewModel.lstCartProducts.map((product:any,i)=>
    {

        return ( <tr className="product-box-contain">
        <td className="product-detail">
            <div className="product border-0">
                <a href="#" className="product-image">
                    <img src={ product.product_resources.length>0? ((viewModel.imagePath+product.image)+'.webp'):''}  
                        className="img-fluid blur-up lazyload" alt=""/>
                </a>
                <div className="product-detail" onClick={()=> viewModel.onProductClick(product.sku_id)}>
                    <ul>
                        <li className="name">
                            <a href="#">{product.title}</a>
                        </li>

                        <li className="text-content"><span className="text-title">Brand
                                :</span> {product.brand_name}</li>

                        {/* <li className="text-content"><span
                                className="text-title">Quantity</span> - 2</li> */}

                        {/* <li>
                            <h5 className="text-content d-inline-block">Price :</h5>
                            <span>Rs {product.mrp}</span>
                            <span className="text-content">Rs {product.selling_price}</span>
                        </li> */}

                        {/* <li>
                            <h5 className="saving theme-color">Saving : Rs {(+product.mrp)-(+product.selling_price)}</h5>
                        </li>

                        <li className="quantity-price-box">
                            <div className="cart_qty">
                                <div className="input-group">
                                    <button type="button" className="btn qty-left-minus"
                                        data-type="minus" data-field="">
                                        <i className="fa fa-minus ms-0"
                                            aria-hidden="true"></i>
                                    </button>
                                    <input className="form-control input-number qty-input"
                                        type="text" name="quantity" value="1"/>
                                    <button type="button" className="btn qty-right-plus"
                                        data-type="plus" data-field="">
                                        <i className="fa fa-plus ms-0"
                                            aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </li> */}

                        {/* <li>
                            <h5>Total: Rs {((+product.qty)* (+product.selling_price))}</h5>
                        </li> */}
                    </ul>
                </div>
            </div>
        </td>

        <td className="price">
            <h4 className="table-title text-content">Price</h4>
            <h5>Rs {(+product.selling_price)} <del style={((+product.mrp)-(+product.selling_price))>0? {display:'block'}:   {display:'none'}} className="text-content">Rs {(+product.mrp)}</del></h5>
            <h6 className="theme-color" style={((+product.mrp)-(+product.selling_price))>0? {display:'block'}:   {display:'none'}}>You Save : Rs {(+product.mrp)-(+product.selling_price)}</h6>
        </td>

        <td className="quantity">
            <h4 className="table-title text-content">Qty</h4>
            <div className="quantity-price">
                <div className="cart_qty">
                    <div className="input-group">
                        <button type="button" className="btn qty-left-minus" onClick={()=>viewModel.onQtyMinusClick(i)}
                            data-type="minus" data-field="">
                            <i className="fa fa-minus ms-0" aria-hidden="true"></i>
                        </button>
                        <input className="form-control input-number qty-input" type="text"
                            name="quantity" value={product.qty}/>
                        <button type="button" className="btn qty-right-plus"  onClick={()=>viewModel.onQtyPluseClick(i)}
                            data-type="plus" data-field="">
                            <i className="fa fa-plus ms-0" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </td>

        <td className="subtotal">
            <h4 className="table-title text-content">Total</h4>
            <h5>Rs {((+product.qty)* (+product.selling_price))}</h5>
        </td>

        <td className="save-remove">
            <h4 className="table-title text-content">Action</h4>
            {/* <a className="save notifi-wishlist" href="javascript:void(0)">Save for later</a> */}
            <a className="remove close_button" onClick={()=>viewModel.removeProduct(i)}>Remove</a>
        </td>
    </tr>)
    })
}


                                   

                                   
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-3">
                    <div className="summery-box p-sticky">
                        <div className="summery-header">
                            <h3>Cart Total</h3>
                        </div>

                        <div className="summery-contain">
                           
                            <ul>
                                <li>
                                    <h4>Subtotal</h4>
                                    <h4 className="price">Rs {viewModel.lstCartProducts.reduce((total:any, product:any) => total+ ((+product.qty)* (+product.mrp)), 0)}</h4>
                                </li>

                                <li>
                                    <h4>Discount</h4>
                                    <h4 className="price">(-) {viewModel.lstCartProducts.reduce((total:any, product:any) => total+ ((+product.qty)* ( (+product.mrp)-(+product.selling_price))), 0)}</h4>
                                </li>

                                {/* <li className="align-items-start">
                                    <h4>Shipping</h4>
                                    <h4 className="price text-end">$6.90</h4>
                                </li> */}
                            </ul>
                        </div>

                        <ul className="summery-total">
                            <li className="list-total border-top-0">
                                <h4>Total (INR)</h4>
                                <h4 className="price theme-color">Rs {viewModel.lstCartProducts.reduce((total:any, product:any) => total+ ((+product.qty)* (+product.selling_price)), 0)}</h4>
                            </li>
                        </ul>

                        <div className="button-group cart-button">
                            <ul>
                                <li>
                                    <button onClick={()=>viewModel.onCheckoutClick()}
                                        className="btn btn-animation proceed-btn fw-bold">Proceed to Checkout</button>
                                </li>

                                <li>
                                    <button  onClick={()=>window.location.href = '/'}
                                        className="btn btn-light shopping-button text-dark">
                                        <i className="fa-solid fa-arrow-left-long"></i>Return To Shopping</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>



 <Footer></Footer></>)

}
export default Cart;