import { useEffect, useState } from "react"
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import { useNavigate } from "react-router-dom";

export function MenuModel()
{
    const repository =SchoolRepository();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const schoolImagePath=repository.schoolImagePath;
    const selectedSchool = useSelector((state: AppState) =>
    {
    // //debugger;; 

     
      return  typeof(state.storeData.SelectdEcomSchool)!='undefined'?state.storeData.SelectdEcomSchool:null;
    }
    );

   const [lstMenu,setMenu]=useState([]);
   const [lstMainCategories,setMainCategories]=useState([]);


useEffect(()=>
{
 
    getMenuCategories();
 
    
},[]);

 
const getMainCategories=(lstCategories:any)=>
{
 // //debugger;;
  if( lstCategories!=null &&  lstCategories.length>0)
  {
    setMainCategories(lstCategories.filter((x:any)=> x.parent_category_id==0))

  }
}
const getMenuCategories=async ()=>
{
   // //debugger;;

   await repository.getMenuCategories().then((result:any)=>
   {
 //   //debugger;;
    dispatch({
      type: ActionType.STORE,
      payload: {
        storeData: result,
        viewName: 'categories',
      },
    });

     setMenu( result);
     getMainCategories(result);
   });

    //let cat=
    // setMenu( await repository.getMenuCategories()) ;
   
}
const lstStoreCat = useSelector((state: AppState) =>
{
//    / //debugger;; 
return  typeof(state.storeData.categories)!='undefined'?state.storeData.categories:null;
}
);

const getChildDetails=(category_id:number):any[]=>
{
////debugger;;

 if(lstStoreCat==null || lstStoreCat.length==0 )
 {
  return [];
 }

let res= lstStoreCat.filter((x:any)=> x.parent_category_id==category_id);
return res;


}

const onCategoryClick=(categoryName:any)=>
{
  //debugger;;
  
  navigate('/products/'+JSON.stringify( {'type':'category','name':categoryName}));
}

    return{onCategoryClick,selectedSchool,schoolImagePath,lstMainCategories,getChildDetails}

}
 

