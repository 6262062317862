import { useEffect, useState } from "react"
import { SchoolRepository } from "../../repository/SchoolRepository";

export  function  BannerModel()
{
const repository=SchoolRepository();

useEffect(()=>
{
    gerBanners();
},[alert]);
const [lstBanners,setBanners]=useState([]);
const gerBanners=()=>
{
    //debugger;;
    repository.getBanners().then((result:any)=>
    {
        
        //debugger;;
if( typeof(result)!='undefined' &&  result.length>0)
{
    setBanners(result);

}


    });
}
const imagePath= repository.ecommerceImagePath;
return {lstBanners,imagePath}

}