import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { MyOrdersModel } from "./MyOrdersModel";

const MyOrders:React.FC=()=>
{
const viewModel=MyOrdersModel();

return (<>
 <Header></Header>
<section className="breadscrumb-section pt-0">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="breadscrumb-contain">
                        <h2>My Orders</h2>
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <a href="index.html">
                                        <i className="fa-solid fa-house"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">My Orders</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="log-in-section background-image-2 section-b-space white">
        <div className="container">
            <div className="row">
         

                <div className="col-xxl-12 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                    <div className="faq-accordion">
                        <div className="accordion" id="accordionExample">

{
viewModel.orderDetails.map((orders:any,i)=>
{


return ( <div className="accordion-item">
<h2 className="accordion-header" id={"headingOne"+i}>
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne"+i} aria-expanded="false" aria-controls="collapseOne">
        <div className="row w-100">
            <div className="col-xxl-4 col-xl-5 col-lg-6">
            <h5><strong className="order-title">Order ID : 
            <span className="text-dark">{orders.order_id}</span> </strong></h5>
            </div>
         
            <div className="col-xxl-4 col-xl-5 col-lg-6">
                <h5><strong className="order-title"> Date : <span className="text-dark">{orders.order_datetime}</span> </strong> </h5>
            </div>
             <div className="col-xxl-4 col-xl-5 col-lg-6">
             <h5><strong className="order-title"> Status : <span className="text-dark">{orders.order_status} </span> </strong> </h5>
             </div>
            </div>
        
    </button>
</h2>
<div id={"collapseOne"+i} className="accordion-collapse collapse" aria-labelledby={"headingOne"+i} data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <div className="row">

<div className="col-md-8"> <h3 className="f-22 p-10">Shipping Details</h3></div>
<div  className="col-md-2"><button   onClick={()=>viewModel.onOrderReturnClick(orders)} className="btn btn-animation proceed-btn fw-bold mx-auto">Return </button>
</div>
        </div>
       

        <div className="table-responsive">
            <table className="table border order-tab-table">
                <thead>
                    <tr>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Apartment</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Zip Code</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{orders.ship_customer_full_name}</td>
                        <td>{orders.email_id}</td>
                        <td>{orders.ship_road_area_colony}</td>
                        <td>{orders.ship_house_no_bulding_name}</td>
                        <td>{orders.bill_city}</td>
                        <td>{orders.state_name}</td>
                        <td>{orders.ship_pincode}</td>
                    </tr>

                  
                </tbody>
            </table>
        </div>
        
        <h3 className="f-22 p-10">Product Details</h3>
        <div className="table-responsive">
            <table className="table border order-tab-table">
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Product Name</th>
                        <th>Qty</th>
                        <th>Unit Price</th>
                        <th>Total</th>
                        <th>Rating</th>
                       
                    </tr>
                </thead>
                <tbody>
{
    orders.orderDetails.map((orderPoducts:any)=>
    {

return (

    <tr>
    <td className="product-detail">
        <div className="product border-0">
            <img src={((viewModel.imagePath+orderPoducts.image)+'.webp')} className="img-fluid blur-up lazyloaded w-70" alt=""
             />
        </div>
    </td>
    <td>{orderPoducts.title}<br></br>{orderPoducts.variants}</td>
    <td>{orderPoducts.qty}</td>
    <td>{orderPoducts.selling_price}</td>
    <td>Rs {orderPoducts.product_total}</td>
    <td>
        {/* <ul className="rating rating-orders">
            <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-star fill"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </li>
            <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-star fill"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </li>
            <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-star fill"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </li>
            <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </li>
            <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </li>
        </ul> */}

    <button data-bs-toggle="modal" data-bs-target="#product-rating" onClick={()=>viewModel.onRateNowClick(orderPoducts)} className="btn btn-animation proceed-btn fw-bold mx-auto">{orderPoducts.rating>0?'View/Change':'Rat now'}</button>
</td>
 
</tr>

)

    })
}



                 

                  
                </tbody>
            </table>
        </div>

      
    </div>
</div>
</div>)

})


}

                           

                           

                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </section>
  

     
    <div className="modal fade theme-modal" id="product-rating" tabIndex={-1} aria-labelledby="exampleModalLabel"
aria-hidden="true">
<div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
    <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel1">Rating </h5>
            <button type="button" id="btnRatingClose" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="fa-solid fa-xmark"></i>
            </button>
        </div>
        <div className="modal-body">
            <div className="row">
                <p className="type">

{
    (+(viewModel.selectedOrderItem as any).rating)==0?

(<> <i className="fa fa-star-o" onClick={()=>viewModel.onRate(1)}></i>
<i className="fa fa-star-o"     onClick={()=>viewModel.onRate(2)}></i>
<i className="fa fa-star-o"     onClick={()=>viewModel.onRate(3)}></i>
<i className="fa fa-star-o"     onClick={()=>viewModel.onRate(4)}></i>
<i className="fa fa-star-o"     onClick={()=>viewModel.onRate(5)}></i>
</>)
    :
    (
        (+(viewModel.selectedOrderItem as any).rating)==1?

        (<>
        <i className="fa fa-star  "onClick={()=>viewModel.onRate(1)}></i>
        <i className="fa fa-star-o"onClick={()=>viewModel.onRate(2)}></i>
        <i className="fa fa-star-o"onClick={()=>viewModel.onRate(3)}></i>
        <i className="fa fa-star-o"onClick={()=>viewModel.onRate(4)}></i>
        <i className="fa fa-star-o"onClick={()=>viewModel.onRate(5)}></i>
        </>):



(
    (+(viewModel.selectedOrderItem as any).rating)==2?

    (<>
    <i className="fa fa-star  "onClick={()=>viewModel.onRate(1)}></i>
    <i className="fa fa-star  "onClick={()=>viewModel.onRate(2)}></i>
    <i className="fa fa-star-o"onClick={()=>viewModel.onRate(3)}></i>
    <i className="fa fa-star-o"onClick={()=>viewModel.onRate(4)}></i>
    <i className="fa fa-star-o"onClick={()=>viewModel.onRate(5)}></i>
    </>):



(
    (+(viewModel.selectedOrderItem as any).rating)==3?

    (<>  <i className="fa fa-star"></i>
    <i className="fa fa-star"></i>
    <i className="fa fa-star"></i>
    <i className="fa fa-star-o"></i>
    <i className="fa fa-star-o"></i>
    </>):



(
    (+(viewModel.selectedOrderItem as any).rating)==4?

    (<> 
     <i className="fa fa-star" onClick={()=>viewModel.onRate(1)} ></i>
    <i className="fa fa-star"  onClick={()=>viewModel.onRate(2)}></i>
    <i className="fa fa-star"  onClick={()=>viewModel.onRate(3)}></i>
    <i className="fa fa-star"  onClick={()=>viewModel.onRate(4)}></i>
    <i className="fa fa-star-o"onClick={()=>viewModel.onRate(5)}></i>
    </>):



(
    (<>  
    <i className="fa fa-star"onClick={()=>viewModel.onRate(1)}></i>
    <i className="fa fa-star"onClick={()=>viewModel.onRate(2)}></i>
    <i className="fa fa-star"onClick={()=>viewModel.onRate(3)}></i>
    <i className="fa fa-star"onClick={()=>viewModel.onRate(4)}></i>
    <i className="fa fa-star"onClick={()=>viewModel.onRate(5)}></i>
    </>)

)


)


)


)

    )




}

               
                </p> 
                
        </div>
            <div className="row">
            <div className="col-md-12">
            <form>
            <div className="form-floating mb-4 theme-form-floating">
                <textarea id="w3review" name="review" rows={4} cols={50} className="w-100"
                 onChange={viewModel.onReviewChange}  
                 value={(viewModel.selectedOrderItem as any).review} ></textarea>
              
            </div>
            </form>
        </div>
        </div>
        <div className="row">
            <div className="col-md-12">
            <form>
            <div className="form-floating mb-4 theme-form-floating">
                <input type="file" className="form-control" id="fname" placeholder="Upload Image" onChange={viewModel.handleImageChange}/>
                <label >Upload Image</label>
            </div>
            </form>
        </div>
        </div>
        <div className="row">
           

            {(viewModel.selectedOrderItem as any).review_image !=''?
            
            <div className="col-md-2">
               
              <img src={viewModel.getImagePath((viewModel.selectedOrderItem as any).review_image)} className="img-fluid blur-up lazyloaded" alt="Selected File" /> 
              <span  className="position-absolute translate-middle badge " style={{    borderRadius: "404px",
    backgroundColor: "red"}}   onClick={()=>viewModel.onRemoveImage("review_image",0)}>X</span>
              </div>

              : ''}

{(viewModel.selectedOrderItem as any).review_image_1 !=''?
            
            <div className="col-md-2">
              <img src={viewModel.getImagePath((viewModel.selectedOrderItem as any).review_image_1)} className="img-fluid blur-up lazyloaded" alt="Selected File" /> 
              <span  className="position-absolute translate-middle badge " style={{    borderRadius: "404px",
    backgroundColor: "red"}}   onClick={()=>viewModel.onRemoveImage("review_image_1",1)}>X</span>
              </div>

              : ''}
{(viewModel.selectedOrderItem as any).review_image_2 !=''?
            
            <div className="col-md-2">
              <img src={viewModel.getImagePath((viewModel.selectedOrderItem as any).review_image_2)} className="img-fluid blur-up lazyloaded" alt="Selected File" /> 
              <span  className="position-absolute translate-middle badge " style={{    borderRadius: "404px",
    backgroundColor: "red"}}   onClick={()=>viewModel.onRemoveImage("review_image_2",2)}>X</span>
              </div>

              : ''}

{(viewModel.selectedOrderItem as any).review_image_3 !=''?
            
            <div className="col-md-2">
              <img src={viewModel.getImagePath((viewModel.selectedOrderItem as any).review_image_3)} className="img-fluid blur-up lazyloaded" alt="Selected File" /> 
              <span  className="position-absolute translate-middle badge " style={{    borderRadius: "404px",
    backgroundColor: "red"}}   onClick={()=>viewModel.onRemoveImage("review_image_3",3)}>X</span>
              </div>

              : ''}


{(viewModel.selectedOrderItem as any).review_image_4 !=''?
            
            <div className="col-md-2">
              <img src={viewModel.getImagePath((viewModel.selectedOrderItem as any).review_image_4)} className="img-fluid blur-up lazyloaded" alt="Selected File" /> 
              <span  className="position-absolute translate-middle badge " style={{    borderRadius: "404px",
    backgroundColor: "red"}}   onClick={()=>viewModel.onRemoveImage("review_image_4",4)}>X</span>
              </div>

              : ''}

             
            
          
        </div>



           
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary btn-md" id="btnRatingClose" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn theme-bg-color btn-md text-white" onClick={()=>viewModel.saveRating()} >Save
                changes</button>
        </div>
    </div>
</div>
</div>

<Footer></Footer>
</>)


}
export default MyOrders;