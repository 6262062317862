import { useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";

import { useNavigate } from "react-router-dom";
import { UserState } from "../../repository/UserState";
import Swal from "sweetalert2";
export function SignupModel(returnPath: any) {

  const repository = SchoolRepository();
  const userState = UserState();
  const navigate = useNavigate();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    debugger;

    if ('value' in e.target) {
      const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
      setUserDetails((prev) => ({ ...prev, [name]: value }));
    } else {
      const { name, value } = e.target as HTMLSelectElement;
      setUserDetails((prev) => ({ ...prev, [name]: value }));
    }
  }


  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>) => {
    debugger;

    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    } else {
      if ('value' in e.target) {
        const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
        setUserDetails((prev) => ({ ...prev, [name]: value }));
      } else {
        const { name, value } = e.target as HTMLSelectElement;
        setUserDetails((prev) => ({ ...prev, [name]: value }));
      }
    }
  }

  const validateMobile = (input: any): boolean => {
    var regexp = new RegExp(/^[6-9]\d{9}$/);
    var res = regexp.test(input)
    return res;
  }

  const [userDetails, setUserDetails] = useState({
    customer_id: 0,
    first_name: '',
    last_name: '',
    mobile_number: '',
    email_id: '',
    profile_image: '',
    password: '',
    re_password: '',

  });

  const [submited, setSubmited] = useState(false);


  const validateSignup = () => {
    let validate = true;
    if (userDetails?.first_name == '') {
      validate = false
    }
    if (userDetails?.last_name == '' && validate) {
      validate = false
    }
    if (userDetails?.mobile_number == '' && validate) {
      validate = false
    }
    if (userDetails.email_id != '' && validate) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      validate = emailRegex.test(userDetails.email_id);
    }
    if (userDetails?.password == '' && validate) {
      validate = false;
    }
    if (userDetails?.re_password == '' && validate) {
      validate = false;
    }

    if (userDetails?.password != userDetails?.re_password && validate) {
      validate = false;
    }
    if (validate && !validateMobile(userDetails.mobile_number)) {
      validate = false;
    }

    return validate;
  }


  const onSignup = async () => {

    debugger;
    setSubmited(true);
    if (validateSignup()) {
      debugger;
      const responseData = await repository.saveCustomer(userDetails);
      debugger;
      if (responseData[0].db_result > 0) {
        debugger;
        userDetails.customer_id = (+responseData[0].customer_id);
        debugger;
        userState.setUserState(userDetails);
        debugger;
        Swal.fire("Registration successfully", "", "success");
        debugger;
        navigate('/' + returnPath);
      }
    }
  }

  const onLoginClick = () => {
    navigate('/login/' + returnPath)
  }

  return { validateMobile, onLoginClick, onSignup, submited, handleChange,handleNumberChange, userDetails }
}