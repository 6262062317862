import { CategoriesModel } from "./CategoriesModel";

const Categories:React.FC=()=>
{
    const viewModel=CategoriesModel();

    return (<section className="banner-section white">
    <div className="container-fluid">
        <div className="title">
            <h2>Shop By Categories</h2>
        </div>
        <div className="row gy-lg-0 gy-3">


            {
                viewModel.lstMainCategories.map((item:any)=>
                {

                    return ( <div className="col-lg-4 mb-20">
                    <div className="banner-contain-3 hover-effect">
                        <div className="bg-size blur-up  lazyloaded class-category-images " style={{ backgroundImage: `url(${viewModel.imagePath + item.category_image+'.webp'})` ,}}>
                            <img src={viewModel.imagePath+item.category_image+'.webp'} className="bg-img blur-up lazyload" alt={item.category_name} style={{display:"none"}}/>
                            <div className="banner-detail banner-detail-2 text-dark p-center-left w-75 banner-p-sm position-relative mend-auto">
                                <div>
                                    {/* <h2 className="fw-normal text-danger"></h2> */}
                                    <h3 className="mb-1 fw-bold category_title">{ viewModel.getAcronym(viewModel.selectedSchool !=null?viewModel.selectedSchool.school_name:'')  } {item.category_name}</h3>
                                    {/* <h4 className="text-content">Offer Of the Week!</h4> */}
                                    <button className="btn btn-md theme-bg-color text-white mt-sm-3 mt-1 fw-bold mend-auto" onClick={()=>viewModel.onCategoryClick(item.category_name)}>Shop Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
                })
            }
           

            
        {/* <div className="col-lg-4">
                <div className="banner-contain-3 hover-effect">
                    <div className="bg-size blur-up  lazyloaded class-category-images "   style={{backgroundImage:'url(/images/grocery/banner/7.jpg)'}} >
                        <img src="/images/grocery/banner/7.jpg" className="bg-img blur-up lazyload" alt="" style={{display:"none"}}/>
                        <div className="banner-detail banner-detail-2 text-dark p-center-left w-75 banner-p-sm position-relative mend-auto">
                            <div>
                                <h2 className="fw-normal text-danger">HPS</h2>
                                <h3 className="mb-1 fw-bold">Stationery</h3>
                                <h4 className="text-content">Offer Of the Week!</h4>
                                <button className="btn btn-md theme-bg-color text-white mt-sm-3 mt-1 fw-bold mend-auto" onClick={()=>window.location.href = '#'}>Shop Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="col-lg-4">
                <div className="banner-contain-3 hover-effect">
                    <div className="bg-size blur-up  lazyloaded class-category-images " style={{backgroundImage:'url(/images/grocery/banner/8.jpg)'}} >
                        <img src="/images/grocery/banner/8.jpg" className="bg-img blur-up lazyload" alt="" style={{display:"none"}}/>
                        <div className="banner-detail banner-detail-2 text-dark p-center-left w-75 banner-p-sm position-relative mend-auto">
                            <div>
                                <h2 className=" fw-normal text-danger">HPS</h2>
                                <h3 className="mb-1 fw-bold">Uniform</h3>
                                <h4 className="text-content">Offer Of the Week!</h4>
                                <button className="btn btn-md theme-bg-color text-white mt-sm-3 mt-1 fw-bold mend-auto" onClick={()=>window.location.href = '#'}>Shop Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            
            </div>
    </div>
</section>);

}
export default Categories;