import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { PaymentGatewayModel } from "./PaymentGatewayModel";

const PaymentGateway:React.FC=()=>
{
    const viewModel=PaymentGatewayModel();
       

    return (
   
        <>
<Header></Header>
<div className="container">
   
    <div className="row">
        <div className="col-md-12">
            <iframe src={viewModel.paymentGatewayUrl}  id="paymentFrame" width="482" height="600" frameBorder={0} scrolling="auto" className="i_frame"></iframe>
        </div>
    </div>
    </div>
    <Footer></Footer>
    </>);

}
export default PaymentGateway;