import { useParams } from "react-router-dom";
import Banners from "../../components/Banners/Banners";
import { CartModel } from "../../components/Cart/CartModel";
import Categories from "../../components/Categories/Categories";
import ClassCategories from "../../components/ClassCategories/ClassCategories";
import CompanyServices from "../../components/CompanyServices/CompanyServices";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header"
import { SchoolSelectionModel } from "../../components/SchoolSelection/SchoolSelectionModel";
import { CartViewModel } from "../../components/Cart/CartViewModel";

const Home:React.FC=()=>
{
    
    const viewCart=CartViewModel();
    const { schoolname } = useParams<{ schoolname: string }>();
    const schoolSelectionModel= SchoolSelectionModel(schoolname);
  
    
    return(
        <>
    <Header></Header>
    
    <Banners></Banners>
    
    <ClassCategories></ClassCategories>
    <Categories></Categories>
    <CompanyServices></CompanyServices>
  
    <Footer></Footer>
    </>
    );
}
export default Home;


