import { useEffect, useState } from "react";
import $ from 'jquery';
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useNavigate } from "react-router-dom";
export function ClassCategoryModel()
{
  const navigate = useNavigate();
  const dispatch = useDispatch();
    const repository =SchoolRepository();
    const [isJqueryLoaded, setJqueryLoaded] =useState<Boolean>(false);
    
    const selectedSchool = useSelector((state: AppState) =>
    {
    //    / //debugger;; 
    return  typeof(state.storeData.SelectdEcomSchool)!='undefined'?state.storeData.SelectdEcomSchool:null;
    }
    );
const [lstClasses,setClasses]=useState([]);
const getClassesBySchool=async ()=>
{

   if(selectedSchool!=null) 
   {
    //debugger;;
    setClasses(await repository.getClassesBySchoolId(selectedSchool.school_id));
    loadScripts();
   }
}
useEffect(() => {

  dispatch({
    type: ActionType.STORE,
    payload: {
      storeData: lstClasses,
      viewName: 'SchoolClasses',
    },
  });

},[lstClasses])
    useEffect(() => {
//         return()=>
//         {
// if(!isJqueryLoaded)
// {
//         loadScripts();
// }
//         }
        return()=>
        {
getClassesBySchool();
        }

    },[alert]);

 const loadScripts = () => {
    setJqueryLoaded(false);
    const script1 = document.createElement('script');
    script1.src = 'https://code.jquery.com/jquery-3.4.1.min.js';
    script1.async = true;
    document.body.appendChild(script1);

    script1.onload = () => {
      //debugger;;

      var $ = jQuery.noConflict();
      try{

    $(document).ready(function() {
      const script3 = document.createElement('script');
      script3.src = '/js/slick/slick.js'; // Replace with the correct local path or CDN
      script3.async = true;
      document.body.appendChild(script3);

      const script4 = document.createElement('script');
      script4.src = '/js/slick/slick-animation.min.js'; // Replace with the correct local path or CDN
      script4.async = true;
      document.body.appendChild(script4);


      const script5 = document.createElement('script');
      script5.src = '/js/custom-slick-animated.js'; // Replace with the correct local path or CDN
      script5.async = true;
      document.body.appendChild(script5);


   
      const script6 = document.createElement('script');
      script6.src = '/js/slick/custom_slick.js'; // Replace with the correct local path or CDN
      script6.async = true;
      document.body.appendChild(script6);
      setJqueryLoaded(true);
    });

    }catch(e)
    {

    }



    
    };
  };

  const onClassCategoryClicked=(className:any)=>
  {
    navigate('/products/'+JSON.stringify( {'type':'class','name':className}));
  }
  return {onClassCategoryClicked,selectedSchool,lstClasses}

}