import React from 'react';
import { MenuModel } from './MenuModel';

const ChildCategories: React.FC<{ lstMainCategories: any[] }> = ({ lstMainCategories }) => {
  // /  //debugger;;
     const viewModel=MenuModel()
  if (!lstMainCategories || lstMainCategories.length==0) {
    return null;
  }

  return (
    <ul className="sub-menu">
      {lstMainCategories.map((item: any) => {
      //  //debugger;;
        return  (  
        <li><a onClick={()=>viewModel.onCategoryClick(item.category_name)}>{item.category_name}</a></li>
       
    
)})}
    </ul>
  );
};

export default ChildCategories;
