import { useEffect } from "react"
import { SchoolRepository } from "../../repository/SchoolRepository"
import { UserState } from "../../repository/UserState";
import { useDispatch } from "react-redux";
import { ActionType } from "../../store/Page.Actions";

export function  CartViewModel()
{
    const dispatch = useDispatch();
    const repository=SchoolRepository();
    const userState=UserState();
useEffect(()=>
{
    getCartProducts();
},[userState.loggedUser])

const getCartProducts=()=>
{
    
    if((+userState.loggedUser.customer_id)>0)
    {
        repository.viewCartDetailsbyCustomer().then((result:any)=>
        {




            //debugger;
            if(typeof(result)!='undefined' &&   result.length>0)
            {
                for(let i=0;i<result.length;i++)
                 {
                    (result as any)[i].product_total= (+(result as any)[i].qty)* (+((result as any)[i].selling_price));
                    try
                    {
                       
                        (result as any)[i].tax=(  (result as any)[i].qty * (+(result as any)[i].selling_price) / (1 +((+(result as any)[i].tax_per)/100)) *((+(result as any)[i].tax_per)/100));
                      
                    }catch(e)
                    {
                     
                     
                        (result as any)[i].tax=0;
                      
                    
                    }

                 }

                // for(let i=0;i<result.length;i++)
                // {
                
                //     try{
                //     var product_feature_feilds=(((result[i].product_feature_feilds).replace(/\n/g, "")).replace(/'/g,"\""));
                //     result[i].product_feature_feilds=JSON.parse(product_feature_feilds)
                //     }catch(e)
                //     {
                // ////debugger;
                //     }
                //     try{
                //     var product_resources=(((result[i].product_resources).replace(/\n/g, "")).replace(/'/g,"\""));
                //     result[i].product_resources=JSON.parse(product_resources)
                // }catch(e)
                //     {
                //       //  //debugger; 
                //     }
                //     try{
                //     var product_variants=(((result[i].product_variants).replace(/\n/g, "")).replace(/'/g,"\""));
                //     result[i].product_variants=JSON.parse(product_variants)
                    
                
                
                // }catch(e)
                // {
                //     ////debugger; 
                // }
                
                
                // try{
                //     var productDiscounts=(((result[i].product_discounts).replace(/\n/g, "")).replace(/'/g,"\""));
                //     result[i].product_discounts=JSON.parse(productDiscounts)
                     
                
                // }catch(e)
                // {
                //     ////debugger; 
                // }
                
                
                
                // }

                dispatch({
                    type: ActionType.STORE,
                    payload: {
                      storeData: result,
                      viewName: 'cart',
                    },
                  });
            }else{
                
                dispatch({
                    type: ActionType.STORE,
                    payload: {
                      storeData: [],
                      viewName: 'cart',
                    },
                  });
            }
           

        })
    }


}

    return {}
}