import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { UserState } from "../../repository/UserState";
import { WishlistModel } from "../../pages/Wishlist/WishlistModel";

export function CartModel() {
  const dispatch = useDispatch();
  const repository = SchoolRepository();
  const [lstCartProducts, setCartProducts] = useState([]);
  const navigate = useNavigate();
  const userState = UserState();
  const wishList = WishlistModel();
  const lstCart = useSelector((state: AppState) => {
    return typeof (state.storeData.cart) != 'undefined' ? state.storeData.cart : [];
  });

  useEffect(() => {
    if (typeof (lstCart) != 'undefined') {
      setCartProducts(lstCart);
    }
  }, [lstCart]);

  // useEffect(()=>{
  //   wishList.getWishlistDetails();
  // })

  useEffect(() => {
    debugger;
    const totalQty = lstCart.reduce((total: any, product: any) => total + (+product.qty), 0);
    debugger;
    $('#cartTotal').text(totalQty);
  });
  //var cartTotal:number=0;
  const prepareCart = (item: any) => {
    //debugger;;

    let index = -1;
    for (let i = 0; i < lstCart.length; i++) {

      if (lstCart[i].product_details_id === item.product_details_id) {
        index = i;
        navigate('/cart');

        //  lstCart[i].qty=(+item.qty);
        break;
      }
    }




    //      let index=   lstCart.findIndex((product:any)=>
    //         {
    //             product.product_details_id==item.product_details_id

    //         });

    if (index > -1) {
      lstCart[index].qty = (+item.qty);
    } else {

      item.qty = (+item.qty) == 0 ? 1 : (+item.qty);


      item.product_total = ((+item.qty) * (+item.selling_price));

      try {

        item.tax = (+item.selling_price) / (1 + ((+item.tax_per) / 100)) * ((+item.tax_per) / 100);

      } catch (e) {
        try {

          item.tax = 0;

        } catch (e) {

        }
      }

      lstCart.push(item);

      dispatch({
        type: ActionType.STORE,
        payload: {
          storeData: lstCart,
          viewName: 'cart',
        },
      });

      debugger;
      const totalQty = lstCart.reduce((total: any, product: any) => total + (+product.qty), 0);
      debugger;
      $('#cartTotal').text(totalQty);

      const updatedProducts: any = [...lstCart];

      setCartProducts(updatedProducts);
      saveCart(updatedProducts);
    }
  }

  const removeProduct = (index: number) => {


    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform the action when confirmed
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // );


        lstCart.splice(index, 1);
        dispatch({
          type: ActionType.STORE,
          payload: {
            storeData: lstCart,
            viewName: 'cart',
          },
        });
        const updatedProducts: any = [...lstCart];

        setCartProducts(updatedProducts);
        saveCart(updatedProducts);
      }
    });





  }

  const addToCart = (item: any) => {

    debugger;


    prepareCart(item);

  }



  const onQtyPluseClick = (index: number) => {
    //debugger;

    const updatedProducts = [...lstCartProducts]; // Copy the array

    (updatedProducts as any)[index].qty = (+(updatedProducts as any)[index].qty) + 1;
    (updatedProducts as any)[index].product_total = (+(updatedProducts as any)[index].qty) * (+((updatedProducts as any)[index].selling_price));

    try {

      (updatedProducts as any)[index].tax = ((updatedProducts as any)[index].qty * (+(updatedProducts as any)[index].selling_price) / (1 + ((+(updatedProducts as any)[index].tax_per) / 100)) * ((+(updatedProducts as any)[index].tax_per) / 100));

    } catch (e) {
      try {

        (updatedProducts as any)[index].tax = 0;

      } catch (e) {

      }
    }



    dispatch({
      type: ActionType.STORE,
      payload: {
        storeData: lstCartProducts,
        viewName: 'cart',
      },
    });
    setCartProducts(updatedProducts);
    saveCart(updatedProducts);
  }

  const onQtyMinusClick = (index: number) => {
    //debugger;

    const updatedProducts = [...lstCartProducts]; // Copy the array

    (updatedProducts as any)[index].qty = ((+(updatedProducts as any)[index].qty) - 1) <= 0 ? 1 : (+(updatedProducts as any)[index].qty) - 1;
    (updatedProducts as any)[index].product_total = (+(updatedProducts as any)[index].qty) * (+((updatedProducts as any)[index].selling_price));
    try {

      (updatedProducts as any)[index].tax = ((updatedProducts as any)[index].qty * (+(updatedProducts as any)[index].selling_price) / (1 + ((+(updatedProducts as any)[index].tax_per) / 100)) * ((+(updatedProducts as any)[index].tax_per) / 100));

    } catch (e) {
      try {

        (updatedProducts as any)[index].tax = 0;

      } catch (e) {

      }
    }

    dispatch({
      type: ActionType.STORE,
      payload: {
        storeData: lstCartProducts,
        viewName: 'cart',
      },
    });
    setCartProducts(updatedProducts);
    saveCart(updatedProducts);
  }
  const onProductClick = (sku_id: any) => {
    navigate('/productdetails/' + sku_id);
  }

  const onCheckoutClick = () => {

    if ((+userState.loggedUser.customer_id) > 0) {
      navigate('/checkout');
    } else {

      navigate('/signup/checkout')
    }


  }

  const saveCart = (cartProducts: any) => {

    if ((+userState.loggedUser.customer_id) > 0) {
      let lstCartSave = [];
      if (cartProducts.length > 0) {
        cartProducts.map((product: any) => {

          lstCartSave.push({ 'customer_id': (+userState.loggedUser.customer_id), 'product_details_id': product.product_details_id, 'qty': product.qty })

        })
      }

      if (lstCartSave.length == 0) {
        lstCartSave.push({ 'customer_id': (+userState.loggedUser.customer_id) })
      }
      repository.saveCart(lstCartSave).then((result: any) => {

      })

    }
  }

  // useEffect(() => {
  //   
  //   saveCart(lstCartProducts);
  //   },[lstCartProducts]);

  const imagePath = repository.ecommerceImagePath;
  return { onCheckoutClick, onProductClick, onQtyPluseClick, onQtyMinusClick, removeProduct, addToCart, lstCartProducts, imagePath, setCartProducts }
}