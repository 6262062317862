import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository"
import { CommonFunctionality } from "../../repository/CommonFunctionality";
import { useDispatch } from "react-redux";
import { ActionType } from "../../store/Page.Actions";
import { useNavigate } from "react-router-dom";


export function WishlistModel() {
    const repository = SchoolRepository();
    const commonFunctionality = CommonFunctionality()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [lstWishlistProducts, setWishlistProducts] = useState([]);
    const onProductClick = ((products: any) => {


        try {
            var product_feature_feilds = (((products.product_feature_feilds)));
            products.product_feature_feilds = commonFunctionality.stringFormatForJson(product_feature_feilds)
        } catch (e) {
            ////debugger;
        }
        try {
            var product_resources = (((products.product_resources)));
            products.product_resources = commonFunctionality.stringFormatForJson(product_resources)
        } catch (e) {
            //  //debugger; 
        }
        try {
            var product_variants = (((products.product_variants)));
            products.product_variants = commonFunctionality.stringFormatForJson(product_variants)
            prepareVariantGroup(products.product_variants);


        } catch (e) {
            ////debugger; 
        }


        try {
            var productDiscounts = (((products.product_discounts)));
            products.product_discounts = commonFunctionality.stringFormatForJson(productDiscounts)


        } catch (e) {
            ////debugger; 
        }






        let selectedProduct: any = lstWishlistProducts.filter((lproduct: any) => {
            return (+lproduct.product_id) === (+products.product_id)
        })
        selectedProduct.map((product: any) => {

            product.selected_product_details_id = products.product_details_id

        });

        dispatch({
            type: ActionType.STORE,
            payload: {
                storeData: selectedProduct,
                viewName: 'selectedProduct',
            },
        });
        navigate('/productdetails');


    });
    var lstProductVarients: any = [];
    var lstVariantGroup: any = [];
    const prepareVariantGroup = (lstProductVariants: any[]) => {
        //
        try {
            const variantGroup: string[] = lstProductVariants.map((productVariant: any) => {
                lstProductVarients.push(productVariant);
                return productVariant.product_variants_title

            }


            );
            const uniqueVariantGroup: string[] = Array.from(new Set(variantGroup));
            lstVariantGroup.push(uniqueVariantGroup);
        } catch (error) {
            console.error("Error preparing VariantGroup:", error);
            return [];
        }
    };
    const onWishlistClick = async (data: any) => {
        //debugger;;
        let wishlist: any = [...lstWishlistProducts];

        let index = wishlist.findIndex(((x: any) => x.product_details_id === data.product_details_id));
        if (index == -1) {
            wishlist.push(data);

        } else {

            wishlist.splice(index, 1);
        }
        setWishlistProducts(wishlist);

        await repository.saveWishlist(wishlist).then((result: any) => {



        })

    }
    const isWishlistItem = (product_details_id: any) => {


        let index = lstWishlistProducts.findIndex(((x: any) => x.product_details_id === product_details_id));

        return index;
    }


    useEffect(() => {
        getWishlistDetails();

    }, [alert]
    )



    useEffect(()=>{
        debugger;
        $('#wishListProducts').text(lstWishlistProducts.length);
    },[lstWishlistProducts])


    const getWishlistDetails = async () => {
        await repository.viewWishlistDetailsbyCustomer().then((results: any) => {
            setWishlistProducts([])
            //debugger;;
            if (typeof (results) != 'undefined' && results.length > 0) {
                setWishlistProducts(results);
            }
        }
        )
    }

    const imagePath = repository.ecommerceImagePath;
    return { onWishlistClick, imagePath, isWishlistItem, onProductClick, lstWishlistProducts ,getWishlistDetails}
}