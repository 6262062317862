import SearchProducts from "../SearchProducts/SearchProducts";


const Footer:React.FC=()=>
{


    return ( <>
    <div className="modal modal01 fade theme-modal view-modal" id="search-box" tabIndex={-1} aria-labelledby="exampleModalLabel"
    aria-hidden="true">
        <SearchProducts></SearchProducts>
    </div>
    <footer className="section-t-space footer-section-2 footer-color-2">
    <div className="container-fluid">
        <div className="main-footer">
            <div className="row g-md-4 gy-sm-5">
                <div className="col-xxl-3 col-xl-4 col-sm-6">
                    <a href="index.html" className="foot-logo theme-logo">
                        <img src="assets/images/logo/2.png" className="img-fluid blur-up lazyload" alt=""/>
                    </a>
                    <p className="information-text information-text-2">it is a long established fact that a reader will
                        be distracted by the readable content.</p>
                    <ul className="social-icon">
                        <li className="light-bg">
                            <a href="https://www.facebook.com/" className="footer-link-color">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li className="light-bg">
                            <a href="https://accounts.google.com/signin/v2/identifier?flowName=GlifWebSignIn&flowEntry=ServiceLogin"
                                className="footer-link-color">
                                <i className="fab fa-google"></i>
                            </a>
                        </li>
                        <li className="light-bg">
                            <a href="https://twitter.com/i/flow/login" className="footer-link-color">
                                <i className="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li className="light-bg">
                            <a href="https://www.instagram.com/" className="footer-link-color">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </li>
                        <li className="light-bg">
                            <a href="https://in.pinterest.com/" className="footer-link-color">
                                <i className="fab fa-pinterest-p"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="col-xxl-2 col-xl-4 col-sm-6">
                    <div className="footer-title">
                        <h4 className="text-white">About Education Today</h4>
                    </div>
                    <ul className="footer-list footer-contact footer-list-light">
                        <li>
                            <a href="about-us.html" className="light-text">About Us</a>
                        </li>
                        <li>
                            <a href="contact-us.html" className="light-text">Contact Us</a>
                        </li>
                        <li>
                            <a href="term_condition.html" className="light-text">Terms & Coditions</a>
                        </li>
                        <li>
                            <a href="careers.html" className="light-text">Careers</a>
                        </li>
                        <li>
                            <a href="blog-list.html" className="light-text">LaText Blog</a>
                        </li>
                    </ul>
                </div>

                <div className="col-xxl-2 col-xl-4 col-sm-6">
                    <div className="footer-title">
                        <h4 className="text-white">Useful Link</h4>
                    </div>
                    <ul className="footer-list footer-list-light footer-contact">
                        <li>
                            <a href="order-success.html" className="light-text">Your Order</a>
                        </li>
                        <li>
                            <a href="user-dashboard.html" className="light-text">Your Account</a>
                        </li>
                        <li>
                            <a href="order-tracking.html" className="light-text">Orders</a>
                        </li>
                        <li>
                            <a href="wishlist.html" className="light-text">Your Wishlist</a>
                        </li>
                        <li>
                            <a href="faq.html" className="light-text">FAQs</a>
                        </li>
                    </ul>
                </div>

                <div className="col-xxl-2 col-xl-4 col-sm-6">
                    <div className="footer-title">
                        <h4 className="text-white">Categories</h4>
                    </div>
                    <ul className="footer-list footer-list-light footer-contact">
                        <li>
                            <a href="vegetables-demo.html" className="light-text">Text-Books</a>
                        </li>
                        <li>
                            <a href="spice-demo.html" className="light-text">Uniform</a>
                        </li>
                        <li>
                            <a href="bags-demo.html" className="light-text">Bags</a>
                        </li>
                        <li>
                            <a href="bakery-demo.html" className="light-text">Shoes</a>
                        </li>
                        <li>
                            <a href="grocery-demo.html" className="light-text">Id Cards</a>
                        </li>
                    </ul>
                </div>

                <div className="col-xxl-3 col-xl-4 col-sm-6">
                    <div className="footer-title">
                        <h4 className="text-white">Store infomation</h4>
                    </div>
                    <ul className="footer-address footer-contact">
                        <li>
                            <a href="javascript:void(0)" className="light-text">
                                <div className="inform-box flex-start-box">
                                    <i data-feather="map-pin"></i>
                                    <p>Education Today India Demo Store, Demo store india 345 - 659</p>
                                </div>
                            </a>
                        </li>

                        <li>
                            <a href="javascript:void(0)" className="light-text">
                                <div className="inform-box">
                                    <i data-feather="phone"></i>
                                    <p>Call us: 123-456-7890</p>
                                </div>
                            </a>
                        </li>

                        <li>
                            <a href="javascript:void(0)" className="light-text">
                                <div className="inform-box">
                                    <i data-feather="mail"></i>
                                    <p>Email : support@education.com</p>
                                </div>
                            </a>
                        </li>

                        <li>
                            <a href="javascript:void(0)" className="light-text">
                                <div className="inform-box">
                                    <i data-feather="printer"></i>
                                    <p>Fax: 123456</p>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="sub-footer sub-footer-lite">
            <div className="left-footer">
                <p className="light-text">2024 Copyright Education Today India Powered By : Mechknowsoftllc</p>
            </div>

            <ul className="payment-box">
                <li>
                    <img src="assets/images/icon/paymant/visa.png" className="blur-up lazyload" alt=""/>
                </li>
                <li>
                    <img src="assets/images/icon/paymant/discover.png" alt="" className="blur-up lazyload"/>
                </li>
                <li>
                    <img src="assets/images/icon/paymant/american.png" alt="" className="blur-up lazyload"/>
                </li>
                <li>
                    <img src="assets/images/icon/paymant/master-card.png" alt="" className="blur-up lazyload"/>
                </li>
                <li>
                    <img src="assets/images/icon/paymant/giro-pay.png" alt="" className="blur-up lazyload"/>
                </li>
            </ul>
        </div>
    </div>
    
</footer>
    </> )
}

export default Footer;