import { useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { ProductDetailsModel } from "./ProductDetailsModel";

const ProductDetails: React.FC = () => {

    const { sku } = useParams<{ sku: string }>();

    const viewModel = ProductDetailsModel(sku);

    return (


        <>
            <Header></Header>
            {/* <!-- Breadcrumb Section Start --> */}
            <section className="breadscrumb-section pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>Prodcut Details</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="index.html">
                                                <i className="fa-solid fa-house"></i>
                                            </a>
                                        </li>

                                        <li className="breadcrumb-item active">Prodcut Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Breadcrumb Section End --> */}

            {/* <!-- Product Left Sidebar Start --> */}
            <section className="product-section white">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-8 col-lg-7 wow fadeInUp">
                            <div className="row g-4">
                                <div className="col-xl-5 wow fadeInUp">
                                    <div className="product-left-box">
                                        <div className="row g-2">
                                            <div className="col-xxl-10 col-lg-12 col-md-10 order-xxl-2 order-lg-1 order-md-2">
                                                <div className="product-main-2 no-arrow">

                                                    {

                                                        ((typeof (viewModel.productDetails) != 'undefined' && typeof ((viewModel.productDetails as any).product_resources) != 'undefined' && ((viewModel.productDetails as any).product_resources) != '')) ? (

                                                            (viewModel.productDetails as any).product_resources.map((resource: any, i: number) => {




                                                                return (<div>
                                                                    <div className="slider-image">
                                                                        <img src={((viewModel.imagePath + resource.resource_name) + '.webp')} id={'img-' + i + 1}
                                                                            data-zoom-image={((viewModel.imagePath + resource.resource_name) + '.webp')}
                                                                            className="img-fluid image_zoom_cls-0 blur-up lazyload" alt="" />
                                                                    </div>
                                                                </div>)
                                                            })
                                                        ) : ''

                                                    }

                                                    {/* <div>
                                                    <div className="slider-image">
                                                        <img src="https://rukminim2.flixcart.com/image/416/416/kf2v3ww0/diary-notebook/e/4/g/classmate-02105003-original-imafvm2csvkhmqzf.jpeg?q=70&crop=false"
                                                            data-zoom-image="/images/product/category/2.jpg"
                                                            className="img-fluid image_zoom_cls-1 blur-up lazyload" alt=""/>
                                                    </div>
                                                </div>
    
                                                <div>
                                                    <div className="slider-image">
                                                        <img src="https://rukminim2.flixcart.com/image/416/416/kf2v3ww0/diary-notebook/e/4/g/classmate-02105003-original-imafvm2chtjxhscs.jpeg?q=70&crop=false"
                                                            data-zoom-image="/images/product/category/3.jpg"
                                                            className="img-fluid image_zoom_cls-2 blur-up lazyload" alt=""/>
                                                    </div>
                                                </div>
    
                                                <div>
                                                    <div className="slider-image">
                                                        <img src="https://rukminim2.flixcart.com/image/416/416/kf2v3ww0/diary-notebook/e/4/g/classmate-02105003-original-imafvm2cfphz3hhg.jpeg?q=70&crop=false"
                                                            data-zoom-image="/images/product/category/4.jpg"
                                                            className="img-fluid image_zoom_cls-3 blur-up lazyload" alt=""/>
                                                    </div>
                                                </div>
    
                                                <div>
                                                    <div className="slider-image">
                                                        <img src="https://rukminim2.flixcart.com/image/416/416/kf2v3ww0/diary-notebook/e/4/g/classmate-02105003-original-imafvm2cn82ekfkn.jpeg?q=70&crop=false"
                                                            data-zoom-image="/images/product/category/5.jpg"
                                                            className="img-fluid image_zoom_cls-4 blur-up lazyload" alt=""/>
                                                    </div>
                                                </div>
    
                                                <div>
                                                    <div className="slider-image">
                                                        <img src="https://rukminim2.flixcart.com/image/416/416/kf2v3ww0/diary-notebook/e/4/g/classmate-02105003-original-imafvm2cmy6uas4b.jpeg?q=70&crop=false"
                                                            data-zoom-image="/images/product/category/6.jpg"
                                                            className="img-fluid image_zoom_cls-5 blur-up lazyload" alt=""/>
                                                    </div>
                                                </div> */}

                                                </div>
                                                {/* ="display: flex;margin-top:-50px;" */}

                                                {
                                                    (typeof (viewModel.productDetails) != 'undefined' && (+(viewModel.productDetails as any).stock) > 0) ?
                                                        (
                                                            <div style={{ display: 'flex', marginTop: '-2px' }} >
                                                                <button id="btnCart" onClick={() => viewModel.addToCart('btnCart')} className="btn theme-color-1 view-button icon text-white fw-bold btn-md w-100 mr-24"><i className="fas fa-shopping-cart"></i>&nbsp; ADD TO CART</button>
                                                                <button id="btnCart" onClick={() => viewModel.addToCart('btnCart')} className="btn theme-bg-color view-button icon text-white fw-bold w-100 btn-md"><i className="fas fa-solid fa-bolt"></i>&nbsp; BUY NOW</button>
                                                            </div>
                                                        ) : 'Not Available'

                                                }



                                            </div>

                                            <div className="col-xxl-2 col-lg-12 col-md-2 order-xxl-1 order-lg-2 order-md-1">
                                                <div className="left-slider-image-2 left-slider no-arrow slick-top">

                                                    {

                                                        (typeof (viewModel.productDetails) != 'undefined' && typeof ((viewModel.productDetails as any).product_resources) != 'undefined' && ((viewModel.productDetails as any).product_resources) != '') ? (

                                                            (viewModel.productDetails as any).product_resources.map((resource: any, i: number) => {



                                                                return (<div>
                                                                    <div className="slider-image">
                                                                        <img src={((viewModel.imagePath + resource.resource_name) + '.webp')} id={'img-' + i + 1}
                                                                            data-zoom-image={((viewModel.imagePath + resource.resource_name) + '.webp')}
                                                                            className="img-fluid blur-up lazyload" alt="" />
                                                                    </div>
                                                                </div>)
                                                            })
                                                        ) : ''

                                                    }

                                                    {/* <div>
                                                    <div className="sidebar-image">
                                                        <img src="https://rukminim2.flixcart.com/image/128/128/kf2v3ww0/diary-notebook/e/4/g/classmate-02105003-original-imafvm2cmy6uas4b.jpeg?q=70&crop=false"
                                                            className="img-fluid blur-up lazyload" alt=""/>
                                                    </div>
                                                </div> */}

                                                    {/* <div>
                                                    <div className="sidebar-image">
                                                        <img src="https://rukminim2.flixcart.com/image/128/128/kf2v3ww0/diary-notebook/e/4/g/classmate-02105003-original-imafvm2csvkhmqzf.jpeg?q=70&crop=false"
                                                            className="img-fluid blur-up lazyload" alt=""/>
                                                    </div>
                                                </div>
    
                                                <div>
                                                    <div className="sidebar-image">
                                                        <img src="https://rukminim2.flixcart.com/image/416/416/kf2v3ww0/diary-notebook/e/4/g/classmate-02105003-original-imafvm2chtjxhscs.jpeg?q=70&crop=false"
                                                            className="img-fluid blur-up lazyload" alt=""/>
                                                    </div>
                                                </div>
    
                                                <div>
                                                    <div className="sidebar-image">
                                                        <img src="https://rukminim2.flixcart.com/image/416/416/kf2v3ww0/diary-notebook/e/4/g/classmate-02105003-original-imafvm2cfphz3hhg.jpeg?q=70&crop=false"
                                                            className="img-fluid blur-up lazyload" alt=""/>
                                                    </div>
                                                </div>
    
                                                <div>
                                                    <div className="sidebar-image">
                                                        <img src="https://rukminim2.flixcart.com/image/416/416/kf2v3ww0/diary-notebook/e/4/g/classmate-02105003-original-imafvm2cn82ekfkn.jpeg?q=70&crop=false"
                                                            className="img-fluid blur-up lazyload" alt=""/>
                                                    </div>
                                                </div>
     */}

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div className="col-xl-7 wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="right-box-contain">
                                        {/* <h6 className="offer-top">30% Off</h6> */}
                                        <h2 className="name">{(viewModel.productDetails as any).title} </h2>
                                        <div className="price-rating">
                                            <h3 className="theme-color price">Rs {(viewModel.productDetails as any).selling_price} <del

                                                style={(+ (viewModel.productDetails as any).selling_price) != (+(viewModel.productDetails as any).mrp) ? { display: 'block' } : { display: 'none' }}
                                                className="text-content">Rs {(viewModel.productDetails as any).mrp}</del>
                                                {/* <span className="offer theme-color">(8% off)</span> */}
                                            </h3>
                                            <div className="product-rating custom-rate">
                                                <ul className="rating">
                                                    <li>
                                                        <i data-feather="star" className="fill"></i>
                                                    </li>
                                                    <li>
                                                        <i data-feather="star" className="fill"></i>
                                                    </li>
                                                    <li>
                                                        <i data-feather="star" className="fill"></i>
                                                    </li>
                                                    <li>
                                                        <i data-feather="star" className="fill"></i>
                                                    </li>
                                                    <li>
                                                        <i data-feather="star"></i>
                                                    </li>
                                                </ul>
                                                <span style={viewModel.lstReviews.length > 0 ? { display: 'block' } : { display: 'none' }} className="review">{viewModel.lstReviews.length} Customer Review</span>
                                            </div>
                                        </div>

                                        <div className="procuct-contain">
                                            <p>{(viewModel.productDetails as any).brand_name} {(viewModel.productDetails as any).title}
                                                {viewModel.displayVarients((viewModel.productDetails as any).product_variants)}
                                            </p>
                                        </div>



                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="product-packege">
                                                    <div className="">

                                                    </div>

                                                    {
                                                        viewModel.lstVariantGroup.map((variantg: any) => {
                                                            return (<ul className="select-packege mt-20"  >
                                                                <h4 className="">{variantg.product_variants_title}</h4>

                                                                {viewModel.getVariantbyGroup(variantg.product_variants_title).map((variant: any) => {

                                                                    return (<li>
                                                                        <a onClick={() => viewModel.onVaraintClick(variantg.product_variants_title, variant.product_variant_value)} className={viewModel.getActiveClass(variantg.product_variants_title, variant.product_variant_value)} >{variant.product_variant_value}</a>
                                                                    </li>)
                                                                })

                                                                }




                                                            </ul>);
                                                        })
                                                    }


                                                </div>
                                            </div>


                                            <div className="col-md-4">
                                                <div className="product-packege select-packege">
                                                    <div className="product-title">
                                                        <h4>Size Chart <img src="/images/size-chart.png" data-bs-toggle="modal" data-bs-target="#view" tabIndex={0} /></h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        {

                                            (typeof ((viewModel.productDetails as any).product_discounts) != 'undefined' && ((viewModel.productDetails as any).product_discounts) != '') ? (

                                                <><div className="row" style={(typeof ((viewModel.productDetails as any).product_discounts) != 'undefined' && (viewModel.productDetails as any).product_discounts.length > 0) ? { display: 'none' } : { display: 'block' }}>
                                                    <div className="col-md-12">
                                                        <div className="product-info mt-10">
                                                            <h4>Available offers</h4>
                                                            <ul className="Highlights">
                                                                {
                                                                    (viewModel.productDetails as any).product_discounts.map((discount: any) => {
                                                                        <li><i className="fa fa-gift"></i>{discount.discount_name}</li>
                                                                    })
                                                                }



                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                    <hr /></>
                                            ) : ''
                                        }




                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="product-info mt-10">
                                                    <h4><i className="fa fa-location-dot"></i>&nbsp; Deliver to</h4>
                                                    <div className="deliver">
                                                        <input type="text" placeholder="Search.." id="txtPincode" className="check-input" />
                                                        <span className="Check" onClick={viewModel.onPinCodeChange}>Check</span>
                                                        <br></br>
                                                        <span className="text-danger">{(viewModel.showPinCodeError == 'true' && viewModel.pinCodeChecked) ? 'Delivery not available' :


                                                            (viewModel.pinCodeChecked ? 'Delivery  available' : '')

                                                        }   </span>


                                                    </div>

                                                    {/* <p className="l-h"><b>Delivery by8 Feb, Thursday</b> | Free <del className="text-content">$40</del><br/>
                                                    if ordered before 5:59 PM <br/>
                                                <a href="#" className="mt-10" data-bs-toggle="modal" data-bs-target="#deal-box" tabIndex={0}>View Details</a></p>
                                                 */}
                                                </div>
                                            </div>

                                            {/* <div className="col-md-4">
                                            <div className="product-info mt-10">
                                                <h5>Services <span className="mt-10"><i className="fa fa-indian-rupee-sign"></i> Cash on Delivery available</span></h5>
                                            </div> 
                                        </div> */}
                                        </div>
                                        <hr />

                                        <div className="pickup-box">
                                            <div className="product-title">
                                                <h4>Description</h4>
                                            </div>

                                            <div className="pickup-detail">
                                                {/* <h4 className="text-content" >{(viewModel.productDetails as any).notes}</h4> */}
                                                <p className="product_description" dangerouslySetInnerHTML={{ __html: (viewModel.productDetails as any).notes }}></p>
                                            </div>

                                            <div className="product-info">
                                                <h4>Specifications</h4>
                                                <ul className="product-info-list product-info-list-2">

                                                    <li>height : <a href="javascript:void(0)">{(viewModel.productDetails as any).height} cm</a></li>
                                                    <li>Length : <a href="javascript:void(0)">{(viewModel.productDetails as any).length} cm</a></li>
                                                    <li>Weight : <a href="javascript:void(0)"> {(viewModel.productDetails as any).weight} g</a></li>
                                                    <li>bradth : <a href="javascript:void(0)">{(viewModel.productDetails as any).bradth}</a>
                                                        {/* <a
                                                        href="javascript:void(0)">Backery</a> */}

                                                    </li>
                                                </ul>
                                            </div>

                                            {

                                                (typeof ((viewModel.productDetails as any).product_feature_feilds) != 'undefined' && ((viewModel.productDetails as any).product_feature_feilds) != '') ? (


                                                    <div className="product-info">
                                                        <h4>Highlights</h4>
                                                        <ul className="product-info-list product-info-list-2">

                                                            {
                                                                (viewModel.productDetails as any).product_feature_feilds.map((feature: any) => {
                                                                    <li>{feature.product_feild_title} : <a href="javascript:void(0)">{feature.product_feild_value} </a></li>
                                                                })
                                                            }


                                                        </ul>
                                                    </div>
                                                ) : ''
                                            }

                                        </div>

                                        <div className="review-box">
                                            <div className="row g-4">
                                                <div className="col-xl-8">
                                                    <div className="review-title">
                                                        <h4 className="fw-500">Customer reviews</h4>
                                                    </div>

                                                    <div className="d-flex">
                                                        <div className="product-rating">
                                                            <ul className="rating">
                                                                <li>
                                                                    <i data-feather="star" className="fill"></i>
                                                                </li>
                                                                <li>
                                                                    <i data-feather="star" className="fill"></i>
                                                                </li>
                                                                <li>
                                                                    <i data-feather="star" className="fill"></i>
                                                                </li>
                                                                <li>
                                                                    <i data-feather="star"></i>
                                                                </li>
                                                                <li>
                                                                    <i data-feather="star"></i>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <h6 className="ms-3">{typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0].total_rating : 0} Out Of 5</h6>
                                                    </div>

                                                    <div className="rating-box">
                                                        <ul>
                                                            <li>
                                                                <div className="rating-list">
                                                                    <h5>5 <i className="fa fa-star"></i></h5>
                                                                    <div className="progress">
                                                                        <div className="progress-bar bg-green" role="progressbar"
                                                                            style={{
                                                                                width: ((100 * (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['5star'] : 0)))
                                                                                    /
                                                                                    ((+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['5star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['4star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['3star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['2star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['1star'] : 0)))).toString() + '%'
                                                                            }} aria-aria-valuenow="100"
                                                                            aria-aria-valuemin="0" aria-aria-valuemax="100">

                                                                        </div>
                                                                    </div>
                                                                    <p className="count">{typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['5star'] : 0}</p>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="rating-list">
                                                                    <h5>4 <i className="fa fa-star"></i></h5>
                                                                    <div className="progress">
                                                                        <div className="progress-bar bg-green" role="progressbar"
                                                                            style={{
                                                                                width:


                                                                                    ((100 * (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['4star'] : 0)))
                                                                                        /
                                                                                        ((+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['5star'] : 0)) +
                                                                                            (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['4star'] : 0)) +
                                                                                            (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['3star'] : 0)) +
                                                                                            (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['2star'] : 0)) +
                                                                                            (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['1star'] : 0)))).toString() + '%'


                                                                            }} aria-aria-valuenow="100"
                                                                            aria-aria-valuemin="0" aria-aria-valuemax="100">
                                                                        </div>
                                                                    </div>
                                                                    <p className="count">{typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['4star'] : 0}</p>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="rating-list">
                                                                    <h5>3 <i className="fa fa-star"></i></h5>
                                                                    <div className="progress">
                                                                        <div className="progress-bar bg-green" role="progressbar"
                                                                            style={{
                                                                                width: ((100 * (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['3star'] : 0)))
                                                                                    /
                                                                                    ((+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['5star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['4star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['3star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['2star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['1star'] : 0)))).toString() + '%'
                                                                            }} aria-aria-valuenow="100"
                                                                            aria-aria-valuemin="0" aria-aria-valuemax="100">
                                                                        </div>
                                                                    </div>
                                                                    <p className="count">{typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['3star'] : 0}</p>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="rating-list">
                                                                    <h5>2 <i className="fa fa-star"></i></h5>
                                                                    <div className="progress">
                                                                        <div className="progress-bar bg-orange" role="progressbar"
                                                                            style={{
                                                                                width: ((100 * (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['2star'] : 0)))
                                                                                    /
                                                                                    ((+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['5star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['4star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['3star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['2star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['1star'] : 0)))).toString() + '%'
                                                                            }} aria-aria-valuenow="100"
                                                                            aria-aria-valuemin="0" aria-aria-valuemax="100">
                                                                        </div>
                                                                    </div>
                                                                    <p className="count">{typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['2star'] : 0}</p>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="rating-list">
                                                                    <h5>1 <i className="fa fa-star"></i></h5>
                                                                    <div className="progress">
                                                                        <div className="progress-bar bg-red" role="progressbar"
                                                                            style={{
                                                                                width: ((100 * (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['1star'] : 0)))
                                                                                    /
                                                                                    ((+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['5star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['4star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['3star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['2star'] : 0)) +
                                                                                        (+(typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['1star'] : 0)))).toString() + '%'
                                                                            }} aria-aria-valuenow="100"
                                                                            aria-aria-valuemin="0" aria-aria-valuemax="100">
                                                                        </div>
                                                                    </div>
                                                                    <p className="count">{typeof (viewModel.lstReviews[0]) != "undefined" ? (viewModel.lstReviews as any)[0]['1star'] : 0}</p>
                                                                </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>

                                                <div className="row mt-20">


                                                    {viewModel.lstReviews.map((rating: any) => {

                                                        return (<>



                                                            <div className="col-md-2" style={rating.review_image != '' ? { display: "block" } : { display: "none" }}>
                                                                <img src={((viewModel.imagePath + rating.review_image) + '.webp')} className="img-fluid blur-up lazyloaded" alt="" />
                                                            </div>
                                                            <div className="col-md-2" style={rating.review_image_1 != '' ? { display: "block" } : { display: "none" }}>
                                                                <img src={((viewModel.imagePath + rating.review_image_1) + '.webp')} className="img-fluid blur-up lazyloaded" alt="" />
                                                            </div>

                                                            <div className="col-md-2" style={rating.review_image_2 != '' ? { display: "block" } : { display: "none" }}>
                                                                <img src={((viewModel.imagePath + rating.review_image_2) + '.webp')} className="img-fluid blur-up lazyloaded" alt="" />
                                                            </div>

                                                            <div className="col-md-2" style={rating.review_image_3 != '' ? { display: "block" } : { display: "none" }}>
                                                                <img src={((viewModel.imagePath + rating.review_image_3) + '.webp')} className="img-fluid blur-up lazyloaded" alt="" />
                                                            </div>

                                                            <div className="col-md-2" style={rating.review_image_4 != '' ? { display: "block" } : { display: "none" }}>
                                                                <img src={((viewModel.imagePath + rating.review_image_4) + '.webp')} className="img-fluid blur-up lazyloaded" alt="" />
                                                            </div>

                                                        </>)

                                                    })}



                                                </div>



                                                <div className="col-12">
                                                    <div className="review-title">
                                                        <h4 className="fw-500">Customer questions & answers</h4>
                                                    </div>

                                                    <div className="review-people">
                                                        <ul className="review-list">
                                                            {

                                                                viewModel.lstReviews.map((rating: any) => {

                                                                    return (<>

                                                                        <li>
                                                                            <div className="people-box">
                                                                                {/* <div>
                                                                    <div className="people-image">
                                                                        <img src="/images/review/1.jpg"
                                                                            className="img-fluid blur-up lazyload"
                                                                            alt=""/>
                                                                    </div>
                                                                </div> */}

                                                                                <div className="people-comment">
                                                                                    <a className="name"
                                                                                        href="javascript:void(0)">{rating.rating}<i className="fa fa-star"></i></a>
                                                                                    <div className="date-time">
                                                                                        <h6 className="text-content">{rating.rated_date}</h6>

                                                                                        <div className="product-rating">
                                                                                            <ul className="rating">
                                                                                                <li>
                                                                                                    <i data-feather="star"
                                                                                                        className="fill" />
                                                                                                </li>
                                                                                                <li>
                                                                                                    <i data-feather="star"
                                                                                                        className="fill"></i>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <i data-feather="star"
                                                                                                        className="fill"></i>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <i data-feather="star"></i>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <i data-feather="star"></i>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="reply">
                                                                                        <p>{rating.review}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row mt-20">

                                                                                <>



                                                                                    <div className="col-md-2" style={rating.review_image != '' ? { display: "block" } : { display: "none" }}>
                                                                                        <img src={((viewModel.imagePath + rating.review_image) + '.webp')} className="img-fluid blur-up lazyloaded" alt="" />
                                                                                    </div>
                                                                                    <div className="col-md-2" style={rating.review_image_1 != '' ? { display: "block" } : { display: "none" }}>
                                                                                        <img src={((viewModel.imagePath + rating.review_image_1) + '.webp')} className="img-fluid blur-up lazyloaded" alt="" />
                                                                                    </div>

                                                                                    <div className="col-md-2" style={rating.review_image_2 != '' ? { display: "block" } : { display: "none" }}>
                                                                                        <img src={((viewModel.imagePath + rating.review_image_2) + '.webp')} className="img-fluid blur-up lazyloaded" alt="" />
                                                                                    </div>

                                                                                    <div className="col-md-2" style={rating.review_image_3 != '' ? { display: "block" } : { display: "none" }}>
                                                                                        <img src={((viewModel.imagePath + rating.review_image_3) + '.webp')} className="img-fluid blur-up lazyloaded" alt="" />
                                                                                    </div>

                                                                                    <div className="col-md-2" style={rating.review_image_4 != '' ? { display: "block" } : { display: "none" }}>
                                                                                        <img src={((viewModel.imagePath + rating.review_image_4) + '.webp')} className="img-fluid blur-up lazyloaded" alt="" />
                                                                                    </div>

                                                                                </>


                                                                            </div>
                                                                        </li>

                                                                    </>)

                                                                })

                                                            }



                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* <!-- Product Left Sidebar End --> */}

            {/* <!-- Releted Product Section Start --> */}
            {

                (typeof (viewModel.lstRelatedProducts) != 'undefined' && viewModel.lstRelatedProducts != null)
                    ?
                    (


                        <section className="product-list-section section-b-space white">
                            <div className="container">
                                <div className="title">
                                    <h2>Related Products</h2>
                                    <span className="title-leaf">
                                        <svg className="icon-width">
                                            <use href="/svg/leaf.svg#leaf"></use>
                                        </svg>
                                    </span>
                                </div>
                                <div className="row">
                                    {
                                        viewModel.lstRelatedProducts.map((product: any) => {
                                            return (
                                                <div className="col-3 mb-3">
                                                    {/* <div className="slider-6_1 product-wrapper"> */}
                                                        <div>
                                                            <div className="product-box-3 wow fadeInUp" onClick={() => viewModel.onProductClick(product)}>
                                                                <div className="product-header">
                                                                    <div className="product-image">
                                                                        <a  >
                                                                            <img src={(typeof (product.product_resources) != 'undefined' && product.product_resources.length > 0) ? 
                                                                                (viewModel.imagePath + viewModel.ViewImage(product.product_resources))  : ''}
                                                                                // ((viewModel.imagePath + product.product_resources[0].resource_name) + '.webp')
                                                                                className="img-fluid blur-up lazyload" alt="" />
                                                                        </a>

                                                                    </div>
                                                                </div>

                                                                <div className="product-footer">
                                                                    <div className="product-detail">

                                                                        <a href="product-left-thumbnail.html">
                                                                            <h5 className="name">{product.title}</h5>
                                                                        </a>
                                                                        {/* <div className="product-rating mt-2">
                                                    <ul className="rating">
                                                        <li>
                                                            <i data-feather="star" className="fill"></i>
                                                        </li>
                                                        <li>
                                                            <i data-feather="star" className="fill"></i>
                                                        </li>
                                                        <li>
                                                            <i data-feather="star" className="fill"></i>
                                                        </li>
                                                        <li>
                                                            <i data-feather="star" className="fill"></i>
                                                        </li>
                                                        <li>
                                                            <i data-feather="star" className="fill"></i>
                                                        </li>
                                                    </ul>
                                                    <span>(5.0)</span>
                                                </div> */}
                                                                        {/* <h6 className="unit">500 G</h6> */}
                                                                        <h5 className="price"><span className="theme-color">Rs {product.mrp}</span> <del>Rs {product.selling_price}</del>
                                                                        </h5>
                                                                        {/* <div className="add-to-cart-box bg-white">
                                                    <button className="btn btn-add-cart addcart-button">Add
                                                        <span className="add-icon bg-light-gray">
                                                            <i className="fa-solid fa-plus"></i>
                                                        </span>
                                                    </button>
                                                    <div className="cart_qty qty-box">
                                                        <div className="input-group bg-white">
                                                            <button type="button" className="qty-left-minus bg-gray"
                                                                data-type="minus" data-field="">
                                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                                            </button>
                                                            <input className="form-control input-number qty-input" type="text"
                                                                name="quantity" value="0"/>
                                                            <button type="button" className="qty-right-plus bg-gray"
                                                                data-type="plus" data-field="">
                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    {/* </div> */}
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                            {/* { viewModel.loadSlideScripts()} */}
                        </section>
                    ) : ''
            }
            {/* <!-- Releted Product Section End --> */}

            {/* <!-- Quick chart Modal Box Start --> */}
            <div className="modal fade theme-modal view-modal" id="view" tabIndex={-1} aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-sm-down">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row g-sm-4 g-2">
                                <div className="col-lg-12">
                                    <div className="slider-image">
                                        <img src="/images/chart-review.jpg" className="img-fluid blur-up lazyload"
                                            alt="" />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Quick View Modal Box End --> */}



            {/* <!-- Deal Box Modal Start --> */}
            <div className="modal fade theme-modal deal-modal" id="deal-box" tabIndex={-1} aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h5 className="modal-title w-100" id="deal_today">Delivery & Installation details</h5>

                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <hr />
                        <div className="modal-body">
                            <div className="deal-offer-box">
                                <p className="l-h">Delivery by<br />
                                    <span className="fz-18">8 Feb, Thursday | Free ₹40</span><br />
                                    if ordered before 5:59 PM</p>

                                <p>Installation Details</p>

                                <p>This product doesn't require installation<br /><br />
                                    Shipping Charges For Flipkart Assured Items<br /><br />
                                    Shipping charges are calculated based on the number of units, distance and delivery date.<br /><br />
                                    Delivery charges if applicable will be shown on the product page and cart.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Deal Box Modal End --> */}

            <div className="button-item">
                <button className="item-btn btn text-white" onClick={() => viewModel.onCartClick()}>
                    <i className="iconly-Bag-2 icli"></i>
                </button>
            </div>

            <Footer></Footer>
        </>

    )

}
export default ProductDetails;