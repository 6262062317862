import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

const NotFound:React.FC=()=>
{
    return (<>
        <Header></Header>
        <p><h2 className="text-center">404 Page Note found</h2></p>
        <Footer></Footer>
        </>
        )
}
export default NotFound;