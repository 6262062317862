import { useEffect, useState } from "react";
//import $ from 'jquery';
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import { CartModel } from "../../components/Cart/CartModel";
import { useNavigate } from "react-router-dom";
import { CommonFunctionality } from "../../repository/CommonFunctionality";
import Swal from "sweetalert2";
export function ProductSearchAndFilterModel(filterData: any) {

    const commonFunctionality = CommonFunctionality()

    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const lstClasses = useSelector((state: AppState) => {
        return typeof (state.storeData.SchoolClasses) != 'undefined' ? state.storeData.SchoolClasses : [];
    }
    );

    const dispatch = useDispatch();
    const repository = SchoolRepository();
    const cartModel = CartModel();
    const navigate = useNavigate();

    const [lstWishlist, setWishlist] = useState([]);
    const [lstSchoolClass, setSchoolClasses] = useState([]);
    const [checkedCategories, setCheckedCategories] = useState([]);
    const [checkedBrands, setCheckedBrands] = useState([]);
    const [checkedDiscounts, setCheckedDiscounts] = useState([]);
    const [checkedClasses, setCheckedClasses] = useState([]);
    const [checkedVariants, setCheckedVariants] = useState([]);
    const [lstProducts, setProducts] = useState([]);
    const [lstAllProducts, setAllProducts] = useState([]);

    const lstSclProducts = useSelector((state: AppState) => {
        return typeof (state.storeData.SchoolProducts) != 'undefined' ? state.storeData.SchoolProducts : [];
    }
    );

    useEffect(() => {
        if (typeof filterData !== 'undefined' && filterData !== '') {
            let filterDataValues = commonFunctionality.stringFormatForJson(filterData);
            if (filterDataValues.type === 'category') {
                setSelectedCategory(filterDataValues.name);
            }
            if (filterDataValues.type === 'class') {
                setSelectedCategory('');
                setSelectedClass(filterDataValues.name);
                if (lstSclProducts.length > 0) {
                    const uniqueCatSet: Set<string> = new Set();
                    const uniquecat: any = [];
                    lstSclProducts.forEach((product: any) => {
                        const catId = `${product.category_id}`;
                        if (!uniqueCatSet.has(catId)) {
                            uniqueCatSet.add(catId);
                            uniquecat.push({ 'category_id': product.category_id });
                        }
                    });
                    productCategories = [...uniquecat];
                    getProductsByCategories();
                }
            }
        }
    }, [filterData, lstSclProducts]);
    
    useEffect(() => {
        categoriesLoad();
    }, [selectedCategory]);
    
    useEffect(() => {
        if (typeof selectedClass !== 'undefined' && selectedClass !== '') {
            const updatedcheckedClasses: any = [...checkedClasses];
            updatedcheckedClasses.push({ 'class': selectedClass });
            setCheckedClasses(updatedcheckedClasses);
        }
    }, [lstAllProducts, lstSchoolClass, selectedClass]);
    
    const lstStoreCat = useSelector((state: AppState) => {
        return typeof (state.storeData.categories) != 'undefined' ? state.storeData.categories : null;
    });
    
    const lstCart = useSelector((state: AppState) => {
        return typeof (state.storeData.cart) != 'undefined' ? state.storeData.cart : [];
    });
    
    const [lstCategories, setCategories] = useState([]);
    
    useEffect(() => {
        setSchoolClasses(lstClasses);
    }, [alert])
    
    const categoriesLoad = () => {
        if (lstStoreCat != null && lstStoreCat.length > 0 && productCategories.length == 0) {
            productCategories = [];
            if (typeof selectedClass !== 'undefined' && selectedClass !== '') {
                let catlist: any = lstStoreCat.filter((x: any) => (+x.is_class_configurable));
                if (catlist.length > 0) {
                    for (let i = 0; i < catlist.length; i++) {
                        productCategories.push({ 'category_id': catlist[i].category_id });
                    }
                }
            } else {
                let sCategory = lstStoreCat.filter((x: any) => x.category_name === selectedCategory)[0];
                if (typeof (sCategory) != 'undefined' && sCategory != '') {
                    productCategories.push(sCategory);
                    getChildCategories(sCategory.category_id);
                    setCategories(typeof (productCategories) != 'undefined' ? productCategories : '');
                    let categories: any = [];
                    productCategories.map((item: any) => {
                        categories.push(item.category_name);
                    });
                }
            }
            getProductsByCategories();
        }
    }
    
    const getProductsByCategories = async () => {
        setProducts([]);
        setAllProducts([]);
        if (productCategories.length > 0) {
            await repository.getProductsByCategories(productCategories).then((results: any) => {
                let lstScProducts = [];
                if (results.length > 0) {
                    for (let i = 0; i < results.length; i++) {
                        if ((+results[i].product_configurable) == 1) {
                            let indp = lstSclProducts.findIndex((product: any) => {
                                return (+product.product_id) === (+results[i].product_id)
                            })
                            if (indp > -1) {
                                lstScProducts.push(results[i]);
                            }
                        } else {
                            lstScProducts.push(results[i]);
                        }
                    }
                }
                let result: any = [...lstScProducts]
                if (result.length > 0) {
                    setAllProducts(result);
                    let brnds: any = prepareBrands(result);
                    setBrands(brnds);
                    let chkBrnds: any = [];
                    brnds.map((item: any) => {
                        chkBrnds.push(item.category_name);
                    });
                    prepareProducts(result);
                }
            });
        }
    }
    
    const prepareProducts = (products: any) => {
        // debugger;
        const updatedProducts: any = [...products];
        if (checkedBrands.length == 0 && checkedCategories.length == 0 && checkedDiscounts.length == 0 && checkedClasses.length == 0) {
            // debugger;
            setProducts(updatedProducts);
        } else {
            let lstFilteredProducts: any = [];
            checkedCategories.map((cat: any) => {
                let resultProducts = lstAllProducts.filter((x: any) => (+x.category_id) === (+cat.category_id));
                for (let i = 0; i < resultProducts.length; i++) {
                    lstFilteredProducts.push(resultProducts[i]);
                }
            });
            checkedBrands.map((brnd: any) => {
                let resultProducts = lstAllProducts.filter((x: any) => (+x.brand_id) === (+brnd.brand_id));
                for (let i = 0; i < resultProducts.length; i++) {
                    lstFilteredProducts.push(resultProducts[i]);
                }
            });
            checkedDiscounts.map((discount: any) => {
                for (let p = 0; p < lstAllProducts.length; p++) {
                    if ((lstAllProducts[p] as any).product_discounts != '') {
                        let indx = (lstAllProducts[p] as any).product_discounts.findIndex((x: any) =>
                            (+x) >= (+discount.discount_percentage));
                        if (indx > -1) {
                            lstFilteredProducts.push(lstAllProducts[p]);
                        }
                    }
                }
            });
            checkedVariants.map((variant: any) => {
                for (let p = 0; p < lstAllProducts.length; p++) {
                    let indx = (lstAllProducts[p] as any).product_variants.findIndex((x: any) =>
                        (x.product_variants_title) == (variant.product_variants_title) && (x.product_variant_value) == (variant.product_variant_value));
                    if (indx > -1) {
                        lstFilteredProducts.push(lstAllProducts[p]);
                    }
                }
            });
            checkedClasses.map((sClass: any) => {
                for (let p = 0; p < lstAllProducts.length; p++) {
                    if ((+ (lstAllProducts[p] as any).product_configurable) == 1) {
                        let indp = lstSclProducts.findIndex((product: any) => {
                            return (((+product.product_id) === (+ (lstAllProducts[p] as any).product_id)) && (product.class == sClass.class))
                        });
                        if (indp > -1) {
                            lstFilteredProducts.push(lstAllProducts[p]);
                        }
                    }
                }
            });
            // debugger;
            setProducts(lstFilteredProducts);
        }
    }
    


    const getProductByCountForClass = (sclass: any) => {
        // debugger;
        let pcount = 0;
        for (let i = 0; i < lstProducts.length; i++) {
            // debugger;
            if ((+ (lstProducts[i] as any).product_configurable) == 1) {
                let indp = lstSclProducts.findIndex((product: any) => {
                    // debugger;
                    return (((+product.product_id) === (+ (lstProducts[i] as any).product_id)) && product.class == sclass)
                });
                // debugger;
                if (indp > -1) {
                    // debugger;
                    pcount = pcount + 1;

                }
                // debugger;
            }
        }
        return pcount;
    }


    var lstProductVarients: any = [];

    var productCategories: any = [];
    const getChildCategories = (parentCategory: number) => {


        for (let i = 0; i < lstStoreCat.length; i++) {

            if ((+lstStoreCat[i].parent_category_id) == parentCategory) {

                productCategories.push(lstStoreCat[i]);

                getChildCategories(lstStoreCat[i].category_id);
            }

        }




    }



    const [lstBrands, setBrands] = useState([]);
    const prepareBrands = (lstResultProducts: any[]) => {
        try {
            const uniqueBrandSet: Set<string> = new Set();
            const uniqueBrands: any = [];

            lstResultProducts.forEach((product: any) => {
                const brandIdName = `${product.brand_id}_${product.brand_name}`;
                if (!uniqueBrandSet.has(brandIdName)) {
                    uniqueBrandSet.add(brandIdName);
                    uniqueBrands.push({ 'brand_id': product.brand_id, 'brand_name': product.brand_name });
                }
            });

            return uniqueBrands;
        } catch (error) {
            console.error("Error parsing JSON:", error);
            return [];
        }
    };


    var lstVariantGroup: any = [];
    const prepareVariantGroup = (lstProductVariants: any[]) => {
        //
        try {
            const variantGroup: string[] = lstProductVariants.map((productVariant: any) => {
                lstProductVarients.push(productVariant);
                return productVariant.product_variants_title

            }


            );
            const uniqueVariantGroup: string[] = Array.from(new Set(variantGroup));
            lstVariantGroup.push(uniqueVariantGroup);
        } catch (error) {
            console.error("Error preparing VariantGroup:", error);
            return [];
        }
    };

    const getProductVarintsByGroup = (variantGroup: any): any[] => {


        return lstProductVarients.filter((varient: any) => {

            return varient.product_variants_title == variantGroup
        })


    }


    const getProductByCount = (type: any, value: any) => {
        //  
        var count: number = 0;
        lstProducts.forEach((product: any) => {
            //     
            count = count + (type == 'Category' ? ((+product.category_id) === (+value) ? 1 : 0) : (type == 'Brand' ? (product.brand_name === value ? 1 : 0) :


                (type == 'Discount' ?


                    (

                        (typeof (product.product_discounts) != 'undefined' && product.product_discounts != '') ?

                            (product.product_discounts.map((disc: any) => {

                                return ((+disc.discount_percentage) > (+value) ? 1 : 0)

                            })

                            )
                            : 0) : 0

                )


            ));

        });

        return count;

    }



    const addToCart = (item: any, ctrid: any) => {
        // if((+item.qty)>0)
        {
            cartModel.addToCart(item);
            var $ = jQuery.noConflict();



            if (($(window) as any).width() > 768) {
                var cart: any = $('.button-item');
            } else {
                var cart: any = $('.mobile-cart ul li a .icli.fly-cate');
            }
            var imgtodrag: any = $('#' + ctrid).parents('.product-box-4, .deal-box').find(".product-image img, .category-image img").eq(0);
            if (imgtodrag) {
                var imgclone = imgtodrag.clone()
                    .offset({
                        top: imgtodrag.offset().top,
                        left: imgtodrag.offset().left
                    })
                    .css({
                        'opacity': '0.5',
                        'position': 'absolute',
                        'height': '130px',
                        'width': '130px',
                        'z-index': '100'
                    })
                    .appendTo($('body'))
                    .animate({
                        'top': cart.offset().top + 10,
                        'left': cart.offset().left + 10,
                        'width': 75,
                        'height': 75
                    }, 1000, 'easeInOutExpo');

                imgclone.animate({
                    'width': 0,
                    'height': 0
                }, function () {
                    // $('.btn-cart').detach()
                });
            }
        }



    }




    const onCategoryChaeckedChange = (e: any, id: number) => {

        const updatedcheckedCategories: any = [...checkedCategories];

        if (e.target.checked) {
            updatedcheckedCategories.push({ 'category_id': id })
        }
        else {

            let ind = checkedCategories.findIndex((x: any) => (+x.category_id) === (+id));
            updatedcheckedCategories.splice(ind, 1);

        }
        setCheckedCategories(updatedcheckedCategories);

    }

    const onClassChaeckedChange = (e: any, className: any) => {
        //// debugger;;

        const updatedcheckedClasses: any = [...checkedClasses];

        if (e.target.checked) {
            updatedcheckedClasses.push({ 'class': className })
        }
        else {

            let ind = checkedClasses.findIndex((x: any) => (x.class) === (className));
            updatedcheckedClasses.splice(ind, 1);

        }
        setCheckedClasses(updatedcheckedClasses);
    }



    const onBrandChaeckedChange = (e: any, id: number) => {

        const updatedcheckedBrands: any = [...checkedBrands];

        if (e.target.checked) {
            updatedcheckedBrands.push({ 'brand_id': id })
        }
        else {

            let ind = checkedBrands.findIndex((x: any) => (+x.brand_id) === (+id));
            updatedcheckedBrands.splice(ind, 1);

        }
        setCheckedBrands(updatedcheckedBrands);

    }


    const onVariantChaeckedChange = (e: any, variant: any) => {

        const updatedcheckedVariants: any = [...checkedVariants];

        if (e.target.checked) {
            updatedcheckedVariants.push(variant)
        }
        else {

            let ind = checkedVariants.findIndex((x: any) => (((x.product_variants_title) === (variant.product_variants_title)) && ((x.product_variant_value) === (variant.product_variant_value))));
            updatedcheckedVariants.splice(ind, 1);

        }
        setCheckedVariants(updatedcheckedVariants);

    }


    const onDiscountCheckedChange = (e: any, discount: number) => {

        const updatedcheckedDiscount: any = [...checkedDiscounts];

        if (e.target.checked) {
            updatedcheckedDiscount.push(discount)
        }
        else {

            let ind = checkedVariants.findIndex((x: any) => (((x) === discount)));
            updatedcheckedDiscount.splice(ind, 1);

        }
        setCheckedDiscounts(updatedcheckedDiscount);

    }





    useEffect(() => {
        prepareProducts(lstAllProducts);
    }, [checkedCategories, checkedBrands, checkedVariants, checkedDiscounts, checkedClasses]);



    const onSortChange = (type: any) => {
        switch (type) {

            case 'popularity':
                {
                    break;
                }
            case 'lowtohigh':
                {
                    const updatedProducts: any = [...lstProducts];
                    // debugger;
                    updatedProducts.sort((a: any, b: any) => a.selling_price - b.selling_price);
                    setProducts(updatedProducts);
                    break;
                }
            case 'hightolow':
                {

                    const updatedProducts: any = [...lstProducts];
                    // debugger;
                    updatedProducts.sort((a: any, b: any) => b.selling_price - a.selling_price);
                    setProducts(updatedProducts);
                    break;
                }

            case 'newest':
                {


                    const updatedProducts: any = [...lstProducts];
                    // debugger;
                    updatedProducts.sort((a: any, b: any) => b.product_details_id - a.product_details_id);
                    setProducts(updatedProducts);
                    break;
                }



        }

    }

    const onProductClick = ((products: any) => {

        debugger;
        try {
            var product_feature_feilds = (((products.product_feature_feilds)));
            products.product_feature_feilds = commonFunctionality.stringFormatForJson(product_feature_feilds)
        } catch (e) {
            ////// debugger;
        }
        try {
            var product_resources = (((products.product_resources)));
            products.product_resources = commonFunctionality.stringFormatForJson(product_resources)
        } catch (e) {
            //  //// debugger; 
        }
        try {
            var product_variants = (((products.product_variants)));
            products.product_variants = commonFunctionality.stringFormatForJson(product_variants)
            prepareVariantGroup(products.product_variants);


        } catch (e) {
            ////// debugger; 
        }


        try {
            var productDiscounts = (((products.product_discounts)));
            products.product_discounts = commonFunctionality.stringFormatForJson(productDiscounts)


        } catch (e) {
            ////// debugger; 
        }






        let selectedProduct: any = lstProducts.filter((lproduct: any) => {
            return (+lproduct.product_id) === (+products.product_id)
        })
        selectedProduct.map((product: any) => {

            product.selected_product_details_id = products.product_details_id

        });

        dispatch({
            type: ActionType.STORE,
            payload: {
                storeData: selectedProduct,
                viewName: 'selectedProduct',
            },
        });
        navigate('/productdetails');


    });
    const onPriceChange = (e: any) => {
        setPriceValue(e.target.value);

    }
    const [priceValue, setPriceValue] = useState(50); // Initial value
    const [fromPrice, setFromPrice] = useState(0);
    const [toPrice, setToPrice] = useState(100000);
    
    useEffect(() => {

        $(function () {
            //// debugger;;
            var $range = $(".js-range-slider"),
                $inputFrom = $(".js-input-from"),
                $inputTo = $(".js-input-to"),
                instance: any,
                min = 0,
                max = 100000,
                from = 0,
                to = 0;
                ($range as any).ionRangeSlider({
                    type: "double",
                    min: min,
                    max: max,
                    from: fromPrice,
                    to: toPrice,
                    prefix: 'Rs. ',
                    onStart: updateInputs,
                    onChange: updateInputs,
                    step: 100,
                    prettify_enabled: true,
                    prettify_separator: ".",
                    values_separator: " - ",
                    force_edges: true
                });

            instance = $range.data("ionRangeSlider");
            function updateInputs(data: any) {
                debugger;
                from = data.from;
                to = data.to;
                setFromPrice(from);
                setToPrice(to);
                $inputFrom.prop("value", from);
                $inputTo.prop("value", to);
                //prepareProductsByPrice(lstProducts);
            }

            $inputFrom.on("input", function () {
                //// debugger;;
                var val = $(this).prop("value");

                // validate
                if (val < min) {
                    val = min;
                } else if (val > to) {
                    val = to;
                }

                instance.update({
                    from: val
                });
            });

            $inputTo.on("input", function () {
                //// debugger;;
                var val = $(this).prop("value");

                // validate
                if (val < from) {
                    val = from;
                } else if (val > max) {
                    val = max;
                }

                instance.update({
                    to: val
                });
            });

        });
    }, [alert])
    // const prepareProductsByPrice=(lstFProducts:any)=>
    // {

    //    // 
    // //// debugger;;
    // let resultProd:any=[];
    //    for(let i=0;i<lstFProducts.length;i++)
    //    {
    //     if ( (+lstFProducts.selling_price)>=(+fromPrice) &&   (+lstFProducts.selling_price)<=(+toPrice) )
    //     {

    //         resultProd.push(lstFProducts[i])
    //     }

    //    }

    //    setProducts(resultProd);
    // }


    useEffect(() => {
        getWishlistDetails();

    }, [alert]
    )

    const getWishlistDetails = async () => {

        await repository.viewWishlistDetailsbyCustomer().then((results: any) => {
            setWishlist([])
            //// debugger;;

            if (typeof (results) != 'undefined' && results.length > 0) {


                setWishlist(results);
            }
        }
        )

    }

    const isWishlistItem = (product_details_id: any) => {


        let index = lstWishlist.findIndex(((x: any) => x.product_details_id === product_details_id));

        return index;
    }

    const onWishlistClick = async (data: any) => {
        debugger;
        let wishlist: any = [...lstWishlist];
        debugger;
        let index = wishlist.findIndex(((x: any) => x.product_details_id === data.product_details_id));
        if (index == -1) {
            wishlist.push(data);
            debugger;
        } else {
            debugger;
            wishlist.splice(index, 1);
        }
        setWishlist(wishlist);
        debugger;
        await repository.saveWishlist(wishlist).then((result: any) => {
            debugger;

            $('#wishListProducts').text(result[0]["@Records"]);

            if (isWishlistItem(data.product_details_id)) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    text: 'Wishlist added successfully !'
                });
                debugger;
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    text: 'Wishlist removed successfully !'
                });
                debugger;
            }
        })

    }
    
    const imagePath = repository.ecommerceImagePath;
    return { isWishlistItem, onWishlistClick, toPrice, fromPrice, priceValue, onPriceChange, getProductByCountForClass, onClassChaeckedChange, lstSchoolClass, onProductClick, onSortChange, onDiscountCheckedChange, onVariantChaeckedChange, onBrandChaeckedChange, onCategoryChaeckedChange, addToCart, imagePath, lstProducts, lstCategories, lstBrands, lstVariantGroup, getProductVarintsByGroup, getProductByCount, checkedClasses }
}