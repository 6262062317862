import { UserState } from "./UserState";
import * as CryptoJS from 'crypto-js';
import React from 'react';
export  function  SchoolRepository()
{
const userState=UserState();
const schoolApi='https://api.educationtodayindia.com/educationtodayindiaschoolwebapi/api/';
const schoolImagePath="https://api.educationtodayindia.com/educationtodayindiaschoolwebapi/public/images/"


const ecommerceApi='https://api.educationtodayindia.com/educationtodayindiawebapi/api/';
const ecommerceImagePath="https://api.educationtodayindia.com/educationtodayindiawebapi/public/images/"


// const api='https://api.educationtodayindia.com/testing/webapi/api/';
// const imagePath="https://api.educationtodayindia.com/testing/webapi/public/images/"
const viewCities=async (district_id:number)=>
{
  $("#loaderParent").show();
  try {
    const response = await fetch(schoolApi+'getcitiesbydistrict', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            
          },
          body: JSON.stringify({district_id:district_id}),
        }
          );
            
          const data = await   response.json()
          $("#loaderParent").hide();
          return  data;
      }catch(e)
      {
        $("#loaderParent").hide();
      }
}
const loadCities=async ()=>
{
  try{
  $("#loaderParent").show();
	const response = await fetch(schoolApi+'getcities');
	const data = await response.json();
  $("#loaderParent").hide();
  return  data;
}catch(e)
{
  $("#loaderParent").hide();
}
}
 



const viewClusters=async (city_id:number)=>
{
  try{
  $("#loaderParent").show();
    const response = await fetch(schoolApi+'getclustersbycity', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({city_id:city_id}),
      }
        );
        const data = await   response.json()
        $("#loaderParent").hide();
        return  data;
      }catch(e)
      {
        $("#loaderParent").hide();
      }
}


const getMenuCategories=async ()=>
{
  try{
  $("#loaderParent").show();
    const response = await fetch(ecommerceApi+'getmenucategories');
    const data = await   response.json();
    $("#loaderParent").hide();
    return  data;
  }catch(e)
  {
    $("#loaderParent").hide();
  }

}


const getBanners=async ()=>
{
  try{
  $("#loaderParent").show();
    const response = await fetch(ecommerceApi+'viewbanners');
    const data = await   response.json();
    $("#loaderParent").hide();
    return  data;
  }catch(e)
  {
    $("#loaderParent").hide();
  }

}




const viewStates=async ()=>
{
  try{
  $("#loaderParent").show();
    const response = await fetch(schoolApi+'getstates');
    const data = await   response.json();
    $("#loaderParent").hide();
    return  data;
  }catch(e)
  {
    $("#loaderParent").hide();
  }

}





const viewDistricts=async (state_id:any)=>
{
  try{
  $("#loaderParent").show();
  const response = await fetch(schoolApi+'getdistrictsbystate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers as needed
    },
    body: JSON.stringify({state_id:state_id}),
  }
    );
  const data = await response.json();
  $("#loaderParent").hide();
    return  data;
  }catch(e)
  {
    $("#loaderParent").hide();
  }

}



const getClassesBySchoolId=async (school_id:any)=>
{
  try{
    $("#loaderParent").show();
    const response = await fetch(schoolApi+'getclassesbyschool', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
      body: JSON.stringify({school_id:school_id}),
    }
      );
    const data = await response.json();
    $("#loaderParent").hide();
      return  data;
    }catch(e)
    {
      $("#loaderParent").hide();
    }

}
//   const prepareEncryptData =  (data: any) => {
//         //debugger;;
//         const secretKey = 'Hg5kP0l9Zx6Cv7Bn8Mj1Qa2Ws3Ed4Rf5';
//         const iv = '5739363859398995';
//         //   const iv = CryptoJS.lib.WordArray.random(16);
//         const cipherText  = encryptData(JSON.stringify(data), secretKey, CryptoJS.enc.Utf8.parse(iv));

//         // const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(state), secretKey).toString();
//         //  const cipherText = CryptoJS.AES.encrypt(JSON.stringify(state), secretKey, { iv }).toString();

//         return { data: cipherText };
       
//      }

// const encryptData = (data: string, key: string,iv:any) => {
//   // Make sure the key is 32 bytes (256 bits)
//   if (key.length !== 32) {
//       throw new Error('AES-256-CBC key must be 32 bytes long');
//   }

//   // Generate a random IV (Initialization Vector)
//  // const iv = CryptoJS.lib.WordArray.random(16);

//   // Encrypt using AES-256-CBC
//   const cipherText = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
//       iv: iv,
//       mode: CryptoJS.mode.CBC,
//       padding: CryptoJS.pad.Pkcs7,
//   }).toString();

//   return  cipherText;//, iv: CryptoJS.enc.Base64.stringify(iv) };
// };
const prepareEncryptData =  (data:any) => {
  //debugger;;
  
  const secretKey:any = process.env.REACT_APP_SECRET_KEY;
  const iv:any= process.env.REACT_APP_IV;


  
  // Parse the IV correctly
  const cipherText = encryptData(JSON.stringify(data), secretKey, (iv));

  return  cipherText ;
}
const prepareDecryptData =  (data:any) => {
  //debugger;;

  
  const secretKey:any = process.env.REACT_APP_SECRET_KEY;
  const iv:any = process.env.REACT_APP_IV;

  // Parse the IV correctly
  const returnData = decryptData((data), secretKey, (iv));

  return  returnData ;
}


const decryptData = (encryptedDataBase64: string, encryptionKey: string, iv: string): string | null => {
  try {
      // Decode IV from base64
 //     const iv = atob(ivBase64);

      // Decode encrypted data from base64
      const encryptedData = atob(encryptedDataBase64);

      // Convert encryption key to WordArray
      const key = CryptoJS.enc.Utf8.parse(encryptionKey);

      // Decrypt the data using AES-256-CBC
      const decryptedData = CryptoJS.AES.decrypt(encryptedDataBase64, key, {
          iv: CryptoJS.enc.Utf8.parse(iv),  // Parse IV as Hex
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
//let dsds=decryptedData.toString();
      // Convert decrypted data to Utf8 string
      const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
      
      return decryptedText;
  } catch (error) {
      console.error('Error decrypting data:', error);
      return null;
  }
};



const encryptData = (data:any, key:any, iv:any) => {
  if (key.length !== 32) {
      throw new Error('AES-256-CBC key must be 32 bytes long');
  }
  const ivBase64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(iv));
  //debugger;;
  const cipherText = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),  // Ensure the IV is a WordArray
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
  }).toString();

  return cipherText;
};



const updateOrderPaymentStatus=async(rsponseData:any) =>
{

  $("#loaderParent").show();
  try{
 // if (isLoading) {
   // return <div>Loading...</div>;
 // }
  const response = await fetch(ecommerceApi+'updateorderpaymentstatus', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers as needed
    },
    body: (rsponseData),
  }
    );
  const data:any  = await response.json();
  
  $("#loaderParent").hide();
  return  data;
}catch(e)
{
  $("#loaderParent").hide();
}
}
 


const searchSchools=async(searchData:any) =>
{

  $("#loaderParent").show();
  try{
 // if (isLoading) {
   // return <div>Loading...</div>;
 // }
  const response = await fetch(schoolApi+'searchschools', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers as needed
    },
    body: JSON.stringify(searchData),
  }
    );
  const data:any  = await response.json();
  
  $("#loaderParent").hide();
  return  data;
}catch(e)
{
  $("#loaderParent").hide();
}
}
 


const uploadImage = async (formData:any) =>{
  $("#loaderParent").show();
  try{
  const response:any = await fetch(ecommerceApi+'upload', {
    method: 'POST',
    body: formData,
});
$("#loaderParent").hide();
return  response;
}catch(e)
{
  $("#loaderParent").hide();
}
}
 


const getProductDetailsBySkuId=async (sku_id:any)=>
{
  try{
  $("#loaderParent").show();
  const response = await fetch(ecommerceApi+'getproductdetailsbyproduct', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers as needed
    },
    body: JSON.stringify({'sku_id':sku_id}),
  }
    );
  const data = await response.json();
  $("#loaderParent").hide();
    return  data;
  }catch(e)
  {
    $("#loaderParent").hide();
  }

}

const getProductDetailsByDetailsId=async (product_details_id:any)=>
{
  try{
  $("#loaderParent").show();
  const response = await fetch(ecommerceApi+'getproductdetailsbyproductdetails', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers as needed
    },
    body: JSON.stringify({'product_details_id':product_details_id}),
  }
    );
  const data = await response.json();
  $("#loaderParent").hide();
    return  data;
  }catch(e)
  {
    $("#loaderParent").hide();
  }

}

const getRelatedProducts=async (product:any)=>
{
  try{
  $("#loaderParent").show();
  const response = await fetch(ecommerceApi+'getrelatedproducts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers as needed
    },
    body: JSON.stringify({product_id:product}),
  }
    );
  const data = await response.json();
  $("#loaderParent").hide();
    return  data;
  }catch(e)
  {
    $("#loaderParent").hide();
  }

}



const getProductsByCategories=async (categories:any)=>
{
  try{
  $("#loaderParent").show();
  const response = await fetch(ecommerceApi+'getproductsbycategories', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers as needed
    },
    body: JSON.stringify(categories),
  }
    );
  const data = await response.json();
  $("#loaderParent").hide();
    return  data;
  }catch(e)
  {
    $("#loaderParent").hide();
  }

}
const saveReturns=async(returnData:any) =>
{

  $("#loaderParent").show();
  try{
 // if (isLoading) {
   // return <div>Loading...</div>;
 // }
  const response = await fetch(ecommerceApi+'savereturn', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers as needed
    },
    body: JSON.stringify(returnData),
  }
    );
  const data:any  = await response.json();
  
  $("#loaderParent").hide();
  return  data;
}catch(e)
{
  $("#loaderParent").hide();
}
}



const saveCustomer=async(userData:any) =>
{

  
  $("#loaderParent").show();
  try{
 // if (isLoading) {
   // return <div>Loading...</div>;
 // }
  const response = await fetch(ecommerceApi+'savecustomer', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers as needed
    },
    body: JSON.stringify(userData),
  }
    );
  const data:any  = await response.json();
  
  $("#loaderParent").hide();
  return  data;
}catch(e)
{
  $("#loaderParent").hide();
}
}





const customerLogin=async(loginData:any) =>
{

  $("#loaderParent").show();
  try{
 // if (isLoading) {
   // return <div>Loading...</div>;
 // }
  const response = await fetch(ecommerceApi+'customerlogin', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers as needed
    },
    body: JSON.stringify(loginData),
  }
    );
  const data:any  = await response.json();
  
  $("#loaderParent").hide();
  return  data;
}catch(e)
{
  $("#loaderParent").hide();
}
}





const getCustomerByMobileNumber=async(loginData:any) =>
{

  $("#loaderParent").show();
  try{
 // if (isLoading) {
   // return <div>Loading...</div>;
 // }
  const response = await fetch(ecommerceApi+'getcustomerbymobilenumber', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers as needed
    },
    body: JSON.stringify(loginData),
  }
    );
  const data:any  = await response.json();
  
  $("#loaderParent").hide();
  return  data;
}catch(e)
{
  $("#loaderParent").hide();
}
}



const orderPaymentStatus=async(order_id:any) =>
{

  $("#loaderParent").show();
  try{
 // if (isLoading) {
   // return <div>Loading...</div>;
 // }
  const response = await fetch('https://payments.educationtodayindia.com/HDFC/orderStatus.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers as needed
    },
    body: JSON.stringify({order_id:order_id}),
  }
    );
  const data:any  = await response.json();
  
  $("#loaderParent").hide();
  return  data;
}catch(e)
{
  $("#loaderParent").hide();
}
}


const getOrdersByCustomer=async ()=>
{
  try{
  $("#loaderParent").show();
  
  
    const response = await fetch(ecommerceApi+'getordersbyuser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({customer_id:userState.loggedUser.customer_id}),
      }
        );
        const data = await   response.json()
        $("#loaderParent").hide();
        return  data;
      }catch(e)
      {
        $("#loaderParent").hide();
      }
}




const getChildrensByCustomer=async ()=>
{
  try{
  $("#loaderParent").show();
  
  
    const response = await fetch(ecommerceApi+'getchildrensbycustomer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({customer_id:userState.loggedUser.customer_id}),
      }
        );
        const data = await   response.json()
        $("#loaderParent").hide();
        return  data;
      }catch(e)
      {
        $("#loaderParent").hide();
      }
}


const getAddressByCustomer=async ()=>
{
  try{
  $("#loaderParent").show();
  
  
    const response = await fetch(ecommerceApi+'getaddressbycustomer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({customer_id:userState.loggedUser.customer_id}),
      }
        );
        const data = await   response.json()
        $("#loaderParent").hide();
        return  data;
      }catch(e)
      {
        $("#loaderParent").hide();
      }
}





const getSchoolProductsBySchool=async (school_name:any)=>
{
  try{
  $("#loaderParent").show();
 
    const response = await fetch(schoolApi+'getschoolproductsbyschool', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({'school_name':school_name}),
      }
        );
        const data = await   response.json()
        $("#loaderParent").hide();
        return  data;
      }catch(e)
      {
        $("#loaderParent").hide();
      }
}


const saveCustomerChildrens=async (customer_address:any)=>
{
  try{
  $("#loaderParent").show();

  customer_address.customer_id=userState.loggedUser.customer_id;
    const response = await fetch(ecommerceApi+'savecustomerchildrens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify(customer_address),
      }
        );
        const data = await   response.json()
        $("#loaderParent").hide();
        return  data;
      }catch(e)
      {
        $("#loaderParent").hide();
      }
}
const saveCustomerAddress=async (customer_address:any)=>
{
  try{
  $("#loaderParent").show();

  customer_address.customer_id=userState.loggedUser.customer_id;
    const response = await fetch(ecommerceApi+'savecustomeraddress', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify(customer_address),
      }
        );
        const data = await   response.json()
        $("#loaderParent").hide();
        return  data;
      }catch(e)
      {
        $("#loaderParent").hide();
      }
}






const checkPincode=async (pincode:any)=>
{

  try{
    $("#loaderParent").show();
  
     
      const response = await fetch(ecommerceApi+'checkdeliveryavailable', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
          },
          body: JSON.stringify({'pincode':pincode}),
        }
          );
          const data = await   response.json()
          $("#loaderParent").hide();
          return  data;
        }catch(e)
        {
          $("#loaderParent").hide();
        }
}


const viewCartDetailsbyCustomer=async ()=>
{
  try{
  $("#loaderParent").show();

   
    const response = await fetch(ecommerceApi+'viewcartdetailsbycustomer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({'customer_id':userState.loggedUser.customer_id}),
      }
        );
        const data = await   response.json()
        $("#loaderParent").hide();
        return  data;
      }catch(e)
      {
        $("#loaderParent").hide();
      }
}


const viewWishlistDetailsbyCustomer=async ()=>
{
  try{
  $("#loaderParent").show();

   
    const response = await fetch(ecommerceApi+'viewwishlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({'customer_id':userState.loggedUser.customer_id}),
      }
        );
        const data = await   response.json()
        $("#loaderParent").hide();
        return  data;
      }catch(e)
      {
        $("#loaderParent").hide();
      }
}






const saveWishlist=async (wishlist_details:any)=>
{
  try{
  $("#loaderParent").show();
if(wishlist_details.length==0)
{
  wishlist_details.push({customer_id:userState.loggedUser.customer_id});
}
for(let i=0;i<wishlist_details.length;i++)
{
  wishlist_details[i].customer_id=userState.loggedUser.customer_id;
}

  
    const response = await fetch(ecommerceApi+'savewishlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify(wishlist_details),
      }
        );
        const data = await   response.json()
        $("#loaderParent").hide();
        return  data;
      }catch(e)
      {
        $("#loaderParent").hide();
      }
}


const saveCart=async (cart_details:any)=>
{
  try{
  $("#loaderParent").show();

  cart_details.customer_id=userState.loggedUser.customer_id;
    const response = await fetch(ecommerceApi+'savecart', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify(cart_details),
      }
        );
        const data = await   response.json()
        $("#loaderParent").hide();
        return  data;
      }catch(e)
      {
        $("#loaderParent").hide();
      }
}

const searchProducts=async (searchData:any)=>
{
  let   searchString={'searchString':searchData};

  try{
    $("#loaderParent").show();
  
     
      const response = await fetch(ecommerceApi+'searchproducts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
          },
          body: JSON.stringify(searchString),
        }
          );
          const data = await   response.json()
          $("#loaderParent").hide();
          return  data;
        }catch(e)
        {
          $("#loaderParent").hide();
        }
}




const saveOrder=async (orderDetails:any,productDetails:any)=>
{
  let   order={'order_details':orderDetails,'products_details':productDetails};

  try{
    $("#loaderParent").show();
  
     
      const response = await fetch(ecommerceApi+'saveorder', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
          },
          body: JSON.stringify(order),
        }
          );
          const data = await   response.json()
          $("#loaderParent").hide();
          return  data;
        }catch(e)
        {
          $("#loaderParent").hide();
        }
}


const getDisacounts=async ()=>
{
  try{
    $("#loaderParent").show();
  
     
      const response = await fetch(ecommerceApi+'getdiscounts', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
          } 
        }
          );
          const data = await   response.json()
          $("#loaderParent").hide();
          return  data;
        }catch(e)
        {
          $("#loaderParent").hide();
        }

}
const getDiscountFromCoupon=async (coupon:any,orderValue:any)=>
{


  try{
    $("#loaderParent").show();
    
    
      const response = await fetch(ecommerceApi+'getdiscountfromcoupon', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
          },
          body: JSON.stringify({customer_id:userState.loggedUser.customer_id,coupon_code:coupon,order_value:orderValue}),
        }
          );
          const data = await   response.json()
          $("#loaderParent").hide();
          return  data;
        }catch(e)
        {
          $("#loaderParent").hide();
        }


}
const getReviewsByProduct=async (product_id:any)=>
{

  try{
    $("#loaderParent").show();
    
    
      const response = await fetch(ecommerceApi+'getreviewsbyproduct', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
          },
          body: JSON.stringify({product_id:product_id}),
        }
          );
          const data = await   response.json()
          $("#loaderParent").hide();
          return  data;
        }catch(e)
        {
          $("#loaderParent").hide();
        }
}



const saveRating=async (orderProduct:any)=>
{

  try{
    $("#loaderParent").show();
    
    
      const response = await fetch(ecommerceApi+'saverating', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
          },
          body: JSON.stringify(orderProduct),
        }
          );
          const data = await   response.json()
          $("#loaderParent").hide();
          return  data;
        }catch(e)
        {
          $("#loaderParent").hide();
        }
}
return {getChildrensByCustomer,saveCustomerChildrens,prepareDecryptData,prepareEncryptData,updateOrderPaymentStatus,orderPaymentStatus,saveReturns,getProductDetailsByDetailsId,getReviewsByProduct,saveRating,viewWishlistDetailsbyCustomer,saveWishlist,getDiscountFromCoupon,getDisacounts,checkPincode,getOrdersByCustomer,saveOrder,searchProducts,getBanners,viewCartDetailsbyCustomer,saveCart,getSchoolProductsBySchool,saveCustomerAddress,getAddressByCustomer,customerLogin,saveCustomer,getRelatedProducts,getProductDetailsBySkuId,getProductsByCategories,schoolImagePath,ecommerceImagePath,viewCities,viewClusters,viewStates,viewDistricts,loadCities,uploadImage,searchSchools,getClassesBySchoolId,getMenuCategories};

}