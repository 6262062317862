import { ClassCategoryModel } from "./ClassCategoryModel";

const ClassCategories:React.FC=()=>
{
    const viewMode=ClassCategoryModel();
    return (<>
    
    {/* <!-- className Category Section Start --> */}
    <section className="category-section-3 white" style={viewMode.lstClasses.length>0?{display:'block'}:  {display:'none'}}>
        <div className="container-fluid">
            <div className="title">
                <h2>Select Class</h2>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="category-slider-1 arrow-slider wow fadeInUp">

                        {
viewMode.lstClasses.map((item:any, i)=>
{
return (       <div>
    <div className="category-box-list">
        <a  className="category-name">
            <h4 className="text-center">{item.classes}</h4>
            {/* <h6>25 items</h6> */}
        </a>
        <div className="category-box-view">
            <a  >
                <img src={`/images/class/${((i+1) % 6) + 1}.png`}
                    className="img-fluid blur-up lazyload" alt=""/>

            </a>
            <h4 className="class-h4">{item.classes}</h4>
            <button onClick={()=>viewMode.onClassCategoryClicked(item.classes)} className="btn shop-button">
                <span>Select</span>
                <i className="fas fa-angle-right"></i>
            </button>
        </div>
    </div>
</div>)
 

}
)

                        }
                 

                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    {/* <!-- className Category Section End --> */}
    
   
    </>
    
    
    
    
    );
}
export default ClassCategories;