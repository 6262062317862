import { useDispatch, useSelector } from "react-redux";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { CartModel } from "../../components/Cart/CartModel";
import { ActionType, AppState } from "../../store/Page.Actions";
import { useEffect, useState } from "react";
// import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import { CommonFunctionality } from "../../repository/CommonFunctionality";


export function ProductDetailsModel(sku: any) {

    const commonFunctionality = CommonFunctionality()
    const dispatch = useDispatch();
    const repository = SchoolRepository();
    const cartModel = CartModel();
    const navigate = useNavigate();
    const [product, setSelectedProduct] = useState([]);
    const [productDetails, setSelectedProductDetails] = useState({});
    const [storedProduct, setStoredProduct] = useState(null);
    const selectedProduct = useSelector((state: AppState) => {
        //    /  
        return typeof (state.storeData.selectedProduct) != 'undefined' ? state.storeData.selectedProduct : {};
    }
    );


    const lstCart = useSelector((state: AppState) => {



        return typeof (state.storeData.cart) != 'undefined' ? state.storeData.cart : [];
    }
    );

    useEffect(() => {
        if (selectedProduct != null) {
            setStoredProduct(selectedProduct);

        }

    }, [alert, selectedProduct]);

    useEffect(() => {
      //  return () => {
            loadScripts();

            if (typeof (sku) != 'undefined' && sku != '') {
                getProductDetailsBySku(sku);
            } else {
                getProductDetailsByDetailsId(selectedProduct[0].selected_product_details_id);
                // setSelectedProduct(selectedProduct);
                // prepareVariantGroups(selectedProduct);
                // setSelectedProductDetailsVariant(selectedProduct);
                // getRelatedProducts();
            }

     //   }

    }, [sku, storedProduct]);

    const getProductDetailsByDetailsId = (details_id: any) => {
        //debugger;;

        repository.getProductDetailsByDetailsId(details_id).then((result: any) => {
            if (result.length > 0) {

                for (let i = 0; i < result.length; i++) {

                    try {
                        var product_feature_feilds = (((result[i].product_feature_feilds)));
                        result[i].product_feature_feilds = commonFunctionality.stringFormatForJson(product_feature_feilds)
                    } catch (e) {
                        ////debugger;
                    }
                    try {
                        var product_resources = (((result[i].product_resources)));
                        result[i].product_resources = commonFunctionality.stringFormatForJson(product_resources)
                    } catch (e) {
                        //  //debugger; 
                    }
                    try {
                        var ProductVariants = (((result[i].ProductVariants)));
                        result[i].ProductVariants = commonFunctionality.stringFormatForJson(ProductVariants)


                    } catch (e) {
                        ////debugger; 
                    }


                    try {
                        var productDiscounts = (((result[i].product_discounts)));
                        result[i].product_discounts = commonFunctionality.stringFormatForJson(productDiscounts)


                    } catch (e) {
                        ////debugger; 
                    }


                    try {
                        var product_variants = (((result[i].product_variants)));
                        result[i].product_variants = commonFunctionality.stringFormatForJson(product_variants)


                    } catch (e) {
                        ////debugger; 
                    }




                }
                setSelectedProduct(result)
                prepareVariantGroups(result);
                setSelectedProductDetailsVariant(result);
                //getRelatedProducts();
            }

            ;
        });

    }

    const setSelectedProductDetailsVariant = ((selectedProduct: any) => {

        let selectedProductDetailsId = selectedProduct[0].selected_product_details_id;


        let resultsproducts = selectedProduct.filter((product: any) => {

            return product.product_details_id === selectedProductDetailsId;
        })

        setSelectedProductDetails(resultsproducts[0]);
    });

    const getProductDetailsBySku = (sku: any) => {

        repository.getProductDetailsBySkuId(sku).then((result: any) => {
            if (result.length > 0) {

                for (let i = 0; i < result.length; i++) {

                    try {
                        var product_feature_feilds = (((result[i].product_feature_feilds)));
                        result[i].product_feature_feilds = commonFunctionality.stringFormatForJson(product_feature_feilds)
                    } catch (e) {
                        ////debugger;
                    }
                    try {
                        var product_resources = (((result[i].product_resources)));
                        result[i].product_resources = commonFunctionality.stringFormatForJson(product_resources)
                    } catch (e) {
                        //  //debugger; 
                    }
                    try {
                        var ProductVariants = (((result[i].ProductVariants)));
                        result[i].ProductVariants = commonFunctionality.stringFormatForJson(ProductVariants)


                    } catch (e) {
                        ////debugger; 
                    }


                    try {
                        var productDiscounts = (((result[i].product_discounts)));
                        result[i].product_discounts = commonFunctionality.stringFormatForJson(productDiscounts)


                    } catch (e) {
                        ////debugger; 
                    }


                    try {
                        var product_variants = (((result[i].product_variants)));
                        result[i].product_variants = commonFunctionality.stringFormatForJson(product_variants)


                    } catch (e) {
                        ////debugger; 
                    }




                }
                setSelectedProduct(result)
                prepareVariantGroups(result);
                setSelectedProductDetailsVariant(result);
                //getRelatedProducts();
            }

            ;
        });

    }


    // useEffect(() => {
    //     return()=>
    //              {
    //     loadScripts();
    //              }
    // },[]);


    const loadScripts = () => {

        const script1 = document.createElement('script');
        script1.src = 'https://code.jquery.com/jquery-3.4.1.min.js';
        script1.async = true;
        document.body.appendChild(script1);

        script1.onload = () => {


            var $ = jQuery.noConflict();
            try {

                $(document).ready(function () {
                    const script3 = document.createElement('script');
                    script3.src = '/js/slick/slick.js'; // Replace with the correct local path or CDN
                    script3.async = true;
                    document.body.appendChild(script3);

                    const script4 = document.createElement('script');
                    script4.src = '/js/slick/slick-animation.min.js'; // Replace with the correct local path or CDN
                    script4.async = true;
                    document.body.appendChild(script4);


                    const script5 = document.createElement('script');
                    script5.src = '/js/custom-slick-animated.js'; // Replace with the correct local path or CDN
                    script5.async = true;
                    document.body.appendChild(script5);



                    const script6 = document.createElement('script');
                    script6.src = '/js/slick/custom_slick.js'; // Replace with the correct local path or CDN
                    script6.async = true;
                    document.body.appendChild(script6);

                });

            } catch (e) {

            }




        };
    };

    const displayVarients = (varients: any) => {
        // //debugger;
        if (varients == '' || typeof (varients) == 'undefined') {
            return '';
        }
        try {
            return ' with ' + varients.map((obj: any) => `${obj.product_variant_value} ${obj.product_variants_title}`).join(' with ');
        } catch (e) {

        }

    }
    const [lstVariantGroup, setVariantGroup] = useState([]);
    const prepareVariantGroups = (lstproducts: any[]) => {

        try {
            const uniqueVariantGroupSet: Set<string> = new Set();
            const uniqueVariantGroup: any = [];

            lstproducts.forEach((products: any) => {
                if (products.product_variants != null && typeof (products.product_variants) != 'undefined' && products.product_variants != '') {
                    products.product_variants.forEach((product: any) => {

                        const variants_Name = `${product.product_variants_title}`;
                        if (!uniqueVariantGroupSet.has(variants_Name)) {
                            uniqueVariantGroupSet.add(variants_Name);
                            uniqueVariantGroup.push({ 'product_variants_title': product.product_variants_title });
                        }
                    })

                }
            });

            setVariantGroup(uniqueVariantGroup);
        } catch (error) {
            console.error("Error parsing JSON:", error);
            return [];
        }
    };


    const getActiveClass = (group: any, value: any): any => {


        if ((productDetails as any).product_variants != null && (productDetails as any).product_variants != '' && typeof ((productDetails as any).product_variants) != 'undefined') {
            let index = (productDetails as any).product_variants.findIndex((x: any) => {
                return x.product_variants_title == group && x.product_variant_value == value
            })
            if (index > -1) {
                return 'active'
            }
            else {
                return '';
            }
        } else {
            return '';
        }

    }


    const getVariantbyGroup = (variantGroup: any) => {
        try {
            const uniqueVariantSet: Set<string> = new Set();
            const uniqueVariant: any = [];

            product.forEach((products: any) => {
                if (products.product_variants != null && typeof (products.product_variants) != 'undefined' && products.product_variants != '') {
                    products.product_variants.forEach((product: any) => {
                        if (product.product_variants_title == variantGroup) {
                            const variants_Name = `${product.product_variant_value}`;
                            if (!uniqueVariantSet.has(variants_Name)) {
                                uniqueVariantSet.add(variants_Name);
                                uniqueVariant.push({ 'product_variant_value': product.product_variant_value });
                            }
                        }
                    })
                }


            });

            return uniqueVariant;
        } catch (error) {
            console.error("Error parsing JSON:", error);
            return [];
        }
    };
    function deepCopy(obj: any) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        let copy: any;
        if (obj instanceof Array) {
            copy = [];
            for (let i = 0; i < obj.length; i++) {
                copy[i] = deepCopy(obj[i]);
            }
            return copy;
        } else if (obj instanceof Object) {
            copy = {};
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    copy[key] = deepCopy(obj[key]);
                }
            }
            return copy;
        }
    }
    const onVaraintClick = (group: any, value: any) => {
        //debugger;;
        var preProductDetails: any = deepCopy(productDetails);

        let preProductVaraint: any = preProductDetails.product_variants;
        let selectableProductDetailsId = 0;
        let clickedVaraintProductDetailsId = 0;
        for (let i = 0; i < preProductVaraint.length; i++) {

            if (preProductVaraint[i].product_variants_title === group) {
                preProductVaraint[i].product_variant_value = value;
                break;
            }
        }


        for (let i = 0; i < product.length; i++) {

            let productVaraint: any = (product[i] as any).product_variants;

            if (clickedVaraintProductDetailsId == 0) {
                let cindex = productVaraint.findIndex((pv: any) => {
                    return (pv.product_variants_title === group && pv.product_variant_value === value)
                });

                if (cindex > -1) {
                    clickedVaraintProductDetailsId = productVaraint[cindex].product_details_id;
                }
            }

            if (productVaraint.length == preProductVaraint.length && selectableProductDetailsId == 0) {

                for (let p = 0; p < preProductVaraint.length; p++) {

                    let index = productVaraint.findIndex((vrnt: any) => {

                        return (vrnt.product_variants_title === preProductVaraint[p].product_variants_title && vrnt.product_variant_value === preProductVaraint[p].product_variant_value)
                    })
                    if (index == -1) {
                        selectableProductDetailsId = 0;
                        break;
                    } else {
                        selectableProductDetailsId = productVaraint[index].product_details_id;
                    }

                }



            }


        }

        if (selectableProductDetailsId > 0 || clickedVaraintProductDetailsId > 0) {
            //debugger;

            let resultsproducts = product.filter((product: any) => {

                return (+product.product_details_id) === (+(selectableProductDetailsId > 0 ? selectableProductDetailsId : clickedVaraintProductDetailsId));
            })

            setSelectedProductDetails(resultsproducts[0]);
        }




    }



    useEffect(() => {
        if (typeof ((productDetails as any).product_id) != 'undefined') {
            getRelatedProducts();
            getReviewsByProduct();
        }
        // /loadSlideScripts();
    }, [productDetails]);

    const [lstRelatedProducts, setRelatedProducts] = useState([]);

    // useEffect(()=>
    // {
    //     // return()=>
    //     //     {
    //         if(lstRelatedProducts.length>0)
    //         {
    //             loadSlideScripts();

    //         }
    //         // }
    // },[lstRelatedProducts]);
    const loadSlideScripts = (): any => {

        // let lstRelatedProductss=lstRelatedProducts;

        const script1 = document.createElement('script');
        script1.src = 'https://code.jquery.com/jquery-3.4.1.min.js';
        script1.async = true;
        document.body.appendChild(script1);

        script1.onload = () => {
            var $ = jQuery.noConflict();
            $(document).ready(function () {

                try {

                    const script3 = document.createElement('script');
                    script3.src = '/js/slick/slick.js'; // Replace with the correct local path or CDN
                    script3.async = true;
                    document.body.appendChild(script3);

                    const script4 = document.createElement('script');
                    script4.src = '/js/slick/slick-animation.min.js'; // Replace with the correct local path or CDN
                    script4.async = true;
                    document.body.appendChild(script4);


                    const script5 = document.createElement('script');
                    script5.src = '/js/custom-slick-animated.js'; // Replace with the correct local path or CDN
                    script5.async = true;
                    document.body.appendChild(script5);



                    const script6 = document.createElement('script');
                    script6.src = '/js/slick/custom_slick.js'; // Replace with the correct local path or CDN
                    script6.async = true;
                    document.body.appendChild(script6);

                } catch (e) {

                }
            });
        }
    }

    const getRelatedProducts = async () => {

        await repository.getRelatedProducts((productDetails as any).product_id).then((result: any) => {

            if (result.length > 0) {

                // for(let i=0;i<result.length;i++)
                // {

                //     try{
                //     var product_feature_feilds=(((result[i].product_feature_feilds)));
                //     result[i].product_feature_feilds=commonFunctionality.stringFormatForJson(product_feature_feilds)
                //     }catch(e)
                //     {
                // ////debugger;
                //     }
                //     try{
                //     var product_resources=(((result[i].product_resources)));
                //     result[i].product_resources=commonFunctionality.stringFormatForJson(product_resources)
                // }catch(e)
                //     {
                //       //  //debugger; 
                //     }
                //     try{
                //     var ProductVariants=(((result[i].ProductVariants)));
                //     result[i].ProductVariants=commonFunctionality.stringFormatForJson(ProductVariants)


                // }catch(e)
                // {
                //     ////debugger; 
                // }


                // try{
                //     var productDiscounts=(((result[i].product_discounts)));
                //     result[i].product_discounts=commonFunctionality.stringFormatForJson(productDiscounts)


                // }catch(e)
                // {
                //     ////debugger; 
                // }


                // try{
                //     var product_variants=(((result[i].product_variants)));
                //     result[i].product_variants=commonFunctionality.stringFormatForJson(product_variants)


                // }catch(e)
                // {
                //     ////debugger; 
                // }




                // }
                setRelatedProducts(result)

            }

            ;
        });


    }

    const addToCart = (ctrid: any) => {
        debugger;
        // if((+item.qty)>0)
        {
            debugger;
            cartModel.addToCart(productDetails);
            var $ = jQuery.noConflict();
            debugger;


            if (($(window) as any).width() > 768) {
                var cart: any = $('.button-item');
            } else {
                var cart: any = $('.mobile-cart ul li a .icli.fly-cate');
            }
            var imgtodrag: any = $('#img-01');
            if (imgtodrag) {
                try {
                    var imgclone = imgtodrag.clone()
                        .offset({
                            top: imgtodrag.offset().top,
                            left: imgtodrag.offset().left
                        })
                        .css({
                            'opacity': '0.5',
                            'position': 'absolute',
                            'height': '130px',
                            'width': '130px',
                            'z-index': '100'
                        })
                        .appendTo($('body'))
                        .animate({
                            'top': cart.offset().top + 10,
                            'left': cart.offset().left + 10,
                            'width': 75,
                            'height': 75
                        }, 1000, 'swing');

                    imgclone.animate({
                        'width': 0,
                        'height': 0
                    }, function () {
                        // $('.btn-cart').detach()
                    });
                } catch (e) {
                    let res = e;


                }
            }
        }



    }
    const onCartClick = () => {
        navigate('/cart');
    }
    const onProductClick = ((products: any) => {
        debugger;

        try {
            var product_feature_feilds = (((products.product_feature_feilds)));
            products.product_feature_feilds = commonFunctionality.stringFormatForJson(product_feature_feilds)
        } catch (e) {
            debugger;
        }
        try {
            var product_resources = (((products.product_resources)));
            products.product_resources = commonFunctionality.stringFormatForJson(product_resources)
        } catch (e) {
             debugger; 
        }
        try {
            var product_variants = (((products.product_variants)));
            products.product_variants = commonFunctionality.stringFormatForJson(product_variants)

        } catch (e) {
            // debugger; 
        }


        try {
            debugger;
            var productDiscounts = (((products.product_discounts)));
            products.product_discounts = commonFunctionality.stringFormatForJson(productDiscounts)
        } catch (e) {
             // debugger; 
        }

        // let selectedProduct:any=lstProducts.filter((lproduct:any)=>
        // {
        //    return  (+lproduct.product_id)===(+products.product_id)
        // })
        // selectedProduct.map((product:any)=>{

        //     product.selected_product_details_id=products.product_details_id

        // });

        debugger;
        dispatch({
            type: ActionType.STORE,
            payload: {
                storeData: products,
                viewName: 'selectedProduct',
            },
        });
        navigate('/productdetails');
    });
    
    const ViewImage = ((resources:any)=>{
        debugger;

        let images = ((resources.replace(/\n/g, "")).replace(/'/g,"\""));
        debugger;
        let extractedImages = JSON.parse(images);
        debugger;
        return (extractedImages[0].resource_name+'.webp');
    })

    const [showPinCodeError, setShowPinCodeError] = useState('false');
    const [pinCodeChecked, setPinCodeChecked] = useState(false);

    const onPinCodeChange = async () => {

        //debugger;;
        setPinCodeChecked(true);
        //debugger;;
        let pinCode = $('#txtPincode').val();
        const responseData: any = await repository.checkPincode(pinCode);
        //debugger;;
        if (responseData.delivery_codes.length > 0) {
            setShowPinCodeError('false');
        }
        else {
            setShowPinCodeError('true');
        }







    }
    const [lstReviews, setReviews] = useState([]);
    const getReviewsByProduct = () => {

        repository.getReviewsByProduct((productDetails as any).product_id).then((result: any) => {
            //debugger;;
            if (result.length > 0) {
                setReviews(result);
            }

        })
    }

    const imagePath = repository.ecommerceImagePath;
    return { lstReviews, pinCodeChecked, onPinCodeChange, showPinCodeError, onProductClick,ViewImage, onCartClick, addToCart, loadScripts, loadSlideScripts, lstRelatedProducts, imagePath, onVaraintClick, getActiveClass, getVariantbyGroup, lstVariantGroup, displayVarients, productDetails, product }
}