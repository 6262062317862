import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppState } from "../../store/Page.Actions";
import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";

export  function OrderResultModel(Result:any)
{

    
    const repository =SchoolRepository()

    const paymentOrder = useSelector((state: AppState) =>
    {
      
      
    
      return  typeof(state.storeData.PaymentOrder)!='undefined'?state.storeData.PaymentOrder:null;
    }
    );
    const [paymentResult,setPaymentResult]=useState({status:'',order_id:'',amount:''});
    const[orderAmount,setOrderAmount]=useState(0);
    const[orderId,setorderId]=useState(0);
    useEffect(()=>
    { 
      
      //debugger;;
      let storeOrder:any=  repository.prepareDecryptData(paymentOrder)



           
      let orderData=JSON.parse(JSON.parse(storeOrder));


       
        if(orderData!=null)
        {
           //debugger;;

//let patResult:any=JSON.parse(Result)

         let resultData:any=  repository.prepareDecryptData(atob(Result))



           
           let res:any=JSON.parse(resultData);
if(res.order_id!=orderData.order_id || (+res.amount)!= (+orderData.amount))
{

  res.status='Parameter Manipulation';
}

           setPaymentResult(res)

           updateOrderPaymentStatus(JSON.stringify(res) )

           if(res.status=="CHARGED")
           {
            setOrderAmount(res.amount);
            setorderId(res.order_id);
           }
           
            console.log(JSON.stringify(res));
        }
    },[alert]);
// const orderStatus=()=>
// {

//     repository.orderPaymentStatus(99).then((result:any)=>
//     {
//         //debugger;;
//         if(result.length>0)
//         {
//         }
//     });
// }
   

  const  updateOrderPaymentStatus=
  (paymentResult:any)=>
  {
    //updateOrderPaymentStatus
    repository.updateOrderPaymentStatus(paymentResult).then((result:any)=>
    {
        //debugger;;
        if(result.length>0)
        {
            //debugger;;
        }
    });



  }

    return {orderId,orderAmount,paymentResult}
}