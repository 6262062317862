const CompanyServices:React.FC=()=>
{


    return (<>
    
      {/* <!-- Service Section Start --> */}
    <section className="service-section section-b-space white">
        <div className="container-fluid">
            <div className="row g-3 row-cols-xxl-5 row-cols-lg-3 row-cols-md-2">
                <div>
                    <div className="service-contain-2">
                        <img src="/svg/delivery.svg"/>
                        <div className="service-detail">
                            <h3>Free Shipping</h3>
                            <h6 className="text-content">Free Shipping world wide</h6>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="service-contain-2">
                        <img src="/images/svg/time-left.png" className="icon-width"/>
                        <div className="service-detail">
                            <h3>24 x 7 Service</h3>
                            <h6 className="text-content">Online Service For 24 x 7</h6>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="service-contain-2">
                        <img src="/images/svg/credit-card.png" className="icon-width"/>
                        <div className="service-detail">
                            <h3>Online Pay</h3>
                            <h6 className="text-content">Online Payment Avaible</h6>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="service-contain-2">
                        <img src="/images/svg/gift.png" className="icon-width"/>
                        <div className="service-detail">
                            <h3>Festival Offer</h3>
                            <h6 className="text-content">Super Sale Upto 50% off</h6>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="service-contain-2">
                        <img src="/images/svg/product-return.png" className="icon-width"/>
                        <div className="service-detail">
                            <h3>100% Original</h3>
                            <h6 className="text-content">100% Money Back</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Service Section End --> */}
    </>)

}
export default  CompanyServices;