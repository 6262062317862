import { useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { ProductSearchAndFilterModel } from "./ProductSearchAndFilterModel";
import { useMemo } from "react";

const ProductSearchAndFilter: React.FC = () => {

    const { filterData } = useParams<{ filterData: string }>();

    // const viewModel = useMemo(() => {
    //     
    //     return ProductSearchAndFilterModel(filterData);
    // }, [filterData]);
    const viewModel = ProductSearchAndFilterModel(filterData);


    return (<>

        <Header></Header>
        <section className="breadscrumb-section pt-0">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="breadscrumb-contain">
                            <h2>Our Products</h2>
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <a href="index.html">
                                            <i className="fa-solid fa-house"></i>
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Home</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-b-space shop-section white">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-custome-3">
                        <div className="left-box wow fadeInUp">
                            <div className="shop-left-sidebar">
                                <div className="back-button">
                                    <h3><i className="fa-solid fa-arrow-left"></i> Back</h3>
                                </div>

                                <div className="accordion custome-accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                <span>Categories</span>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show"
                                            aria-labelledby="headingOne">
                                            <div className="accordion-body">
                                                {/* <div  className="form-floating theme-form-floating-2 search-box">
                                                <input type="search"  className="form-control" id="search"
                                                    placeholder="Search .."/>
                                                <label  >Search</label>
                                            </div> */}

                                                <ul className="category-list custom-padding custom-height">
                                                    {/* {viewModel.productCategories.length} */}

                                                    {

                                                        viewModel.lstCategories.map((item: any) => {


                                                            return (

                                                                <li>
                                                                    <div className="form-check ps-0 m-0 category-list-box">
                                                                        <input className="checkbox_animated" type="checkbox" id={item.category_id} onChange={(e) => viewModel.onCategoryChaeckedChange(e, item.category_id)} />
                                                                        <label className="form-check-label">
                                                                            <span className="name">{item.category_name}</span>
                                                                            <span className="number">({viewModel.getProductByCount('Category', item.category_id)})</span>
                                                                        </label>
                                                                    </div>
                                                                </li>



                                                            )
                                                        })

                                                    }


                                                </ul>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                <span>Brand</span>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show"
                                            aria-labelledby="headingOne">
                                            <div className="accordion-body">

                                                <ul className="category-list custom-padding custom-height">


                                                    {

                                                        viewModel.lstBrands.map((item: any) => {


                                                            return (

                                                                <li>
                                                                    <div className="form-check ps-0 m-0 category-list-box">
                                                                        <input className="checkbox_animated" type="checkbox" id={item.brand_id} onChange={(e) => viewModel.onBrandChaeckedChange(e, item.brand_id)} />
                                                                        <label className="form-check-label"  >
                                                                            <span className="name">{item.brand_name}</span>
                                                                            <span className="number">({viewModel.getProductByCount('Brand', item.brand_name)})</span>
                                                                            {/* <span  className="number">(15)</span> */}
                                                                        </label>
                                                                    </div>
                                                                </li>



                                                            )
                                                        })

                                                    }


                                                </ul>
                                            </div>
                                        </div>
                                    </div>







                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                <span>Class</span>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show"
                                            aria-labelledby="headingOne">
                                            <div className="accordion-body">

                                                <ul className="category-list custom-padding custom-height">


                                                    {

                                                        viewModel.lstSchoolClass.map((item: any) => {


                                                            return (

                                                                <li>
                                                                    <div className="form-check ps-0 m-0 category-list-box">
                                                                        <input className="checkbox_animated" type="checkbox"

                                                                            checked={viewModel.checkedClasses?.some((chk: any) => chk.class === item.classes)}

                                                                            id={item.classes} onChange={(e) => viewModel.onClassChaeckedChange(e, item.classes)} />
                                                                        <label className="form-check-label"  >
                                                                            <span className="name">{item.classes}</span>
                                                                            <span className="number">({viewModel.getProductByCountForClass(item.classes)})</span>
                                                                            {/* <span  className="number">(15)</span> */}
                                                                        </label>
                                                                    </div>
                                                                </li>



                                                            )
                                                        })

                                                    }


                                                </ul>
                                            </div>
                                        </div>
                                    </div>




                                    {

                                        viewModel.lstVariantGroup.map((variant: any) => {

                                            return (<div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseOne" aria-expanded="true"
                                                        aria-controls="collapseOne">
                                                        <span>{variant}</span>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse show"
                                                    aria-labelledby="headingOne">
                                                    <div className="accordion-body">
                                                        {/* <div  className="form-floating theme-form-floating-2 search-box">
            <input type="search"  className="form-control" id="search"
                placeholder="Search .."/>
            <label  >Search</label>
        </div> */}

                                                        <ul className="category-list custom-padding custom-height">
                                                            {/* {viewModel.productCategories.length} */}

                                                            {

                                                                viewModel.getProductVarintsByGroup(variant).map((item: any) => {


                                                                    return (

                                                                        <li>
                                                                            <div className="form-check ps-0 m-0 category-list-box">
                                                                                <input className="checkbox_animated" type="checkbox" id={item.product_variant_value} onChange={(e) => viewModel.onVariantChaeckedChange(e, { 'product_variants_title': variant, 'product_variant_value': item.product_variant_value })} />
                                                                                <label className="form-check-label"  >
                                                                                    <span className="name">{item.product_variant_value}</span>

                                                                                </label>
                                                                            </div>
                                                                        </li>



                                                                    )
                                                                })

                                                            }


                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            )

                                        })
                                    }




                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree">
                                                <span>Price</span>
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse show"
                                            aria-labelledby="headingThree">
                                            <div className="accordion-body">
                                                <div className="range-slider" >
                                                    <input type="range" className="js-range-slider" value={viewModel.priceValue} onChange={() => viewModel.onPriceChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                                aria-expanded="false" aria-controls="collapseFour">
                                                <span>Discount</span>
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse show"
                                            aria-labelledby="headingFour">
                                            <div className="accordion-body">
                                                <ul className="category-list custom-padding">
                                                    <li>
                                                        <div className="form-check ps-0 m-0 category-list-box">
                                                            <input className="checkbox_animated" type="checkbox" onChange={(e) => viewModel.onDiscountCheckedChange(e, 5)}
                                                                id="flexCheckDefault" />
                                                            <label className="form-check-label" >
                                                                <span className="name">5% or more</span>
                                                                <span className="number">  <span className="number">({viewModel.getProductByCount('Discount', 5)})</span></span>
                                                            </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div className="form-check ps-0 m-0 category-list-box">
                                                            <input className="checkbox_animated" type="checkbox" onChange={(e) => viewModel.onDiscountCheckedChange(e, 10)}
                                                                id="flexCheckDefault1" />
                                                            <label className="form-check-label">
                                                                <span className="name">10% or more</span>
                                                                <span className="number">({viewModel.getProductByCount('Discount', 10)})</span>
                                                            </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div className="form-check ps-0 m-0 category-list-box">
                                                            <input className="checkbox_animated" type="checkbox" onChange={(e) => viewModel.onDiscountCheckedChange(e, 20)}
                                                                id="flexCheckDefault2" />
                                                            <label className="form-check-label">
                                                                <span className="name">20% or more</span>
                                                                <span className="number">({viewModel.getProductByCount('Discount', 20)})</span>
                                                            </label>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div className="form-check ps-0 m-0 category-list-box">
                                                            <input className="checkbox_animated" type="checkbox" onChange={(e) => viewModel.onDiscountCheckedChange(e, 30)}
                                                                id="flexCheckDefault3" />
                                                            <label className="form-check-label">
                                                                <span className="name">30% or more</span>
                                                                <span className="number">({viewModel.getProductByCount('Discount', 30)})</span>
                                                            </label>
                                                        </div>
                                                    </li>


                                                    <li>
                                                        <div className="form-check ps-0 m-0 category-list-box">
                                                            <input className="checkbox_animated" type="checkbox" onChange={(e) => viewModel.onDiscountCheckedChange(e, 40)}
                                                                id="flexCheckDefault3" />
                                                            <label className="form-check-label">
                                                                <span className="name">40% or more</span>
                                                                <span className="number">({viewModel.getProductByCount('Discount', 40)})</span>
                                                            </label>
                                                        </div>
                                                    </li>



                                                    <li>
                                                        <div className="form-check ps-0 m-0 category-list-box">
                                                            <input className="checkbox_animated" type="checkbox" onChange={(e) => viewModel.onDiscountCheckedChange(e, 50)}
                                                                id="flexCheckDefault3" />
                                                            <label className="form-check-label">
                                                                <span className="name">50% or more</span>
                                                                <span className="number">({viewModel.getProductByCount('Discount', 50)})</span>
                                                            </label>
                                                        </div>
                                                    </li>


                                                    <li>
                                                        <div className="form-check ps-0 m-0 category-list-box">
                                                            <input className="checkbox_animated" type="checkbox" onChange={(e) => viewModel.onDiscountCheckedChange(e, 60)}
                                                                id="flexCheckDefault3" />
                                                            <label className="form-check-label">
                                                                <span className="name">60% or more</span>
                                                                <span className="number">({viewModel.getProductByCount('Discount', 60)})</span>
                                                            </label>
                                                        </div>
                                                    </li>


                                                    <li>
                                                        <div className="form-check ps-0 m-0 category-list-box">
                                                            <input className="checkbox_animated" type="checkbox" onChange={(e) => viewModel.onDiscountCheckedChange(e, 70)}
                                                                id="flexCheckDefault3" />
                                                            <label className="form-check-label">
                                                                <span className="name">70% or more</span>
                                                                <span className="number">({viewModel.getProductByCount('Discount', 70)})</span>
                                                            </label>
                                                        </div>
                                                    </li>



                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                </div>




                            </div>
                        </div>
                    </div>

                    <div className="col-custome-9">
                        <div className="show-button">
                            <div className="filter-button-group mt-0">
                                <div className="filter-button d-inline-block d-lg-none">
                                    <a><i className="fa-solid fa-filter"></i> Filter Menu</a>
                                </div>
                            </div>

                            <div className="top-filter-menu">
                                <div className="grid-option d-none d-md-block">

                                </div>
                                <div className="category-dropdown">
                                    <h5 className="text-content">Sort By :</h5>
                                    <div className="dropdown">
                                        <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span>Popularity</span> <i className="fa-solid fa-angle-down"></i>
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"  >
                                            <li>
                                                <a className="dropdown-item" id="pop" onClick={() => viewModel.onSortChange('popularity')}>Popularity</a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" id="low" onClick={() => viewModel.onSortChange('lowtohigh')}>Low - High
                                                    Price</a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" id="high" onClick={() => viewModel.onSortChange('hightolow')}  >High - Low
                                                    Price</a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" id="newest" onClick={() => viewModel.onSortChange('newest')}>Newest First
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="row g-8">
                            {/* { viewModel.fromPrice+':'+viewModel.toPrice} */}
                            {/* {viewModel.lstProducts.length} */}
                            {
                                viewModel.lstProducts.map((item: any, i) => {
                                    return (
                                        ((+item.selling_price) >= (+viewModel.fromPrice) && (+item.selling_price) <= (+viewModel.toPrice)) ? (
                                            <div className="col-xxl-3 col-lg-3 col-md-4 col-6 wow fadeInUp" key={i}>
                                                <div className="product-box-4" >
                                                    <div className="product-image mb-4">
                                                        <div className="label-flex" onClick={() => viewModel.onWishlistClick(item)}>
                                                            <button className="btn p-0 wishlist btn-wishlist notifi-wishlist"  >
                                                                <i className="iconly-Heart icli" style={viewModel.isWishlistItem(item.product_details_id) > -1 ? { color: "red" } : {}}></i>
                                                            </button>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="product-detail" onClick={() => viewModel.onProductClick(item)} >

                                                        <div className="mb-4" >
                                                            <img src={item.product_resources.length > 0 ? ((viewModel.imagePath + item.image) + '.webp') : ''} className="img-fluid" alt="" />
                                                        </div>

                                                        <span className="span-name">{item.brand_name}</span>
                                                        <a>
                                                            <h5 className="name">{item.title}</h5>
                                                        </a>
                                                        <h5 className="price theme-color">Rs {item.selling_price}<del style={((+item.mrp) - (+item.selling_price)) > 0 ? { display: 'block' } : { display: 'none' }}>Rs {item.mrp}</del></h5>
                                                        <div className="price-qty"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                    );
                                })
                            }
                        </div>
                        {/* 
                    <nav  className="custome-pagination">
                        <ul  className="pagination justify-content-center">
                            <li  className="page-item disabled">
                                <a  className="page-link" href="javascript:void(0)" tabIndex={-1} aria-disabled="true">
                                    <i  className="fa-solid fa-angles-left"></i>
                                </a>
                            </li>
                            <li  className="page-item active">
                                <a  className="page-link" href="javascript:void(0)">1</a>
                            </li>
                            <li  className="page-item" aria-current="page">
                                <a  className="page-link" href="javascript:void(0)">2</a>
                            </li>
                            <li  className="page-item">
                                <a  className="page-link" href="javascript:void(0)">3</a>
                            </li>
                            <li  className="page-item">
                                <a  className="page-link" href="javascript:void(0)">
                                    <i  className="fa-solid fa-angles-right"></i>
                                </a>
                            </li>
                        </ul>
                    </nav> */}
                    </div>
                </div>
            </div>
        </section>


        {/* <!-- Quick View Modal Box Start --> */}
        {/* <div className="modal fade theme-modal view-modal" id="view" tabIndex={-1} aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-sm-down">
            <div className="modal-content">
                <div className="modal-header p-0">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row g-sm-4 g-2">
                        <div className="col-lg-6">
                            <div className="slider-image">
                                <img src="assets/images/product/category/1.jpg" className="img-fluid blur-up lazyload" alt="" style={{height:'50vh'}}/>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="right-sidebar-modal">
                                <h4 className="title-name">Textbook</h4>
                                <h4 className="price">$36.99</h4>
                                <div className="product-rating">
                                    <ul className="rating">
                                        <li>
                                            <i data-feather="star" className="fill"></i>
                                        </li>
                                        <li>
                                            <i data-feather="star" className="fill"></i>
                                        </li>
                                        <li>
                                            <i data-feather="star" className="fill"></i>
                                        </li>
                                        <li>
                                            <i data-feather="star" className="fill"></i>
                                        </li>
                                        <li>
                                            <i data-feather="star"></i>
                                        </li>
                                    </ul>
                                    <span className="ms-2">8 Reviews</span>
                                    <span className="ms-2 text-danger">6 sold in last 16 hours</span>
                                </div>

                                <div className="product-detail">
                                    <h4>Product Details :</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s</p>
                                </div>

                                <ul className="brand-list">
                                    <li>
                                        <div className="brand-box">
                                            <h5>Brand Name:</h5>
                                            <h6>Black Forest</h6>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="brand-box">
                                            <h5>Product Code:</h5>
                                            <h6>W0690034</h6>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="brand-box">
                                            <h5>Product Type:</h5>
                                            <h6>White</h6>
                                        </div>
                                    </li>
                                </ul>

                                <div className="select-size">
                                    <h4>Quantity :</h4>
                                    <select className="form-select select-form-size">
                                        <option selected>Quantity</option>
                                        <option value="1.2">1</option>
                                        <option value="0">2</option>
                                        <option value="1.5">3</option>
                                        <option value="red">4</option>
                                        <option value="pink">5</option>
                                    </select>
                                </div>

                                <div className="modal-button">
                                    <button onClick={()=>window.location.href = 'cart.html'} 
                                        className="btn theme-bg-color view-button icon text-white fw-bold btn-md">Add
                                        To Cart</button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
        {/* <!-- Quick View Modal Box End --> */}
        <Footer></Footer>

    </>)

}
export default ProductSearchAndFilter;