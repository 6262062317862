import { CartModel } from "../../components/Cart/CartModel";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { CheckoutModel } from "./CheckoutModel";

const Checkout:React.FC=()=>
{
    const cartModel=CartModel();
const viewModel=CheckoutModel();
    return (<>
      <Header></Header>
    <section className="checkout-section-2 section-b-space white">
        <div className="container-fluid">
            <div className="row g-sm-4 g-3">
                <div className="col-lg-8">
                    <div className="left-sidebar-checkout">
                        <div className="checkout-detail-box">
                            <ul>
                                <li>
                                    {/* <div className="checkout-icon">
                                        <lord-icon target=".nav-item" src="https://cdn.lordicon.com/ggihhudh.json"
                                            trigger="loop-on-hover"
                                            colors="primary:#121331,secondary:#646e78,tertiary:#0baf9a"
                                            className="lord-icon">
                                        </lord-icon>
                                    </div> */}
                                    <div className="checkout-box">
                                     
                                        <div className="checkout-title">
                                            <h4>Delivery Address</h4>
                                            <h4 className="add-address" data-bs-toggle="modal" data-bs-target="#add-address"><i className="fas fa-plus"></i> Add Address</h4>
                                        </div>

                                        <div className="checkout-detail">
                                            <div className="row g-4">

{
    viewModel.lstCustomerAddress.map((address:any)=>
    {
        return (  <div className="col-xxl-6 col-lg-12 col-md-6">
        <div className="delivery-address-box">
            <div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="jack" onChange={(e)=>viewModel.onSelectedAddressChange(e,address)}   checked={(viewModel.selectedAddres as any).customer_address_id==address.customer_address_id?true:false}
                        id="flexRadioDefault1"/>
                </div>

                <div className="label">
                    <label>{address.addres_type}</label>
                </div>

                <ul className="delivery-address-detail">
                    <li>
                        <h4 className="fw-500">{address.full_name}</h4>
                    </li>

                    <li>
                        <p className="text-content"><span
                                className="text-title">Address
                                : </span>{address.house_no_bulding_name},{address.road_area_colony},{address.city},{address.state_name}</p>
                    </li>

                    <li>
                        <h6 className="text-content"><span
                                className="text-title">Pin Code
                                :</span> +{address.pincode}</h6>
                    </li>

                    <li>
                        <h6 className="text-content mb-0"><span
                                className="text-title">Phone
                                :</span> + {address.phone_number}{address.alternate_phone_number!=''?  ','+address.alternate_phone_number:''}</h6>
                    </li>
                </ul>
            </div>
        </div>
    </div>)
    })
}

                                              

                                               
                                            </div>
                                        </div>
                                    </div>
                                </li>
{/* 
                                <li>
                                    <div className="checkout-icon">
                                        <lord-icon target=".nav-item" src="https://cdn.lordicon.com/oaflahpk.json"
                                            trigger="loop-on-hover" colors="primary:#0baf9a" className="lord-icon">
                                        </lord-icon>
                                    </div>
                                    <div className="checkout-box">
                                        <div className="checkout-title">
                                            <h4>Delivery Option</h4>
                                        </div>

                                        <div className="checkout-detail">
                                            <div className="row g-4">
                                                <div className="col-xxl-6">
                                                    <div className="delivery-option">
                                                        <div className="delivery-category">
                                                            <div className="shipment-detail">
                                                                <div
                                                                    className="form-check custom-form-check hide-check-box">
                                                                    <input className="form-check-input" type="radio"
                                                                        name="standard" id="standard" checked/>
                                                                    <label className="form-check-label">Standard
                                                                        Delivery Option</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xxl-6">
                                                    <div className="delivery-option">
                                                        <div className="delivery-category">
                                                            <div className="shipment-detail">
                                                                <div
                                                                    className="form-check mb-0 custom-form-check show-box-checked">
                                                                    <input className="form-check-input" type="radio"
                                                                        name="standard" id="future"/>
                                                                    <label className="form-check-label">Future Delivery Option</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 future-box">
                                                    <div className="future-option">
                                                        <div className="row g-md-0 gy-4">
                                                            <div className="col-md-6">
                                                                <div className="delivery-items">
                                                                    <div>
                                                                        <h5 className="items text-content"><span>3
                                                                                Items</span>@
                                                                            Rs693.48</h5>
                                                                        <h5 className="charge text-content">Delivery Charge
                                                                            Rs34.67
                                                                            <button type="button" className="btn p-0"
                                                                                data-bs-toggle="tooltip"
                                                                                data-bs-placement="top"
                                                                                title="Extra Charge">
                                                                                <i
                                                                                    className="fa-solid fa-circle-exclamation"></i>
                                                                            </button>
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <form
                                                                    className="form-floating theme-form-floating date-box">
                                                                    <input type="date" className="form-control"/>
                                                                    <label>Select Date</label>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}

                                <li>
                                    {/* <div className="checkout-icon">
                                        <lord-icon target=".nav-item" src="https://cdn.lordicon.com/qmcsqnle.json"
                                            trigger="loop-on-hover" colors="primary:#0baf9a,secondary:#0baf9a"
                                            className="lord-icon">
                                        </lord-icon>
                                    </div> */}
                                    <div className="checkout-box" style={{display:"none"}}>
                                        <div className="checkout-title">
                                            <h4>Payment Option</h4>
                                        </div>

                                        <div className="checkout-detail">
                                            <div className="accordion accordion-flush custom-accordion"
                                                id="accordionFlushExample">
                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="flush-headingFour">
                                                        <div className="accordion-button collapsed"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseFour">
                                                            <div className="custom-form-check form-check mb-0">
                                                                <label className="form-check-label"  ><input
                                                                        className="form-check-input mt-0" type="radio"
                                                                        name="flexRadioDefault" id="cash" checked/> Cash
                                                                    On Delivery</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="flush-collapseFour"
                                                        className="accordion-collapse collapse show"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <p className="cod-review">Pay digitally with SMS Pay
                                                                Link. Cash may not be accepted in COVID restricted
                                                                areas. <a href="javascript:void(0)">Know more.</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="flush-headingOne">
                                                        <div className="accordion-button collapsed"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseOne">
                                                            <div className="custom-form-check form-check mb-0">
                                                                <label className="form-check-label"><input
                                                                        className="form-check-input mt-0" type="radio"
                                                                        name="flexRadioDefault" id="credit"/>
                                                                    Credit or Debit Card</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="flush-collapseOne" className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <div className="row g-2">
                                                                <div className="col-12">
                                                                    <div className="payment-method">
                                                                        <div
                                                                            className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                            <input type="text" className="form-control"
                                                                                id="credit2"
                                                                                placeholder="Enter Credit & Debit Card Number"/>
                                                                            <label>Enter Credit & Debit
                                                                                Card Number</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xxl-4">
                                                                    <div
                                                                        className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                        <input type="text" className="form-control"
                                                                            id="expiry" placeholder="Enter Expiry Date"/>
                                                                        <label>Expiry Date</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xxl-4">
                                                                    <div
                                                                        className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                        <input type="text" className="form-control" id="cvv"
                                                                            placeholder="Enter CVV Number"/>
                                                                        <label>CVV Number</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xxl-4">
                                                                    <div
                                                                        className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                        <input type="password" className="form-control"
                                                                            id="password" placeholder="Enter Password"/>
                                                                        <label>Password</label>
                                                                    </div>
                                                                </div>

                                                                <div className="button-group mt-0">
                                                                    <ul>
                                                                        <li>
                                                                            <button
                                                                                className="btn btn-light shopping-button">Cancel</button>
                                                                        </li>

                                                                        <li>
                                                                            <button className="btn btn-animation">Use This
                                                                                Card</button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="flush-headingTwo">
                                                        <div className="accordion-button collapsed"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseTwo">
                                                            <div className="custom-form-check form-check mb-0">
                                                                <label className="form-check-label"><input
                                                                        className="form-check-input mt-0" type="radio"
                                                                        name="flexRadioDefault" id="banking"/>Net
                                                                    Banking</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="flush-collapseTwo" className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <h5 className="text-uppercase mb-4">Select Your Bank
                                                            </h5>
                                                            <div className="row g-2">
                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="bank1"/>
                                                                        <label className="form-check-label"
                                                                            >Industrial & Commercial
                                                                            Bank</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="bank2"/>
                                                                        <label className="form-check-label"
                                                                          >Agricultural Bank</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="bank3"/>
                                                                        <label className="form-check-label">Bank
                                                                            of America</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="bank4"/>
                                                                        <label className="form-check-label"
                                                                           >Construction Bank Corp.</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="bank5"/>
                                                                        <label className="form-check-label">HSBC
                                                                            Holdings</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="bank6"/>
                                                                        <label className="form-check-label" >JPMorgan Chase & Co.</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="select-option">
                                                                        <div className="form-floating theme-form-floating">
                                                                            <select
                                                                                className="form-select theme-form-select"
                                                                                aria-label="Default select example">
                                                                                <option value="hsbc">HSBC Holdings
                                                                                </option>
                                                                                <option value="loyds">Lloyds Banking
                                                                                    Group</option>
                                                                                <option value="natwest">Nat West Group
                                                                                </option>
                                                                                <option value="Barclays">Barclays
                                                                                </option>
                                                                                <option value="other">Others Bank
                                                                                </option>
                                                                            </select>
                                                                            <label>Select Other Bank</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="flush-headingThree">
                                                        <div className="accordion-button collapsed"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseThree">
                                                            <div className="custom-form-check form-check mb-0">
                                                                <label className="form-check-label"><input
                                                                        className="form-check-input mt-0" type="radio"
                                                                        name="flexRadioDefault" id="wallet"/>My
                                                                    Wallet</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="flush-collapseThree" className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <h5 className="text-uppercase mb-4">Select Your Wallet
                                                            </h5>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <label className="form-check-label"><input
                                                                                className="form-check-input mt-0"
                                                                                type="radio" name="flexRadioDefault"
                                                                                id="amazon"/>Amazon Pay</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="gpay"/>
                                                                        <label className="form-check-label"
                                                                             >Google Pay</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="airtel"/>
                                                                        <label className="form-check-label"
                                                                            >Airtel Money</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="paytm"/>
                                                                        <label className="form-check-label"
                                                                             >Paytm Pay</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="jio"/>
                                                                        <label className="form-check-label">JIO Money</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="free"/>
                                                                        <label className="form-check-label"
                                                                             >Freecharge</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="left-sidebar-checkout">
                        <div className="checkout-detail-box">
                            <ul>
                                <li>
                                    
                                    <div className="checkout-box">
                                     
                                        <div className="checkout-title">
                                            <h4>Belong to</h4>
                                            <h4 className="add-address" data-bs-toggle="modal" data-bs-target="#add-children"><i className="fas fa-plus"></i> Add Childrens</h4>
                                        </div>

                                        <div className="checkout-detail">
                                            <div className="row g-4">

{
    viewModel.lstCustomerChildrens.map((children:any)=>
    {
        return (  <div className="col-xxl-6 col-lg-12 col-md-6">
        <div className="delivery-address-box">
            <div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="children" 
                    onChange={(e)=>viewModel.onSelectedChildChange(e,children)}  
                     checked={viewModel.checkSelectedChildrens(children.children_id)}
                        id="flexRadioDefault1"/>
                </div>

                <div className="label">
                    <label>{children.gender}</label>
                </div>

                <ul className="delivery-address-detail">
                    <li>
                        <h4 className="fw-500">{children.children_name}</h4>
                    </li>

                   
                </ul>
            </div>
        </div>
    </div>)
    })
}

                                              

                                               
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    {/* <div className="checkout-icon">
                                        <lord-icon target=".nav-item" src="https://cdn.lordicon.com/oaflahpk.json"
                                            trigger="loop-on-hover" colors="primary:#0baf9a" className="lord-icon">
                                        </lord-icon>
                                    </div> */}
                                    {/* <div className="checkout-box">
                                        <div className="checkout-title">
                                            <h4>Delivery Option</h4>
                                        </div>

                                        <div className="checkout-detail">
                                            <div className="row g-4">
                                                <div className="col-xxl-6">
                                                    <div className="delivery-option">
                                                        <div className="delivery-category">
                                                            <div className="shipment-detail">
                                                                <div
                                                                    className="form-check custom-form-check hide-check-box">
                                                                    <input className="form-check-input" type="radio"
                                                                        name="standard" id="standard" checked/>
                                                                    <label className="form-check-label">Standard
                                                                        Delivery Option</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xxl-6">
                                                    <div className="delivery-option">
                                                        <div className="delivery-category">
                                                            <div className="shipment-detail">
                                                                <div
                                                                    className="form-check mb-0 custom-form-check show-box-checked">
                                                                    <input className="form-check-input" type="radio"
                                                                        name="standard" id="future"/>
                                                                    <label className="form-check-label">Future Delivery Option</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 future-box">
                                                    <div className="future-option">
                                                        <div className="row g-md-0 gy-4">
                                                            <div className="col-md-6">
                                                                <div className="delivery-items">
                                                                    <div>
                                                                        <h5 className="items text-content"><span>3
                                                                                Items</span>@
                                                                            Rs693.48</h5>
                                                                        <h5 className="charge text-content">Delivery Charge
                                                                            Rs34.67
                                                                            <button type="button" className="btn p-0"
                                                                                data-bs-toggle="tooltip"
                                                                                data-bs-placement="top"
                                                                                title="Extra Charge">
                                                                                <i
                                                                                    className="fa-solid fa-circle-exclamation"></i>
                                                                            </button>
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <form
                                                                    className="form-floating theme-form-floating date-box">
                                                                    <input type="date" className="form-control"/>
                                                                    <label>Select Date</label>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </li>

                                <li>
                                    {/* <div className="checkout-icon">
                                        <lord-icon target=".nav-item" src="https://cdn.lordicon.com/qmcsqnle.json"
                                            trigger="loop-on-hover" colors="primary:#0baf9a,secondary:#0baf9a"
                                            className="lord-icon">
                                        </lord-icon>
                                    </div> */}
                                    <div className="checkout-box" style={{display:"none"}}>
                                        <div className="checkout-title">
                                            <h4>Payment Option</h4>
                                        </div>

                                        <div className="checkout-detail">
                                            <div className="accordion accordion-flush custom-accordion"
                                                id="accordionFlushExample">
                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="flush-headingFour">
                                                        <div className="accordion-button collapsed"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseFour">
                                                            <div className="custom-form-check form-check mb-0">
                                                                <label className="form-check-label"  ><input
                                                                        className="form-check-input mt-0" type="radio"
                                                                        name="flexRadioDefault" id="cash" checked/> Cash
                                                                    On Delivery</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="flush-collapseFour"
                                                        className="accordion-collapse collapse show"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <p className="cod-review">Pay digitally with SMS Pay
                                                                Link. Cash may not be accepted in COVID restricted
                                                                areas. <a href="javascript:void(0)">Know more.</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="flush-headingOne">
                                                        <div className="accordion-button collapsed"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseOne">
                                                            <div className="custom-form-check form-check mb-0">
                                                                <label className="form-check-label"><input
                                                                        className="form-check-input mt-0" type="radio"
                                                                        name="flexRadioDefault" id="credit"/>
                                                                    Credit or Debit Card</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="flush-collapseOne" className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <div className="row g-2">
                                                                <div className="col-12">
                                                                    <div className="payment-method">
                                                                        <div
                                                                            className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                            <input type="text" className="form-control"
                                                                                id="credit2"
                                                                                placeholder="Enter Credit & Debit Card Number"/>
                                                                            <label>Enter Credit & Debit
                                                                                Card Number</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xxl-4">
                                                                    <div
                                                                        className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                        <input type="text" className="form-control"
                                                                            id="expiry" placeholder="Enter Expiry Date"/>
                                                                        <label>Expiry Date</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xxl-4">
                                                                    <div
                                                                        className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                        <input type="text" className="form-control" id="cvv"
                                                                            placeholder="Enter CVV Number"/>
                                                                        <label>CVV Number</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xxl-4">
                                                                    <div
                                                                        className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                        <input type="password" className="form-control"
                                                                            id="password" placeholder="Enter Password"/>
                                                                        <label>Password</label>
                                                                    </div>
                                                                </div>

                                                                <div className="button-group mt-0">
                                                                    <ul>
                                                                        <li>
                                                                            <button
                                                                                className="btn btn-light shopping-button">Cancel</button>
                                                                        </li>

                                                                        <li>
                                                                            <button className="btn btn-animation">Use This
                                                                                Card</button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="flush-headingTwo">
                                                        <div className="accordion-button collapsed"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseTwo">
                                                            <div className="custom-form-check form-check mb-0">
                                                                <label className="form-check-label"><input
                                                                        className="form-check-input mt-0" type="radio"
                                                                        name="flexRadioDefault" id="banking"/>Net
                                                                    Banking</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="flush-collapseTwo" className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <h5 className="text-uppercase mb-4">Select Your Bank
                                                            </h5>
                                                            <div className="row g-2">
                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="bank1"/>
                                                                        <label className="form-check-label"
                                                                            >Industrial & Commercial
                                                                            Bank</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="bank2"/>
                                                                        <label className="form-check-label"
                                                                          >Agricultural Bank</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="bank3"/>
                                                                        <label className="form-check-label">Bank
                                                                            of America</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="bank4"/>
                                                                        <label className="form-check-label"
                                                                           >Construction Bank Corp.</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="bank5"/>
                                                                        <label className="form-check-label">HSBC
                                                                            Holdings</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="bank6"/>
                                                                        <label className="form-check-label" >JPMorgan Chase & Co.</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="select-option">
                                                                        <div className="form-floating theme-form-floating">
                                                                            <select
                                                                                className="form-select theme-form-select"
                                                                                aria-label="Default select example">
                                                                                <option value="hsbc">HSBC Holdings
                                                                                </option>
                                                                                <option value="loyds">Lloyds Banking
                                                                                    Group</option>
                                                                                <option value="natwest">Nat West Group
                                                                                </option>
                                                                                <option value="Barclays">Barclays
                                                                                </option>
                                                                                <option value="other">Others Bank
                                                                                </option>
                                                                            </select>
                                                                            <label>Select Other Bank</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="flush-headingThree">
                                                        <div className="accordion-button collapsed"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseThree">
                                                            <div className="custom-form-check form-check mb-0">
                                                                <label className="form-check-label"><input
                                                                        className="form-check-input mt-0" type="radio"
                                                                        name="flexRadioDefault" id="wallet"/>My
                                                                    Wallet</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="flush-collapseThree" className="accordion-collapse collapse"
                                                        data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <h5 className="text-uppercase mb-4">Select Your Wallet
                                                            </h5>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <label className="form-check-label"><input
                                                                                className="form-check-input mt-0"
                                                                                type="radio" name="flexRadioDefault"
                                                                                id="amazon"/>Amazon Pay</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="gpay"/>
                                                                        <label className="form-check-label"
                                                                             >Google Pay</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="airtel"/>
                                                                        <label className="form-check-label"
                                                                            >Airtel Money</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="paytm"/>
                                                                        <label className="form-check-label"
                                                                             >Paytm Pay</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="jio"/>
                                                                        <label className="form-check-label">JIO Money</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="custom-form-check form-check">
                                                                        <input className="form-check-input mt-0"
                                                                            type="radio" name="flexRadioDefault"
                                                                            id="free"/>
                                                                        <label className="form-check-label"
                                                                             >Freecharge</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="right-side-summery-box">
                        <div className="summery-box-2">
                            <div className="summery-header">
                                <h3>Order Summery</h3>
                            </div>

                            <ul className="summery-contain">
                            
                            
                            {
    cartModel.lstCartProducts.map((product:any,i)=>
    {

        return ( 
                            
                                <li >
                                    <img src={ product.product_resources.length>0? ((cartModel.imagePath+product.image)+'.webp'):''}  
                                        className="img-fluid blur-up lazyloaded checkout-image" alt=""/>
                                    <h4>{product.title} <span>X {product.qty}</span></h4>
                                    <h4 className="price">Rs{((+product.qty)* (+product.selling_price))}</h4>
                                </li>
        )
    })
}
                               
                            </ul>

                            <div className="coupon-cart mt-20" style={ (+viewModel.couponDiscount)>0?{display:'none'}:{display:'block'}}>
                                <h6 className="text-content mb-2">Coupon Apply</h6>
                                <div className="mb-3 coupon-box input-group">
                                    <input type="email" className="form-control" id="txtCoupon"
                                        placeholder="Enter Coupon Code Here..."/>
                                    <button className="btn-apply"  onClick={viewModel.applayCoupon}>Apply</button>
                                </div>
                            </div>

                            {/* <div className="coupon-cart mt-20" style={ (+viewModel.couponDiscount)>0?{display:'block'}:{display:'none'}}>
                                
                                <div className="mb-3 coupon-box input-group">
                                    <p  className="form-control" id="txtCoupon" 
                                        >{viewModel.couponCode} applied</p>
                                    <button className="btn-apply"  onClick={viewModel.applayCoupon}>Remove</button>
                                </div>
                            </div> */}



                            <ul className="summery-total">
                                <li>
                                    <h4>Subtotal</h4>
                                    <h4 className="price">Rs {cartModel.lstCartProducts.reduce((total:any, product:any) => total+ ((+product.qty)* (+product.mrp)), 0)}</h4>
                                </li>

                                <li>
                                    <h4>Shipping</h4>
                                    <h4 className="price">Rs 0</h4>
                                </li>

                                <li>
                                    <h4>Tax(included price)</h4>
                                    <h4 className="price">Rs { (+(cartModel.lstCartProducts.reduce((taxTotal:any, product:any) => taxTotal+ (+product.tax), 0))).toFixed(2)} </h4>
                                </li>

                                <li>
                                    <h4>Coupon discount</h4>
                                    <h4 className="price">Rs -{viewModel.couponDiscount}</h4>
                                </li>
                                <li  >
                                <a  style={ (+viewModel.couponDiscount)>0?{display:'block',float:"right",marginLeft: 'auto',textAlign: 'end',marginTop: '-25px'}:{display:'none'}}   className="danger">Coupon '{viewModel.couponCode}' applied <span className="badge1 danger" onClick={viewModel.couponRemove}>X</span></a><br></br>
                                
   
                                    {/* <h4>{viewModel.couponCode} applied</h4>
                                    <button className="btn-apply"  onClick={viewModel.couponRemove}>Remove</button> */}
                                    
                                </li>

                                <li className="list-total">
                                    <h4>Total (INR)</h4>
                                    <h4 className="price">Rs {viewModel.netTotal} </h4>
                                </li>
                            </ul>
                        </div>

                        <div className="checkout-offer" style={viewModel.lstDiscounts.length==0?{display:'none'}:{display:'block'}}>
                            <div className="offer-title">
                                <div className="offer-icon">
                                    <img src="/images/inner-page/offer.svg" className="img-fluid" alt=""/>
                                </div>
                                <div className="offer-name">
                                    <h6>Available Offers</h6>
                                </div>
                            </div>

                            <ul className="offer-detail">

                                {

                                    viewModel.lstDiscounts.map((discount:any)=>{

                                        return ( <li>
                                            <p>{(+discount.discount_percentage).toFixed(0)}% Off: use coupon code <b>'{discount.coupon_code}'</b> avail discount</p>
                                        </li>)
                                    })
                                }
                               
                                
                            </ul>
                        </div>
                        {/* href="order-success.html" */}
                        <a><button onClick={()=>viewModel.onSaveOrder()} className="btn theme-bg-color text-white btn-md w-100 mt-4 fw-bold">Place Order</button></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="modal fade theme-modal" id="add-address" tabIndex={-1} aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel1">Add a new address</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="modal-body">
                
                 
                <div className="row">
                        <div className="col-md-3 col-6">
                <div className="custom-form-check form-check mb-0">
                                                                <label className="form-check-label"  ><input
                                                                        className="form-check-input mt-0" type="radio"
                                                                        name="addres_type"   onChange={(e)=>viewModel.addressTypeChange(e,'Home')} 
                                                                        
                                                                        
                                                                        checked={viewModel.addressDetails.addres_type=='Home'?true:false}/>
                                                                    Home</label>
                                                            </div>
                                                            </div>

                                                            <div className="col-md-3 col-6">
                <div className="custom-form-check form-check mb-0">
                                                                <label className="form-check-label"  ><input
                                                                        className="form-check-input mt-0" type="radio" onChange={(e)=>viewModel.addressTypeChange(e,'Work')} 
                                                                     
                                                                        checked={viewModel.addressDetails.addres_type=='Work'?true:false}
                                                                     
                                                                     name="addres_type"  />
                                                                    Work</label>
                                                            </div>
                                                            </div>

                                                            </div>
                    <div className="row">
                        <div className="col-md-12">
                    <form>
                        <div className="form-floating mb-4 theme-form-floating">
                            <input type="text" className="form-control" 
                            
                            name="full_name" value={viewModel.addressDetails.full_name} onChange={viewModel.handleChangeForAddress} 

                            
                            placeholder="Full Name"/>
                            <label>Full Name<span  className="m-0 text-danger">*</span></label>


                            
                        </div>
                        {((viewModel.addressDetails.full_name=='' ) && viewModel.submited) ?(<label  className="m-0 text-danger">Name required.</label>):''}
                    </form>
                </div>
                </div>

                <div className="row">
            <div className="col-md-6">
                <form>
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control"
                        
                        name="phone_number" value={viewModel.addressDetails.phone_number} onChange={viewModel.handleChangeForAddress} 
form-floating mb-4
                        
                        placeholder="Phone Number"/>
                        <label >Phone Number <span  className="m-0 text-danger">*</span></label>
                    </div>
                    {((viewModel.addressDetails.phone_number=='' ) && viewModel.submited) ?(<label  className="m-0 text-danger">Phone Number required.</label>):''}
                </form>
            </div>
            <div className="col-md-6">
                <form>
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control" 
                          name="alternate_phone_number" value={viewModel.addressDetails.alternate_phone_number} onChange={viewModel.handleChangeForAddress} 

                        
                        placeholder="Alternate Phone Number"/>
                        <label>Alternate Phone Number</label>
                    </div>
                </form>
            </div>
            </div>


                <div className="row"> 
            <div className="col-md-12">
                <form>
                    <div className="form-floating mb-4 theme-form-floating">
                        <input type="text" className="form-control"
                        
                        name="house_no_bulding_name" value={viewModel.addressDetails.house_no_bulding_name} onChange={viewModel.handleChangeForAddress} 

                        
                        placeholder="House No Bulding Name"/>
                        <label >House No Bulding Name <span  className="m-0 text-danger">*</span></label>
                    </div>
                    {((viewModel.addressDetails.house_no_bulding_name=='' ) && viewModel.submited) ?(<label  className="m-0 text-danger">House No Bulding Name required.</label>):''}
                </form>
            </div>
            </div>

            <div className="row">
                <div className="col-md-6">
            <form>
                <div className="form-floating mb-4 theme-form-floating">
                    <input type="text" className="form-control" 
                    
                    name="road_area_colony" value={viewModel.addressDetails.road_area_colony} onChange={viewModel.handleChangeForAddress} 

                    
                    placeholder="Road Area Colony"/>
                    <label >Road Area Colony<span  className="m-0 text-danger">*</span></label>
                </div>
                {((viewModel.addressDetails.road_area_colony=='' ) && viewModel.submited) ?(<label  className="m-0 text-danger">Road Area Colony required.</label>):''}

            </form>
        </div>
        <div className="col-md-6">
            <form>
                <div className="form-floating mb-4 theme-form-floating">
                    <input type="text" className="form-control" 
                    
                    name="city" value={viewModel.addressDetails.city} onChange={viewModel.handleChangeForAddress} 

                    
                    placeholder="City"/>
                    <label >City<span  className="m-0 text-danger">*</span></label>
                </div>
                {((viewModel.addressDetails.city=='' ) && viewModel.submited) ?(<label  className="m-0 text-danger">City required.</label>):''}

            </form>
        </div>
        </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="select-option">
                        <div className="form-floating mb-4 theme-form-floating">
                            <select className="form-select theme-form-select" 
                            name="state_id" value={viewModel.addressDetails.state_id} onChange={viewModel.handleChangeForAddress} 

                    
                            
                            aria-label="Default select example">
 <option value="0" >Select State
                                </option>
                                {

                                  viewModel.lstStates.map((state:any)=>
                                  {
                                    return ( <option value={state.state_id}>{state.state_name}
                                    </option>)
                                })
                                }
                               
                                
                            </select>
                            <label >Select State <span  className="m-0 text-danger">*</span></label>
                            
                        </div>
                {((viewModel.addressDetails.state_id==0 ) && viewModel.submited) ?(<label  className="m-0 text-danger">State required.</label>):''}

                    </div>
        </div>
        <div className="col-md-6">
            <form>
                <div className="form-floating mb-4 theme-form-floating">
                    <input type="text" className="form-control" 
                    
                    name="pincode" value={viewModel.addressDetails.pincode} onChange={viewModel.onPinCodeChange}  

                    
                    placeholder="Enter Pin Code"/>
                    <label >Pin Code<span  className="m-0 text-danger">*</span></label>
                </div>
                {((viewModel.addressDetails.pincode=='' ) && viewModel.submited) ?(<label  className="m-0 text-danger">Pin Code required.</label>):''}
                {viewModel.showPinCodeError==='true' ?(<label  className="m-0 text-danger">delivery not available to this location.</label>):''}
            </form>
        </div>
        </div>


                   
                </div>
                <div className="modal-footer">
                    <button type="button" id="btnAddressClose" className="btn btn-secondary btn-md" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn theme-bg-color btn-md text-white"   onClick={()=>viewModel.onAddressSave()}>Save
                        changes</button>
                </div>
            </div>
        </div>
    </div>


    <div className="modal fade theme-modal" id="add-children" tabIndex={-1} aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel1">Add Children</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="modal-body">
                
                 
                <div className="row">
                        <div className="col-md-3 col-6">
                <div className="custom-form-check form-check mb-0">
                                                                <label className="form-check-label"  ><input
                                                                        className="form-check-input mt-0" type="radio"
                                                                        name="addres_type"   onChange={(e)=>viewModel.genderTypeChange(e,'Male')} 
                                                                        
                                                                        
                                                                        checked={viewModel.childrenDetails.gender=='Male'?true:false}/>
                                                                    Male</label>
                                                            </div>
                                                            </div>

                                                            <div className="col-md-3 col-6">
                <div className="custom-form-check form-check mb-0">
                                                                <label className="form-check-label"  ><input
                                                                        className="form-check-input mt-0" type="radio" onChange={(e)=>viewModel.genderTypeChange(e,'Female')} 
                                                                     
                                                                        checked={viewModel.childrenDetails.gender=='Female'?true:false}
                                                                     
                                                                     name="addres_type"  />
                                                                    Female</label>
                                                            </div>
                                                            </div>

                                                            </div>
                    <div className="row">
                        <div className="col-md-12">
                    <form>
                        <div className="form-floating mb-4 theme-form-floating">
                            <input type="text" className="form-control" 
                            
                            name="children_name" value={viewModel.childrenDetails.children_name} onChange={viewModel.handleChangeForChildren} 

                            
                            placeholder="Full Name"/>
                            <label>Children Name<span  className="m-0 text-danger">*</span></label>


                            
                        </div>
                        {((viewModel.childrenDetails.children_name=='' ) && viewModel.submited) ?(<label  className="m-0 text-danger">Name required.</label>):''}
                    </form>
                </div>
                </div>
 

 
           
           
                </div>
                <div className="modal-footer">
                    <button type="button" id="btnAddressClose" className="btn btn-secondary btn-md" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn theme-bg-color btn-md text-white"   onClick={()=>viewModel.onChildrenSave()}>Save
                        changes</button>
                </div>
            </div>
        </div>
    </div>
    
    <Footer></Footer>
    
    </>)
}
export default Checkout;