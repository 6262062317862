import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/Page.Actions";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function CategoriesModel()
{
    
    const repository =SchoolRepository();
    const navigate = useNavigate();
    const lstStoreCat = useSelector((state: AppState) =>
    {
    //    /  
      

      return  typeof(state.storeData.categories)!='undefined'?state.storeData.categories:null;
    }
    );
    const selectedSchool = useSelector((state: AppState) =>
    {
    
   return  typeof(state.storeData.SelectdEcomSchool)!='undefined'?state.storeData.SelectdEcomSchool:null;
    }
    );
  const  onCategoryClick=(categoryName:any)=>
  {

    navigate('/products/'+JSON.stringify( {'type':'category','name':categoryName}));
    
  }

    useEffect(()=>
    {
    
        getMainCategories();
        
    },[lstStoreCat]);
    
    const [lstMainCategories,setMainCategories]=useState([]);
    const getMainCategories=( )=>
    {
      if(lstStoreCat!=null &&  lstStoreCat.length>0)
      {
        setMainCategories(lstStoreCat.filter((x:any)=> x.parent_category_id==0))
      }
    }
    const getAcronym = (str: string): string => {
      // Split the string into words
      const words = str.split(' ');
      
      // Get the first letter of each word
      const acronym = words.map(word => word.charAt(0).toUpperCase()).join('');
      
      return acronym;
    }


    const imagePath= repository.ecommerceImagePath;
    
    return {getAcronym,lstMainCategories,selectedSchool,imagePath,onCategoryClick}

}

