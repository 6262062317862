import { useParams } from "react-router-dom";
import { LoginModel } from "./LoginModel";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

const Login:React.FC=()=>
{
    const { returnPath } = useParams<{ returnPath: string }>();

    const viewModel=LoginModel(typeof(returnPath)!='undefined'?returnPath:'');
    return (<>
     <Header></Header>
    <section className="log-in-section background-image-2 section-b-space white">
        <div className="container-fluid-lg w-100">
            <div className="row">
                <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                    <div className="image-contain">
                        <img src="/images/inner-page/log-in.png" className="img-fluid" alt=""/>
                    </div>
                </div>

                <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                    <div className="log-in-box">
                        <div className="log-in-title">
                            <h3>Welcome To Education Today India</h3>
                            <h4>Log In Your Account</h4>
                        </div>

                        <div className="input-box">
                            <div className="row g-4">
                                <div className="col-12">
                                    <div className="form-floating theme-form-floating log-in-form">
                                        <input type="text" className="form-control" 
                                        
                                        name="mobile_number" value={viewModel.userDetails.mobile_number} onChange={viewModel.handleChange} 

                                        
                                        placeholder="Email Address"/>
                                        <label >Mobile Number</label>

                                    </div>
                                    {((viewModel.userDetails.mobile_number=='' ) && viewModel.submited) ?(<span className="m-0 text-danger">Enter mobile number</span>):''}

                                </div>

                                <div className="col-12">
                                    <div className="form-floating theme-form-floating log-in-form">
                                        <input type="password" className="form-control" 
                                        name="password" value={viewModel.userDetails.password} onChange={viewModel.handleChange} 
                                            placeholder="Password"/>
                                        <label >Password</label>
                                    {((viewModel.userDetails.password=='' ) && viewModel.submited) ?(<span  className="m-0 text-danger">Enter password</span>):''}

                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="forgot-box">
                                        <div className="form-check ps-0 m-0 remember-box">
                                            <input className="checkbox_animated check-box" type="checkbox"
                                                id="flexCheckDefault"/>
                                            <label className="form-check-label">Remember me</label>
                                        </div>
                                        <a href="forgot.html" className="forgot-password">Forgot Password?</a>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <button onClick={()=>viewModel.onLogin()} className="btn btn-animation w-100 justify-content-center"  >Log
                                        In</button>
                                </div>
                            </div>
                        </div>

                        <div className="other-log-in">
                            <h6>or</h6>
                        </div>

                        

                        <div className="other-log-in">
                            <h6></h6>
                        </div>

                        <div className="sign-up-box">
                            <h4>Don't have an account?</h4>
                            <a href="sign-up.html">Sign Up</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
      
    <Footer></Footer>
    </>)
}

export default Login;