import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useEffect, useState } from "react";
import { CommonFunctionality } from "../../repository/CommonFunctionality";
import { ActionType, AppState } from "../../store/Page.Actions";
import Swal from "sweetalert2";

export function MyOrdersModel()
{
    const dispatch = useDispatch();
    const repository =SchoolRepository();
    const navigate = useNavigate();
    const commonFunctionality=CommonFunctionality()
    const[orderDetails,setOrderDetails]=useState([]);
    const[selectedOrderItem,setSelectedOrderItem]=useState({})
    useEffect(()=>
    {

        getOrders();
    },[alert])

    const getOrders=()=>
    {
        //debugger;;
        setOrderDetails([]);
        repository.getOrdersByCustomer().then((result:any)=>{
//debugger;;
            if(result.length>0)
            {
            //debugger;;
                for(let i=0;i<result.length;i++)
            {
            
                try{
                var orderDetails=(((result[i].orderDetails)));
                result[i].orderDetails=commonFunctionality.stringFormatForJson(orderDetails)
                }catch(e)
                {
                }
            }
            setOrderDetails(result);
        }
            })
    }
    const onRateNowClick=(data:any)=>
    {
        //debugger;;
setSelectedOrderItem(data);
let image:any=[];

if(data.review_image !='')
{
    image.push({file:null,imageSrc: data.review_image  as string})
  //  setuploadedFiles(uFiles); 
}
if(data.review_image_1 !='')
{
    image.push({file:null,imageSrc: data.review_image_1  as string})
  //  setuploadedFiles(uFiles); 
}

if(data.review_image_2 !='')
{
    image.push({file:null,imageSrc: data.review_image_2  as string})
  //  setuploadedFiles(uFiles); 
}

if(data.review_image_3 !='')
{
    image.push({file:null,imageSrc: data.review_image_3  as string})
  //  setuploadedFiles(uFiles); 
}

if(data.review_image_4 !='')
{
    image.push({file:null,imageSrc: data.review_image_4  as string})
  //  setuploadedFiles(uFiles); 
}

setuploadedFiles(image);

    }
    const onRate=(rating:any)=>
    {
(selectedOrderItem as any).rating=rating;

let orderItem= {...selectedOrderItem};
setSelectedOrderItem(orderItem);
    }
    const[uploadedFiles,setuploadedFiles]=useState([]);
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        //debugger;;
        if(uploadedFiles.length<5)
        if (e.target instanceof HTMLInputElement && e.target.files && e.target.files.length > 0) {
         
            const selectedFile = e.target.files[0];

            if (selectedFile.size > ((1024 * 1024) * 3)) {
                //Swal.fire("Opps", "Max Image size is 3MB ", "error");
            }
            else {
                
                // Read the file and convert it to a data URL
                const reader = new FileReader();
                reader.onloadend = () => {
                    //debugger;;
                    let sImage:any={file:selectedFile,imageSrc: reader.result as string}
                    let uFiles:any=[...uploadedFiles]
                    uFiles.push(sImage)

                    for(let i=0;i<uFiles.length;i++)
                    {
                        switch(i)
                        {
case 0:
    {
        (selectedOrderItem as any).review_image=uFiles[i].imageSrc;
        break;
    }
    case 1:
    {
        (selectedOrderItem as any).review_image_1=uFiles[i].imageSrc;
        break;
    }
    case 2:
    {
        (selectedOrderItem as any).review_image_2=uFiles[i].imageSrc;
        break;
    }
    case 3:
    {
        (selectedOrderItem as any).review_image_3=uFiles[i].imageSrc;
        break;
    }
    case 4:
    {
        (selectedOrderItem as any).review_image_4=uFiles[i].imageSrc;
        break;
    }



                        }
                        
                    }
                    setuploadedFiles(uFiles);
                    e.target.value = '';
                   // setImageSrc();
                };
                reader.readAsDataURL(selectedFile);
                //debugger;;
            }
        }
    };
    const uploadImage = async (file:any,imageName:any) => {
        //debugger;;
        
        if (file && file!=null && file!='' && typeof(file)!="undefined" ) {
            //debugger;;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('imageToSave',  imageName);
            try {
                //debugger;;
                const responseData: any = await repository.uploadImage(formData);
                return responseData;
            }
            catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    }
const saveRating=async ()=>
{
//debugger;;
    let date = new Date();
    let imageName =(selectedOrderItem as any).order_details_id+'_'+date.getTime().toString();

    for(let i=0;i<uploadedFiles.length;i++)
    {
        
        let uploadedFile:any= uploadedFiles[i];
        if(uploadedFile.file !=null)
        {
        switch(i)
        {
case 0:
{
(selectedOrderItem as any).review_image=imageName;
break;
}
case 1:
{
(selectedOrderItem as any).review_image_1=imageName+'_1';
break;
}
case 2:
{
(selectedOrderItem as any).review_image_2=imageName+'_2';
break;
}
case 3:
{
(selectedOrderItem as any).review_image_3=imageName+'_3';
break;
}
case 4:
{
(selectedOrderItem as any).review_image_4=imageName+'_4';
break;
}



        }
    }
    }
    //debugger;;
await repository.saveRating(selectedOrderItem).then(async (result:any)=>
{
    //debugger;;
    if(typeof(result)!='undefined' &&  result.length>0 )
    {

        if (result[0].db_result > 0) {
            for(let i=0;i<uploadedFiles.length;i++)
            {
                let uploadedFile:any= uploadedFiles[i];

                switch(i)
                {
        case 0:
        {
         await   uploadImage(uploadedFile.file,  (selectedOrderItem as any).review_image);
        break;
        }
        case 1:
        {
            await  uploadImage(uploadedFile.file,  (selectedOrderItem as any).review_image_1);
        break;
        }
        case 2:
        {
            await  uploadImage(uploadedFile.file,  (selectedOrderItem as any).review_image_2);
        break;
        }
        case 3:
        {
            await uploadImage(uploadedFile.file, (selectedOrderItem as any).review_image_3);
        break;
        }
        case 4:
        {
            await uploadImage(uploadedFile.file,  (selectedOrderItem as any).review_image_4);
        break;
        }
        
        
        
                }
            }
            $("#btnRatingClose").click();
            if(await (await Swal.fire("Rating submited successfully", "", "info")).isConfirmed==true)
            {

                window.location.reload();

            };
       
        }
    }

})

}



const onReviewChange=(e:any)=>
{
    let prodDetails:any={...selectedOrderItem};
   
    prodDetails.review=e.target.value;
    setSelectedOrderItem(prodDetails);
}
    const imagePath= repository.ecommerceImagePath;

    const getImagePath=(image:any)=>
{
  

    if(typeof( image)!="undefined")
    {
        let spDetails=image.split(':');

    if(spDetails.length==1)
    {
if(image!='')
{
 return  imagePath+image+'.webp';

}

    }
else{
    return image;
}
    }
}
const onRemoveImage=(image:any,index:number)=>
{
    //debugger;;

    let lstImages:any=[...uploadedFiles]
    lstImages.splice(index,1);
    setuploadedFiles(lstImages);

    let prodDetails:any={...selectedOrderItem};
    // let pdetails:any=[];
    // pdetails.push(prodDetails);
    prodDetails[image]='';

setSelectedOrderItem(prodDetails);



}

const [strReturnDisplayVariants,setReturnDisplayVariants]=useState('Product')
const [returnType,setReturnType]=useState('');
const [reson,setReson]=useState('');
const [selectedIndex,setSelectedIndex]=useState(-1)
const onReturnClick=(orderPoducts:any,index :any)=>
{
    setSelectedIndex(index);

    setReturnQty(0);
setReturnQtyList([]);
setReturnType('');
setReson('');
    let variantString= orderPoducts.variants.split(', ').map((attr:any) => attr.split(':')[0]).join('/');
    setReturnDisplayVariants(variantString!=''?variantString:'Product');
let lstQty:any=[];
for(let i=0;i<((+orderPoducts.qty)-(+orderPoducts.return_qty));i++)
{
    lstQty.push(i+1);
    if(i==0)
    {
        setReturnQty(i+1)
    }
   
}
setReturnQtyList(lstQty);

    setProductDetailsId(orderPoducts.product_details_id);
}

const onResonChange=(e:any)=>
{

    setReturnType(e.target.value);

}
const onReson=(e:any)=>
{

    setReson(e.target.value);

}






const [productDetailsId,setProductDetailsId]=useState(0)


useEffect(()=>
    {
        
     
        if(typeof(productDetailsId) !='undefined' &&  productDetailsId!=0)
        {
            getProductDetailsByDetailsId(productDetailsId);
        }
       
    
        
    },[productDetailsId]);



    const getProductDetailsByDetailsId=(details_id:any)=>
    {
        //debugger;;
    
        repository.getProductDetailsByDetailsId(details_id).then((result:any)=>
        {
            if(result.length>0)
            {
    
    for(let i=0;i<result.length;i++)
    {
    
        try{
        var product_feature_feilds=(((result[i].product_feature_feilds)));
        result[i].product_feature_feilds=commonFunctionality.stringFormatForJson(product_feature_feilds)
        }catch(e)
        {
    ////debugger;
        }
        try{
        var product_resources=(((result[i].product_resources)));
        result[i].product_resources=commonFunctionality.stringFormatForJson(product_resources)
    }catch(e)
        {
          //  //debugger; 
        }
        try{
        var ProductVariants=(((result[i].ProductVariants)));
        result[i].ProductVariants=commonFunctionality.stringFormatForJson(ProductVariants)
        
    
    }catch(e)
    {
        ////debugger; 
    }
    
    
    try{
        var productDiscounts=(((result[i].product_discounts)));
        result[i].product_discounts=commonFunctionality.stringFormatForJson(productDiscounts)
         
    
    }catch(e)
    {
        ////debugger; 
    }
    
    
    try{
        var product_variants=(((result[i].product_variants)));
        result[i].product_variants=commonFunctionality.stringFormatForJson(product_variants)
         
    
    }catch(e)
    {
        ////debugger; 
    }
    
    
    
    
    }
    setSelectedProduct(result)
    prepareVariantGroups(result);
    setSelectedProductDetailsVariant(result);
    //getRelatedProducts();
            }
    
         ;
        });
    
    }

    const[product,setSelectedProduct]=useState([]);
 
    const [lstVariantGroup,setVariantGroup]=useState([]);
    const prepareVariantGroups= (lstproducts: any[]) => {
        
        try {
            const uniqueVariantGroupSet: Set<string> = new Set();
            const uniqueVariantGroup: any = [];
    
            lstproducts.forEach((products: any) => {
    if(products.product_variants!=null &&  typeof( products.product_variants)!='undefined' && products.product_variants!='')
    {
                products.product_variants.forEach((product: any) => {
    
                const variants_Name = `${product.product_variants_title}`;
                if (!uniqueVariantGroupSet.has(variants_Name)) {
                    uniqueVariantGroupSet.add(variants_Name);
                    uniqueVariantGroup.push( { 'product_variants_title': product.product_variants_title });
                }
            })
    
        }
            });
    
            setVariantGroup( uniqueVariantGroup);
        } catch (error) {
            console.error("Error parsing JSON:", error);
            return [];
        }
    };
    const setSelectedProductDetailsVariant=((selectedProduct:any)=>
    {
    
    let selectedProductDetailsId=selectedProduct[0].selected_product_details_id;
    
    
     let resultsproducts=selectedProduct.filter((product:any)=>{
    
        return    product.product_details_id===selectedProductDetailsId;
       })
    
        setSelectedProductDetails( resultsproducts[0]);
    });
    const[productDetails,setSelectedProductDetails]=useState({});

    const displayVarients=(varients:any)=>
    {
       // //debugger;
        if(varients=='' || typeof(varients)=='undefined')
        {
            return '';
        }
    try{
       return  ' with '+ varients.map((obj:any) => `${obj.product_variant_value} ${obj.product_variants_title}`).join(' with ');
    }catch(e)
    {
        
    }
    
    }
    const getVariantbyGroup= ( variantGroup: any) => {
        try {
            const uniqueVariantSet: Set<string> = new Set();
            const uniqueVariant: any = [];
    
            product.forEach((products: any) => {
                if(products.product_variants!=null &&  typeof( products.product_variants)!='undefined' && products.product_variants!='')
                {
                products.product_variants.forEach((product: any) => {
    if(product.product_variants_title==variantGroup)
    {
                const variants_Name = `${product.product_variant_value}`;
                if (!uniqueVariantSet.has(variants_Name)) {
                    uniqueVariantSet.add(variants_Name);
                    uniqueVariant.push( { 'product_variant_value': product.product_variant_value });
                }
            }
            })
        }
    
    
            });
    
           return uniqueVariant;
        } catch (error) {
            console.error("Error parsing JSON:", error);
            return [];
        }
    };
    const onVaraintClick=(group:any,value:any)=>
    {
         //debugger;;
     var  preProductDetails:any=deepCopy(productDetails) ;
    
    let preProductVaraint:any=preProductDetails.product_variants;
    let selectableProductDetailsId=0;
    let clickedVaraintProductDetailsId=0;
    for(let i=0;i<preProductVaraint.length;i++)
    {
    
        if(preProductVaraint[i].product_variants_title===group)
        {
            preProductVaraint[i].product_variant_value=value;
            break;
        }
    }
    
    
    for(let i=0;i<product.length;i++)
    {
     
    let productVaraint:any=(product[i] as any).product_variants;
    
    if(clickedVaraintProductDetailsId==0)
    {
       let cindex= productVaraint.findIndex((pv:any)=>{
          return   (pv.product_variants_title===group && pv.product_variant_value===value)
        });
    
    if(cindex>-1)
    {
        clickedVaraintProductDetailsId=  productVaraint[cindex].product_details_id;
    }
    }
    
    if(productVaraint.length== preProductVaraint.length && selectableProductDetailsId==0)
    {
    
        for(let p=0;p<preProductVaraint.length;p++)
        {
    
            let index=   productVaraint.findIndex((vrnt:any)=>
            {
    
                return (vrnt.product_variants_title===preProductVaraint[p].product_variants_title && vrnt.product_variant_value===preProductVaraint[p].product_variant_value)
            })
            if(index==-1)
            {
                selectableProductDetailsId=0;
    break;
            }else{
                selectableProductDetailsId=productVaraint[index].product_details_id;
            }
    
        }
    
    
    
    }
    
    
    }
    
    if(selectableProductDetailsId>0 || clickedVaraintProductDetailsId>0)
    {
    
        let resultsproducts= product.filter((product:any)=>{
    
            return    (+product.product_details_id)===(+(selectableProductDetailsId>0?selectableProductDetailsId:clickedVaraintProductDetailsId));
           })
        
            setSelectedProductDetails( resultsproducts[0]);
    }
    
    
    
        
    }


    function deepCopy(obj:any) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }
        
        let copy:any;
        if (obj instanceof Array) {
            copy = [];
            for (let i = 0; i < obj.length; i++) {
                copy[i] = deepCopy(obj[i]);
            }
            return copy;
        } else if (obj instanceof Object) {
            copy = {};
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    copy[key] = deepCopy(obj[key]);
                }
            }
            return copy;
        }
    }
    const getActiveClass=(group:any,value:any):any=>
    {
    
        
        if((productDetails as any).product_variants!=null && (productDetails as any).product_variants!='' && typeof((productDetails as any).product_variants)!='undefined')
        {
       let index=(productDetails as any).product_variants.findIndex((x:any)=>
        {
           return   x.product_variants_title==group && x.product_variant_value==value
        })
        if(index>-1)
        {
            return 'active'
        }
        else{
            return '';
        }
    }else
    {
        return '';
    }
    
    }
    
    const [returnQtyList,setReturnQtyList]=useState([]);


    const [returnQty,setReturnQty]=useState(0);

const onReturnQtyChange=(e:any)=>
{

    setReturnQty(e.target.value);

}
const storeSelectedOrder = useSelector((state: AppState) =>
{
//    /  
  return   typeof(state.storeData.selectedOrder)!='undefined'?state.storeData.selectedOrder:{}; 
}
);

const [selectedOrder,setSelectedOrder]=useState({} as any);
 
const onOrderReturnClick=(orderDetails:any)=>
{

    

    dispatch({
        type: ActionType.STORE,
        payload: {
            storeData:orderDetails,
            viewName:'selectedOrder',
        },
      });
navigate('/productdetails');


    navigate('/orderdetails');
}


//const [lstReplaceProducts,setReplaceProducts]=useState([]);
const [lstReturnProducts,setReturnProducts]=useState([]);

const onReturnItemClick=()=>
{
    //debugger;;
    

    let index1=   lstReturnProducts.findIndex((x:any)=> x.index==selectedIndex);


    if(index1>-1)
    {
        lstReturnProducts.splice(index1,1);
    }
    
    
    let lstRtList:any=[...lstReturnProducts];
    
    lstRtList.push({
        
        order_details_id:((storeSelectedOrder as any).orderDetails[selectedIndex]['order_details_id']),
        product_details_id:(productDetails as any).product_details_id,
        qty:returnQty,
        order_id:((storeSelectedOrder as any).orderDetails[selectedIndex]['order_id']),
        image:(productDetails as any)['image'],
        title:(productDetails as any)['title'],
       index:selectedIndex,
        reson_type:returnType,
        reson:reson,
        return_type:'Return'
    })
    
    setReturnProducts(lstRtList);
    $("#ctrReturnClose").click();
}

const onReplaceClick=()=>
{


let index1=   lstReturnProducts.findIndex((x:any)=> x.index==selectedIndex);


if(index1>-1)
{
    lstReturnProducts.splice(index1,1);
}


let lstRpList:any=[...lstReturnProducts];

lstRpList.push({
    
    order_details_id:((storeSelectedOrder as any).orderDetails[selectedIndex]['order_details_id']),
    product_details_id:(productDetails as any).product_details_id,
    qty:returnQty,
    order_id:((storeSelectedOrder as any).orderDetails[selectedIndex]['order_id']),
    image:(productDetails as any)['image'],
    title:(productDetails as any)['title'],
    index:selectedIndex,
    reson_type:returnType,
    reson:returnType,
    return_type:'Replace'
})

setReturnProducts(lstRpList);
$("#ctrReturnClose").click();
    
}

const getreturnQty=(order_details_id:any)=>
{

 
    let  sum = lstReturnProducts
    .filter((item:any) => item.order_details_id === order_details_id)
    .reduce((acc, current) => acc + (current as any).qty, 0);

    return sum;
    

}
const onSaveReturns=()=>
{
    //debugger;;
repository.saveReturns(lstReturnProducts).then((result:any)=>{
    if(typeof(result)!='undefined' &&  result.length>0 )
    {

        if (result[0].db_result > 0) {
            Swal.fire("Return placed successfully", "", "info");
            navigate('/myorders');
        
        }
    }
    //debugger;;

});

}
    return {onSaveReturns,lstReturnProducts,getreturnQty,onReturnItemClick,onReplaceClick, onOrderReturnClick,storeSelectedOrder, returnQty,onReturnQtyChange,returnQtyList,getActiveClass,deepCopy,onVaraintClick,getVariantbyGroup,displayVarients,productDetails,lstVariantGroup,product,onReson,returnType,onResonChange,strReturnDisplayVariants,onReturnClick,onRemoveImage,getImagePath,onReviewChange,saveRating,handleImageChange,onRate,onRateNowClick,selectedOrderItem,imagePath,orderDetails}
}