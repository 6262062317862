import { SchoolSelectionModel } from "./SchoolSelectionModel";

const SchoolSelection:React.FC=()=>
{

    
const viewModel=SchoolSelectionModel('');

    return ( <div className="location-list">
    <div  className="city">
    <h5 className="modal-titles" id="exampleModalLabel"  >Choose your School</h5>
    <p className="mt-1 text-content mb-20">Enter your address and we will specify the offer for your area.</p>
    <div className="select-option" style={viewModel.lstSchools.length === 0 ? { display: 'block' } : { display: 'none' }} >
        <div className="form-floating theme-form-floating">
            <select className="form-select theme-form-select" aria-label="Default select example" onChange={($event)=>viewModel.onStateChange($event)}>
                <option value="0" >Select State</option>
                {

          (typeof(viewModel.lstStates)!='undefined')?
           
viewModel.lstStates.map((item:any)=>
    {
return (<option value={item.state_id}>{item.state_name}</option>);

    })
                :''
            }
            </select>
            <label>State *</label>
            <br/>
            <div className="form-floating theme-form-floating">
            <select className="form-select theme-form-select" aria-label="Default select example" onChange={($event)=>viewModel.onDistrictChange($event)}>
                <option  value="0">Select Districts</option>
                {
viewModel.lstDistricts.map((item:any)=>
    {
return (<option value={item.district_id}>{item.district_name}</option>);

    })
                }
            </select>
            <label>Districts  *</label>
            </div>
            <br/>
            <div className="form-floating theme-form-floating">
            <select className="form-select theme-form-select" aria-label="Default select example" onChange={($event)=>viewModel.onCityChange($event)}>
                <option value="0">Select City</option>
                {
viewModel.lstCities.map((item:any)=>
    {
return (<option value={item.city_id}>{item.city_name}</option>);

    })
                }
            </select>
            <label>City *</label>
        </div>
    </div>
    {/* <button className="btn btn-md theme-bg-color text-white mt-sm-3 mt-1 fw-bold mend-auto text-right"  >Next</button> */}
    </div>
   
    </div>
    <div  className="city" style={!viewModel.showSchool ? { display: 'none' } : { display: 'block' }} >
         
        <div className="location-list">
            <div className="search-input">
                <input type="search" className="form-control" placeholder="Search School" onChange={($event)=>viewModel.onSearchSchool($event)}/>
                <i className="fa-solid fa-magnifying-glass"></i>
            </div>

            <div className="disabled-box">
                <h6>Select a School's</h6>
            </div>

            <ul className="location-select custom-height">
                <li className="school01">

{
    viewModel.lstSchools.map((item:any)=>
    
    {
return (<a  onClick={()=>viewModel.onSchoolSelect(item)}>
<div className="row">
<div className="col-md-2">
<img   src={`${viewModel.schoolImagePath}${item.image}.webp?${new Date().getTime()}`} title={item.school_name} alt={item.school_name} className="w-100"/>
    </div>
    <div className="col-md-10">
    <h6>{item.school_name}</h6>
    </div>
</div>

</a>);
    })
}


                    
                </li>

               

               
            </ul>
        </div>
        <div className="row">
            <div className="col-md-6">
            <button className="btn btn-md theme-bg-color text-white mt-sm-3 mt-1 fw-bold mend-auto text-left" onClick={()=>viewModel.onBackClick()}>back</button>


            </div>
            <div className="col-md-6">
            {/* <button className="btn btn-md theme-bg-color text-white mt-sm-3 mt-1 fw-bold mend-auto text-right"  >Next</button> */}


            </div>

        </div>
        
      </div>
      
    

    
</div>)
}
export default SchoolSelection;