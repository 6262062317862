import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { WishlistModel } from "./WishlistModel";

const WishList: React.FC = () => {
    const viewModel = WishlistModel();
    return (<>
        <Header></Header>
        <section className="product-list-section section-b-space white">
            <div className="container">
                <div className="title">
                    <h2>Wishlist Products</h2>
                    <span className="title-leaf">
                        <svg className="icon-width">
                            <use href="/svg/leaf.svg#leaf"></use>
                        </svg>
                    </span>
                </div>
                <div className="row">
                    {/* { viewModel.fromPrice+':'+viewModel.toPrice} */}
                    {
                        viewModel.lstWishlistProducts.map((item: any, i) => {
                            return (
                                <div className="col-xxl-3 col-lg-3 col-md-4 col-6 wow fadeInUp mb-3" key={i}>
                                    <div className="product-box-4" >
                                        <div className="product-image">
                                            <div className="label-flex">
                                                <button className="btn p-0 wishlist btn-wishlist notifi-wishlist" style={{ zIndex: "9999" }} onClick={() => viewModel.onWishlistClick(item)}>
                                                    <i className="iconly-Heart icli" style={viewModel.isWishlistItem(item.product_details_id) > -1 ? { color: "red" } : {}}></i>
                                                </button>
                                            </div>
                                            <a onClick={() => viewModel.onProductClick(item)}>
                                                <img src={item.product_resources.length > 0 ? ((viewModel.imagePath + item.image) + '.webp') : ''} className="img-fluid" alt="" />
                                            </a>
                                        </div>
                                        <div className="product-detail">
                                            <span className="span-name">{item.brand_name}</span>
                                            <a>
                                                <h5 className="name">{item.title}</h5>
                                            </a>
                                            <h5 className="price theme-color">Rs {item.selling_price}<del style={((+item.mrp) - (+item.selling_price)) > 0 ? { display: 'block' } : { display: 'none' }}>Rs {item.mrp}</del></h5>
                                            <div className="price-qty"></div>
                                        </div>
                                    </div>
                                </div>

                            );
                        })



                    }




                </div>
            </div>
        </section>
        <Footer></Footer>
    </>)

}
export default WishList;