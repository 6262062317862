import { useNavigate } from "react-router-dom";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { UserState } from "../../repository/UserState";
import { useState } from "react";

export function  LoginModel(returnPath:any)
{
    const repository=SchoolRepository();
    const userState=UserState();
    const navigate = useNavigate();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setUserDetails((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setUserDetails((prev) => ({ ...prev, [name]: value }));
        }
    }
    const [userDetails, setUserDetails] = useState({
        
        mobile_number: '',
         
        password: '' 
        
    });

    const validateLogin=()=>
    {
      let validate=true;
      
      if(userDetails?.mobile_number =='' && validate)
      {
        validate=false
      }
       
      if(userDetails?.password =='' && validate)
      {
        validate=false;
      }
       
      
       
    return validate;
    }
const [submited,setSubited]= useState(false);
    const onLogin=async ()=>
    {
        
        setSubited(true);
        if(validateLogin())
        {
            const responseData:any = await repository.customerLogin(userDetails);
            if(responseData.length>0)
            {
    
    
                 
    
                userState.setUserState(responseData[0]);
    
    
                
                navigate('/'+returnPath);
    
            }
       
    
        }
    }
    return {submited,userDetails,handleChange,onLogin}
}