import React from 'react';
import { MenuModel } from './MenuModel';
import ChildCategories from './ChildCategories';

const MenuCategories: React.FC<{ lstMainCategories: any[] }> = ({ lstMainCategories }) => {
    const viewModel=MenuModel()
  if (!lstMainCategories || lstMainCategories.length==0) {
    return null;
  }

  return (
    <ul className="dropdown-menu">
      {lstMainCategories.map((item: any) => {
        ////debugger;;
        return item.is_parent === 'true' ? (
          <li className="sub-dropdown-hover" key={item.category_id}>

            <a  onClick={()=>viewModel.onCategoryClick(item.category_name)} className="dropdown-item">{item.category_name}</a>
            {/* Recursively render nested categories */}
            {/* <MenuCategories lstMainCategories={viewModel.getChildDetails(item.category_id)} /> */}
            <ChildCategories lstMainCategories={viewModel.getChildDetails(item.category_id)} /> 
            
          </li>
        ) : (
          <li key={item.category_id}>
            <a className="dropdown-item"onClick={()=>viewModel.onCategoryClick(item.category_name)}>{item.category_name}</a>
          </li>
        );
      })}
    </ul>
  );
};

export default MenuCategories;
