export function CommonFunctionality()
{

    const stringFormatForJson=(stringValue:any)=>
    {
//debugger;;
// let resultValue =stringValue.replace(/'/g, '"');
// //stringValue =(stringValue.replace(/\n/g, "")).replace(/'/g,"\"");
//  resultValue = stringValue.replace(/\n/g, "");
//  resultValue = resultValue.replace(/\\n/g, "\\n");
// resultValue = resultValue.replace(/\\'/g, "'");
// resultValue = resultValue.replace(/\\"/g, '"');
// resultValue = resultValue.replace(/\\\\/g, "\\");
//         try{
//         let res= JSON.parse( resultValue);
//         }catch(e)
//         {

//             console.log(e);
//         }

       
try{
    let requestedString=stringValue;
    requestedString =(requestedString.replace(/\n/g, "")).replace(/'/g,"\"");

   return  JSON.parse( requestedString);
}

catch(e)
        {
            let requestedString=stringValue;
            requestedString = requestedString.replace(/\\n/g, "\\n");
            requestedString = requestedString.replace(/\\'/g, "'");
            requestedString = requestedString.replace(/\\"/g, '"');
            requestedString = requestedString.replace(/\\\\/g, "\\");
            try{
           return  JSON.parse( requestedString);
            }catch(e)
            {

                //debugger;;
            }
        }





       
    }

return{stringFormatForJson}

}