import { useEffect, useState } from "react"
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ActionType } from "../../store/Page.Actions";

export function SchoolSelectionModel(schoolName:any)
{
    
    const repository =SchoolRepository();
    const schoolImagePath=repository.schoolImagePath;
    const dispatch = useDispatch();
    const navigate = useNavigate();
const [lstStates,setStates]=useState([]);
const [lstDistricts,setDistricts]=useState([]);
const [lstCities,setCities]=useState([]);
const [lstSchools,setSchools]=useState([]);

const [lstAllSchools,setAllSchools]=useState([]);

useEffect(()=>
{

    getShoolProducts(schoolName);
    getStates();
},[]);
// useEffect(()=>
// {

//     setSchools( lstAllSchools );
    
// },lstAllSchools);

//const [schoolProducts,setSchoolProducts]=useState([]);


const getShoolProducts=(schoolName:any)=>
{
    
    if(typeof( schoolName)!='undefined' && schoolName!='')
    {
      //  setSchoolProducts([]);
        repository.getSchoolProductsBySchool(schoolName).then((result:any)=>
        {
            
            if(result.length>0)
            {
        //    setSchoolProducts(result);


        dispatch({
            type: ActionType.STORE,
            payload: {
              storeData: {'school_id':result[0].school_id,'udise_code':result[0].udise_code,'school_name':result[0].school_name,'contact_no':result[0].contact_no,'email':result[0].email,'image':result[0].image},
              viewName: 'SelectdEcomSchool',
            },
          });


            dispatch({
                type: ActionType.STORE,
                payload: {
                  storeData: result,
                  viewName: 'SchoolProducts',
                },
              });

            }else{
                dispatch({
                    type: ActionType.STORE,
                    payload: {
                      storeData: [],
                      viewName: 'SchoolProducts',
                    },
                  });
                //setSchoolProducts([]);
            }
        })
        
    }
}

const [showSchool,setshowSchool]=useState(false);

useEffect(()=>
{
if(lstAllSchools.length>0)
{
    setshowSchool(true);
    setSchools( lstAllSchools );
}
},[lstAllSchools]);

const getStates=async ()=>
{
    setStates( await repository.viewStates());

}

const onDistrictChange=async (e:any)=>
{

setCities( await repository.viewCities(e.target.value) );

}

const onStateChange=async (e:any)=>
{

setDistricts( await repository.viewDistricts(e.target.value) );

}
//var  selectedCity:number=0;
const onCityChange=async (e:any)=>
{

//selectedCity=(+e.target.value);
await repository.searchSchools( {'city_id':e.target.value}) .then((result:any)=>
{
    
    setAllSchools(result);
    
})
 


}


const  onSearchSchool=async (e:any)=>
{
    let lstSearchResult= Object.assign([], lstAllSchools.filter((x:any)=>
      
        ( x.school_name.toLowerCase( ).includes(e.target.value.toLowerCase( )) 
         
        
       
        
        )
         
         
         ) );
         setSchools( lstSearchResult );

}
const onSchoolSelect=(school:any)=>
{
    
    dispatch({
        type: ActionType.STORE,
        payload: {
          storeData: school,
          viewName: 'SelectdEcomSchool',
        },
      });
      navigate('/'+school.school_name);

    //  getShoolProducts(school.school_name);

window.location.reload();
}

const onBackClick=()=>
{
    setSchools([]);
    setCities([]);
    setDistricts([]);
    setStates([]);
}


    return{showSchool,lstStates,lstDistricts,lstCities,lstSchools,schoolImagePath,onSchoolSelect,onStateChange,onDistrictChange,onCityChange,onBackClick,onSearchSchool}

}
