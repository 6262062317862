import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { CartModel } from "../../components/Cart/CartModel";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { UserState } from "../../repository/UserState";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";

export function CheckoutModel()
{
  const dispatch = useDispatch();
    const repository =SchoolRepository();
    const cartModel=CartModel();
    const userState=UserState();
    const navigate = useNavigate();
    const [showPinCodeError,setShowPinCodeError]=useState('false');

    const selectedSchool = useSelector((state: AppState) =>
    {
    
   return  typeof(state.storeData.SelectdEcomSchool)!='undefined'?state.storeData.SelectdEcomSchool:null;
    }
    );

    useEffect(()=>
    {
        if((+userState.loggedUser.customer_id)>0)
        getAddressByCustomer();
        getChildrensByCustomer();
    },[userState.loggedUser]);
const [lstCustomerAddress,setCustomerAddress]=useState([]);
const [lstCustomerChildrens,setCustomerChildrens]=useState([]);




const  getChildrensByCustomer=async ()=>
{
   const responseData:any = await repository.getChildrensByCustomer();
   if(responseData.length>0)
   {
       setCustomerChildrens(responseData);
   }
}

 const  getAddressByCustomer=async ()=>
 {
    const responseData:any = await repository.getAddressByCustomer();
    if(responseData.length>0)
    {
        setCustomerAddress(responseData);
    }
 }

 const [lstDiscounts,setDiscounts]=useState([]);
useEffect(()=>
{


getDiscounts();
},[alert])


const getDiscounts=async ()=>
{
  setDiscounts([]);
  const responseData:any =await  repository.getDisacounts();
  if(responseData.length>0)
  {
    setDiscounts(responseData);

  }
}
const onPinCodeChange=async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>)=>
{
  handleChangeForAddress(e);
  
if(e.target.value.length>5)
{


const responseData:any = await repository.checkPincode(e.target.value);

if(responseData.delivery_codes.length>0)
{
  setShowPinCodeError('false');
}
else{
  setShowPinCodeError('true');
}


}




}



const handleChangeForChildren = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    
  if ('value' in e.target) {
      const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
      setchildrenDetails((prev) => ({ ...prev, [name]: value }));
  } else {
      const { name, value } = e.target as HTMLSelectElement;
      setchildrenDetails((prev) => ({ ...prev, [name]: value }));
  }
}



const handleChangeForAddress = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    
    if ('value' in e.target) {
        const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
        setAddressDetails((prev) => ({ ...prev, [name]: value }));
    } else {
        const { name, value } = e.target as HTMLSelectElement;
        setAddressDetails((prev) => ({ ...prev, [name]: value }));
    }
}



useEffect(()=>
{
    getStates();
},[alert])

  const [lstStates,setStates]=useState([]);

const getStates=async ()=>
{

 await   repository.viewStates().then((result:any)=>
    
    {
        setStates(result);

    })


}




const [childrenDetails, setchildrenDetails] = useState({
    
  customer_id: (+userState.loggedUser.customer_id),
children_id:0,
  gender: 'Male',
  children_name: '' 
});

const [addressDetails, setAddressDetails] = useState({
    
    customer_id: (+userState.loggedUser.customer_id),
    addres_type: 'Home',
    full_name: '',
    phone_number: '',
    alternate_phone_number: '',
    pincode: '',
    state_id: 0,
    city: '',
    house_no_bulding_name:'',
    road_area_colony:'',
    customer_address_id:0
});

const [selectedAddres,setSelectedAddress]=useState({});
const [selectedChildrens,setSelectedChildrens]=useState([]);


const genderTypeChange=(e:any,value:any)=>
{
    
if(e.target.value=='on')
{
   var addr={...childrenDetails};
   addr.gender=value;
   setchildrenDetails(addr);
 // setAddressDetails((prev) => ({ ...prev, [addres_type]: value }));
}

}


const addressTypeChange=(e:any,value:any)=>
{
    
if(e.target.value=='on')
{
   var addr={...addressDetails};
   addr.addres_type=value;
   setAddressDetails(addr);
 // setAddressDetails((prev) => ({ ...prev, [addres_type]: value }));
}

}

const  validateSaveChildren=():boolean=>
{
    let validate=true;
  if(childrenDetails?.children_name =='')
  {
    validate=false
  }

  if(childrenDetails?.gender =='')
  {
    validate=false
  }


  return validate 
}

const  validateSaveAddress=():boolean=>
{
    let validate=true;
  if(addressDetails?.full_name =='')
  {
    validate=false
  }

  if(addressDetails?.addres_type =='')
  {
    validate=false
  }


  if(addressDetails?.phone_number =='')
  {
    validate=false
  }

  if(addressDetails?.pincode =='')
  {
    validate=false
  }

  if(addressDetails?.state_id ==0)
  {
    validate=false
  }

  if(addressDetails?.city =='')
  {
    validate=false
  }
  if(addressDetails?.house_no_bulding_name =='')
  {
    validate=false
  }


  if(addressDetails?.road_area_colony =='')
  {
    validate=false
  }
  return validate 
}

const [submited,setSubmited]=useState(false);

 const onAddressSave=()=>
 {
    
    setSubmited(true);
    if(validateSaveAddress())
    {
        
repository.saveCustomerAddress(addressDetails).then((result:any)=>
{
    
    if(result.length>0)
    {
        if((+result[0].db_result)>0)
        {
            addressDetails.customer_address_id=result[0].customer_address_id;
            setSelectedAddress(addressDetails);
            getAddressByCustomer();
        }
       
    }
   

    
    $('#btnAddressClose').click();
})

    }
 }




 const onChildrenSave=()=>
 {
    
    setSubmited(true);
    if(validateSaveChildren())
    {
        
repository.saveCustomerChildrens(childrenDetails).then((result:any)=>
{
    
    if(result.length>0)
    {
        if((+result[0].db_result)>0)
        {
          childrenDetails.children_id=result[0].children_id;
          let selectedChilds:any=[...selectedChildrens]
          selectedChilds.push(childrenDetails)
          setSelectedChildrens(selectedChilds);
            getChildrensByCustomer();
        }
       
    }
   

    
    $('#btnAddressClose').click();
})

    }
 }


 const onSelectedChildChange=(e:any,child:any)=>
 {
    
    if(e.target.checked)
{
  
  let selectedChilds:any=[...selectedChildrens]
  selectedChilds.push(child)
  setSelectedChildrens(selectedChilds);
 
 
}else{


  let index=  selectedChildrens.findIndex(((x:any)=>x.children_id==child.children_id));


  let selectedChilds:any=[...selectedChildrens]
  selectedChilds.splice(index,1);
  setSelectedChildrens(selectedChilds);

}

 }



 const onSelectedAddressChange=(e:any,address:any)=>
 {
    
    if(e.target.value=='on')
{
    setSelectedAddress(address);
}

 }

 const getTotalGross=():any=>
 {
 
return   cartModel.lstCartProducts.reduce((total:any, product:any) => total+ ((+product.qty)* (+product.mrp)), 0)
  
 }




 const getTotalProductPrice=():any=>
 {

return   cartModel.lstCartProducts.reduce((total:any, product:any) => total+ ((+product.qty)* (+product.selling_price)), 0)
  
 }



 const getTotalTax=():any=>
 {

return   cartModel.lstCartProducts.reduce((totalTax:any, product:any) => totalTax+ (+product.tax), 0)
  
 }


const getTotalProductDiscount=():any=>
{

  return getTotalGross()- getTotalProductPrice();

}
const [couponDiscount,setcouponDiscount]=useState(0);
const [couponCode,setcouponCode]=useState('');

const [deliveryCharges,setdeliveryCharges]=useState(0);

const [netTotal,setNetTotal]=useState('0');

const prepareNetTotal=()=>
{


var t = getTotalGross();
var tt = getTotalProductDiscount();
var c = couponDiscount;
var dc=deliveryCharges;

  setNetTotal( (+ (( ((getTotalGross()-getTotalProductDiscount())-couponDiscount)+deliveryCharges) )).toFixed(2));
}

const prepareTotalTax=()=>
{

return   (+(cartModel.lstCartProducts.reduce((taxTotal:any, product:any) => taxTotal+ (+product.tax), 0)))
}

useEffect(()=>
{
  prepareNetTotal();

},[alert,couponDiscount,deliveryCharges,getTotalProductDiscount(), cartModel.lstCartProducts])



const onSaveOrder=()=>
{




let address:any=selectedAddres;

if(typeof( address.pincode)!='undefined' &&  address.pincode!='')
{
  
let orderDetaisl:any={
  'customer_id':userState.loggedUser.customer_id,
  'ship_addres_type':address.addres_type,
  'ship_customer_full_name':address.full_name,
  'ship_phone_number':address.phone_number,
  'ship_alternate_phone_number':address.alternate_phone_number,
  'ship_pincode':address.pincode,
  'ship_state_id':address.state_id,
  'ship_city':address.city,

  'ship_house_no_bulding_name':address.house_no_bulding_name,
  'ship_road_area_colony':address.road_area_colony,
  'bill_customer_full_name':address.full_name,


  'bill_phone_number':address.phone_number,
  'bill_alternate_phone_number':address.alternate_phone_number,

  'bill_pincode':address.pincode,
  'bill_state_id':address.state_id,
  'bill_city':address.city,
  'bill_house_no_bulding_name':address.house_no_bulding_name,
  'bill_road_area_colony':address.road_area_colony,

  'gross_amount':getTotalGross(),

  'coupon_code':couponCode,

  'coupon_discount':couponDiscount,

  'order_discount':getTotalProductDiscount(),

  'order_tax':getTotalTax(),
'school_id':selectedSchool!=null?  selectedSchool.school_id:0,
  'delivery_charges':deliveryCharges,
  'net_total':netTotal,
  'payment_mode':'',
  'order_status':'Created',
  'email_id':userState.loggedUser.email_id,



}
// debugger;
// let pData=repository.prepareEncryptData(JSON.stringify(  {'order_id':1,'amount':netTotal,customer_id:userState.loggedUser.customer_id}))

// let storeOrder:any=  repository.prepareDecryptData(pData)
// debugger;

repository.saveOrder(orderDetaisl,cartModel.lstCartProducts).then((result:any)=>{
  
  if(result.length>0)
  {
      if(+(result[0].db_result)>0)
      {
 
cartModel.setCartProducts([]);

if((+netTotal)!=(+result[0].net_total))
{

  alert('Order Failed due to Parameter Manipulation..');

}else{
  debugger;
let pData=repository.prepareEncryptData(JSON.stringify(  {'order_id':result[0].order_id,'amount':netTotal,customer_id:userState.loggedUser.customer_id}))

dispatch({
  type: ActionType.STORE,
  payload: {
    storeData:pData ,
    viewName: 'PaymentOrder',
  },
});

        navigate('/paymentprocess');
}
      }else{
        if(+(result[0].db_result)==-8)
        {

          alert('Out of stock..')
        }
      }
    
    
    }


});
}else{
  alert('Select Delivery Address');
}

}


const applayCoupon=()=>
{
  
let couponCode:any=$('#txtCoupon').val();


repository.getDiscountFromCoupon(couponCode,getTotalGross()).then((result:any)=>{
  
  if(result.length>0)
  {

    setcouponCode(couponCode);

    calcCouponDiscount( result[0].discount_percentage ,result[0].max_discount_amount);
  }
}
  );


}

const calcCouponDiscount=(discountPer:any,  maxDiscountValue:any)=>
{
  
let grossVal:any=getTotalGross();
let probDiscount=(+ ((grossVal*discountPer)/100)).toFixed(2);
let discount:any=(maxDiscountValue>0 && (+maxDiscountValue)<(+probDiscount)) ?maxDiscountValue:probDiscount;
setcouponDiscount(discount) ;



}

const checkSelectedChildrens=(childrenId:any):boolean=>
{

let index=  selectedChildrens.findIndex(((x:any)=>x.children_id==childrenId));


return index==-1?false:true;
}
const couponRemove=()=>
{
  
  setcouponCode('');
  setcouponDiscount(0) ;
  $('#txtCoupon').val('');
}

    return {onSelectedChildChange,checkSelectedChildrens,selectedChildrens,onChildrenSave,handleChangeForChildren,childrenDetails,genderTypeChange,lstCustomerChildrens,couponRemove,couponCode,couponDiscount,applayCoupon,lstDiscounts,showPinCodeError, onPinCodeChange,onSaveOrder,netTotal,onSelectedAddressChange,selectedAddres,addressTypeChange,submited,lstStates,handleChangeForAddress,addressDetails,onAddressSave,lstCustomerAddress,cartModel}
}