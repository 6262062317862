import { BannerModel } from "./BannersModel";

const Banners:React.FC=()=>
{

  const  viewModel=BannerModel();
return(<section className="home-section-2 home-section-bg pt-0 overflow-hidden">
<div id="wowslider-container1">
    {/* <div className="ws_images"> */}
        
        <ul>

        {
viewModel.lstBanners.map((banner:any)=>
{

    return (<li><img src={viewModel.imagePath+banner.Image+'.webp'} alt="1" title="1" id="wows1_0"/></li>)
})

        }
            
            {/* <li><img src="/images/grocery/banner/1.jpg" alt="1-2" title="1-2" id="wows1_1"/></li>
            <li><a href="#"><img src="/images/grocery/banner/1-2.jpg" alt="#" title="1-3" id="wows1_2"/></a></li>
            <li><img src="/images/grocery/banner/1-3.jpg" alt="1-4" title="1-4" id="wows1_3"/></li>
        <li><img src="/images/grocery/banner/1-5.jpg" alt="1-4" title="1-4" id="wows1_3"/></li> */}

        </ul>
        {/* </div> */}
    <div className="ws_shadow"></div>
    </div>	
    <script type="text/javascript" src="/engine/wowslider.js"></script>
    <script type="text/javascript" src="/engine/script.js"></script>
    
</section>);

}

export default Banners;