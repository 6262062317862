import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { MyOrdersModel } from "./MyOrdersModel";

const OrderDetails:React.FC=()=>
{

 const viewModel=MyOrdersModel();
 const  selectedOrder =viewModel.storeSelectedOrder;
return (<>
<Header></Header>

        

 <div className="accordion-body">

 <div className="row w-100">
            <div className="col-xxl-4 col-xl-5 col-lg-6">
            <h5><strong className="f-22 p-10">Order ID : 
            <span className="text-dark">{selectedOrder.order_id}</span> </strong></h5>
            </div>
         
            <div className="col-xxl-4 col-xl-5 col-lg-6">
                <h5><strong className="f-22 p-10"> Date : <span className="text-dark">{selectedOrder.order_datetime}</span> </strong> </h5>
            </div>
             <div className="col-xxl-4 col-xl-5 col-lg-6">
             <h5><strong className="f-22 p-10"> Status : <span className="text-dark">{selectedOrder.order_status} </span> </strong> </h5>
             </div>
            </div>

<h3 className="f-22 p-10">Shipping Details</h3>
<div className="table-responsive">
    <table className="table border order-tab-table">
        <thead>
            <tr>
                <th>Full Name</th>
                <th>Email</th>
                <th>Address</th>
                <th>Apartment</th>
                <th>City</th>
                <th>State</th>
                <th>Zip Code</th>

            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{selectedOrder.ship_customer_full_name}</td>
                <td>{selectedOrder.email_id}</td>
                <td>{selectedOrder.ship_road_area_colony}</td>
                <td>{selectedOrder.ship_house_no_bulding_name}</td>
                <td>{selectedOrder.bill_city}</td>
                <td>{selectedOrder.state_name}</td>
                <td>{selectedOrder.ship_pincode}</td>
            </tr>

          
        </tbody>
    </table>
</div>

<h3 className="f-22 p-10">Product Details</h3>
<div className="table-responsive">
    <table className="table border order-tab-table">
        <thead>
            <tr>
                <th>Product</th>
                <th>Product Name</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>Total</th>
                <th>Return Qty</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
{
selectedOrder.orderDetails.map((orderPoducts:any,index:number)=>
{

return (

<tr>
<td className="product-detail">
<div className="product border-0">
    <img src={((viewModel.imagePath+orderPoducts.image)+'.webp')} className="img-fluid blur-up lazyloaded w-70" alt=""
     />
</div>
</td>
<td>{orderPoducts.title}<br></br>{orderPoducts.variants}</td>
<td>{orderPoducts.qty}</td>
<td>{orderPoducts.selling_price}</td>
<td>Rs {orderPoducts.product_total}</td>
<td> { (+orderPoducts.return_qty)+(+ viewModel.getreturnQty(orderPoducts.order_details_id))}</td>
<td>

<button style={(+orderPoducts.qty)-(+orderPoducts.return_qty)>0?{display:'block'}:{display:'none'}} data-bs-toggle="modal" data-bs-target="#product-return-replace" onClick={()=>viewModel.onReturnClick(orderPoducts,index)} className="btn btn-animation proceed-btn fw-bold mx-auto">Return </button>
</td>
</tr>

)

})
}



         

          
        </tbody>
    </table>
</div>
<div className="row" >
<div className="col-md-12" style={viewModel.lstReturnProducts.length>0?{display:"block"}:{display:"none"}}>
<button  style={  {width:"150px",float:"right"}} className="btn theme-bg-color view-button icon text-white fw-bold  btn-md"   
onClick={()=>viewModel.onSaveReturns()} ><i className="fas fa-solid fa-bolt"></i>&nbsp; SAVE RETURNS</button>


</div>


</div>

<div className="modal fade theme-modal" id="product-return-replace" tabIndex={-1} aria-labelledby="exampleModalLabel"
aria-hidden="true">
<div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
    <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel1">Return  </h5>
            <button type="button" className="btn-close" id="ctrReturnClose" data-bs-dismiss="modal" aria-label="Close">
                <i className="fa-solid fa-xmark"></i>
            </button>
        </div>
        <div className="modal-body">

<div className="row">
 <div className="col-md-12">
 <label>Reason</label>
 <select className="form-select mb-4" aria-label="Default select example" value={viewModel.returnType}
                                                onChange={(e)=>viewModel.onResonChange(e)}
                                                >
                                                    <option value="" selected>Select a Reason</option>
                                                    <option value="Wrong Product"> {"Wrong "+viewModel.strReturnDisplayVariants}</option>
                                                    <option value="Damaged or defective">Damaged or defective</option>
                                                    
                                                    <option value="others">others</option>
                                                  </select>
 </div>

 <div className="col-md-12">
    <label>Qty</label>
 <select className="form-select mb-4" aria-label="Default select example" 
                                                onChange={(e)=>viewModel.onReturnQtyChange(e)} value={viewModel.returnQty}
                                                >

                                                    {

viewModel.returnQtyList.map((qty,index:number)=>
{

    return (<option value={qty}>{qty}</option>);


})

                                                    }
                                                    
                                                    
                                                  </select>
 </div>


 <div className="col-md-12" style={viewModel.returnType=='others'?{display:'block'}:{display:'none'}}>
 <label>Comment</label>
                                                  
                                                        <textarea onChange={viewModel.onReson}  className="form-control" id="textAreaExample1" rows={4} placeholder="Your comment (optional)" ></textarea>
                                                     
                                              
 
                                                        </div>
</div>

<br/>
<div style={(viewModel.returnType =='others'  ) ?{display:'block'}:{display:'none'}} >
       
       <button   className="btn theme-bg-color view-button icon text-white fw-bold w-100 btn-md"   onClick={()=>viewModel.onReturnItemClick()} ><i className="fas fa-solid fa-bolt"></i>&nbsp; RETURN</button>
   </div>
<div className="row" style={(viewModel.returnType!='others' && viewModel.returnType!='') ?{display:'block'}:{display:'none'}} >
<label>Replace with</label>
                    <div className="col-xxl-12 col-xl-8 col-lg-7 wow fadeInUp">
                        <div className="row g-4">
                            <div className="col-xxl-12 col-xl-8 col-lg-7 wow fadeInUp">
                                <div className="product-left-box">
                                    <div className="row g-2">
                                        <div className="col-xxl-10 col-lg-12 col-md-10 order-xxl-2 order-lg-1 order-md-2">
                                            <div className="product-main-2 no-arrow">

                                            {

((typeof(viewModel.productDetails)!='undefined' &&   typeof((viewModel.productDetails as any).product_resources)!='undefined' &&  ((viewModel.productDetails as any).product_resources) !=''))?(

    (viewModel.productDetails as any).product_resources.map((resource:any,i:number)=>
    {
        


        
                                      return           (<div style={i>0?{display:'none'}:{display:'block',textAlign:"center"}}>
                                                     
                                                        <img src={((viewModel.imagePath+resource.resource_name)+'.webp')} id={'img-'+i+1}
                                                            data-zoom-image={((viewModel.imagePath+resource.resource_name)+'.webp')}
                                                            className="img-fluid image_zoom_cls-0 blur-up lazyload" alt=""/>
                                                     
                                                </div>)
    })
):''

}

                                            

                                
                                  
            
                                            
                                        </div>
    
                                        {/* <div className="col-xxl-2 col-lg-12 col-md-2 order-xxl-1 order-lg-2 order-md-1">
                                            <div className="left-slider-image-2 left-slider no-arrow slick-top">

                                            {

( typeof(viewModel.productDetails) !='undefined' &&  typeof((viewModel.productDetails as any).product_resources)!='undefined' &&  ((viewModel.productDetails as any).product_resources) !='')?(

    (viewModel.productDetails as any).product_resources.map((resource:any,i:number)=>
    {


        
                                           return (     <div>
                                                    <div className="slider-image">
                                                        <img src={((viewModel.imagePath+resource.resource_name)+'.webp')} id={'img-'+i+1}
                                                            data-zoom-image={((viewModel.imagePath+resource.resource_name)+'.webp')}
                                                            className="img-fluid blur-up lazyload" alt=""/>
                                                    </div>
                                                </div>)
    })
):''

}
                                  
                                                
                                            </div>
                                        </div> */}
                                        
                                    </div>
                                    
                                </div>
                            </div>
    
                            <div className="col-xl-7 wow fadeInUp" data-wow-delay="0.1s"   >
                                <div className="right-box-contain">
                                    {/* <h6 className="offer-top">30% Off</h6> */}
                                    <h2 className="name">{(viewModel.productDetails as any).title} </h2>
                                    <div className="price-rating">
                                        <h3 className="theme-color price">Rs {(viewModel.productDetails as any).selling_price} <del
                                        
                                        style={(+ (viewModel.productDetails as any).selling_price)!=(+(viewModel.productDetails as any).mrp) ? {display:'block'}:{display:'none'}}
                                        className="text-content">Rs {(viewModel.productDetails as any).mrp}</del>
                                         {/* <span className="offer theme-color">(8% off)</span> */}
                                                </h3>
                                        <div className="product-rating custom-rate">
                                            <ul className="rating">
                                                <li>
                                                    <i data-feather="star" className="fill"></i>
                                                </li>
                                                <li>
                                                    <i data-feather="star" className="fill"></i>
                                                </li>
                                                <li>
                                                    <i data-feather="star" className="fill"></i>
                                                </li>
                                                <li>
                                                    <i data-feather="star" className="fill"></i>
                                                </li>
                                                <li>
                                                    <i data-feather="star"></i>
                                                </li>
                                            </ul>
                                           
                                        </div>
                                    </div>
    
                                    <div className="procuct-contain">
                                        <p>{(viewModel.productDetails as any).brand_name} {(viewModel.productDetails as any).title}
                                         {viewModel.displayVarients((viewModel.productDetails as any).product_variants)}
                                        </p>
                                    </div>
    
                                  
    
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="product-packege">
                                                <div className="">
                                                    
                                                </div>

{
    viewModel.lstVariantGroup.map((variantg:any)=>
    {
return (<ul className="select-packege mt-20"  >
<h4 className="">{variantg.product_variants_title}</h4>

{viewModel.getVariantbyGroup(variantg.product_variants_title).map((variant:any)=>
{

    return (<li>
        <a  onClick={()=>  viewModel.onVaraintClick(variantg.product_variants_title, variant.product_variant_value)}  className={viewModel.getActiveClass(variantg.product_variants_title, variant.product_variant_value)} >{variant.product_variant_value}</a>
    </li>)
})

    }

 


</ul>);
    })
}

                                                
                                            </div>
                                        </div>


                                       
                                    </div>
                                   

                                    
                                   
                              
    
                                   
                                    
    
                                    <div className="pickup-box">
                                        <div className="product-title">
                                            <h4>Description</h4>
                                        </div>
    
                                        <div className="pickup-detail">
                                            <h4 className="text-content">{(viewModel.productDetails as any).notes}</h4>
                                        </div>
    
                                       

                                        

                                    </div>
                                    <br/>  <br/>
                                    {
                                        
(typeof (viewModel.productDetails)!='undefined' &&   (+(viewModel.productDetails as any).stock)>0)?
(
    <div style={{display:'flex',marginTop:'-2px'}} >
       
    <button id="btnCart"      className="btn theme-bg-color view-button icon text-white fw-bold w-100 btn-md" onClick={viewModel.onReplaceClick}><i className="fas fa-solid fa-bolt"></i>&nbsp; Add</button>
</div>
):'Not Available'

                                }
                                    
    
                                   
                                </div>
                            </div>
    
                          
                        </div>
                    </div>
    
                </div>

        </div>
        </div>

        </div>
        </div>
        </div>
</div> 
<Footer></Footer>
</> )


}
export default OrderDetails;