import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../store/Page.Actions";
import { useNavigate } from "react-router-dom";

export function UserState()
{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState({
        customer_id: 0,
        first_name: '',
        last_name: '',
        mobile_number: '',
        email_id: '',
        profile_image: '',
        password: '',
        re_password: '',
        
    });

const setUserState=(userDetails:any)=>
{
    //debugger;;
    dispatch({
        type: ActionType.STORE,
        payload: {
          storeData: userDetails,
          viewName: 'ETICustomer',
        },
      });
    //   if((+userDetails.customer_id)==0)
    //   {
    //     dispatch({
    //         type: ActionType.STORE,
    //         payload: {
    //           storeData: [],
    //           viewName: 'cart',
    //         },
    //       });
    //   }
}


const loggedUser = useSelector((state: AppState) =>
{
   
  return   typeof(state.storeData.ETICustomer)!='undefined'?state.storeData.ETICustomer:{
    customer_id: 0,
    first_name: '',
    last_name: '',
    mobile_number: '',
    email_id: '',
    profile_image: '',
    password: '',
    re_password: '',
    
}; 
}
);

const getUserState=()=>
{
    

    setUserDetails(loggedUser);


}

useEffect(()=>
{
    getUserState();
});


const onLogOut=()=>
{
    setUserState({
        customer_id: 0,
        first_name: '',
        last_name: '',
        mobile_number: '',
        email_id: '',
        profile_image: '',
        password: '',
        re_password: '',
        
    })
    navigate('/');

}

const onSignup=()=>
{
    navigate('/signup/home')
}
const onLogin=()=>
{
    navigate('/login')
}


    return {onLogin,onSignup,onLogOut,loggedUser,setUserState}
}