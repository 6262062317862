import { useNavigate } from "react-router-dom";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import { useEffect, useState } from "react";
import { CommonFunctionality } from "../../repository/CommonFunctionality";

export function SearchProductsModel()
{
    const commonFunctionality=CommonFunctionality()
    const repository =SchoolRepository();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lstStoreCat = useSelector((state: AppState) =>
    {
  
      return  typeof(state.storeData.categories)!='undefined'?state.storeData.categories:null;
    }
    );

    const lstStoreSclProducts = useSelector((state: AppState) =>
    {
      
      
    
      return  typeof(state.storeData.SchoolProducts)!='undefined'?state.storeData.SchoolProducts:[];
    }
    );


    const lstStoreClasses = useSelector((state: AppState) =>
    {
      
      
    
      return  typeof(state.storeData.SchoolClasses)!='undefined'?state.storeData.SchoolClasses:[];
    }
    );

    const[lstCategories,setCategories]=useState([]);
    useEffect(()=>
    {
        setCategories(lstStoreCat);

    },[lstStoreCat])

const[lstSclProducts,setSclProducts]=useState([]);

useEffect(()=>
{
    setSclProducts(lstStoreSclProducts);

},[lstStoreSclProducts])



    const[lstClasses,setClasses]=useState([]);
    useEffect(()=>
    {
        
        setClasses(lstStoreClasses);

    },[lstStoreClasses])
const [lstSearchProducts,setSearchProducts]=useState([]);
let timeout: ReturnType<typeof setTimeout> | null = null;

const onProductSearch=(e:any)=>
{

    if (timeout) {
        clearTimeout(timeout);
    }
    
    timeout = setTimeout(() => {
    if (e.target.value.length > 2) {
        //debugger;;
        setSearchProducts([]);
        repository.searchProducts(e.target.value).then((result:any)=>{



            let lstScProducts=[];
            if(result.length>0)
            {
    
    for(let i=0;i<result.length;i++)
    {
     //debugger;;    
       if((+ result[i].product_configurable)==1)
       {
     let indp=   lstSclProducts.findIndex((product:any)=>
        {
    
            return  (+product.product_id)===(+ result[i].product_id)
        })
    
    if(indp>-1)
    {
        lstScProducts.push(result[i]);
    }
       }
    
       else{
        lstScProducts.push(result[i]);
    }
       }
    }
            
     result=[...lstScProducts]


            setSearchProducts(result);


        });
    }else{
        setSearchProducts([]);
    }
}, 500);
}

useEffect(()=>
{
    setResultCategories(prepareCategoriesList(lstSearchProducts));

    setResultClasses(prepareClassList(lstSearchProducts));
},[lstSearchProducts])
const [lstResultCategories,setResultCategories]=useState([]);
const prepareCategoriesList=(lstSearchProductResult:any)=>
{
//debugger;;
    try {
        const uniqueCategoriesSet: Set<string> = new Set();
        const uniqueCategories: any = [];

        lstSearchProductResult.forEach((product: any) => {
            const  CategoryIdName = `${product.category_id}_${product.category_name}`;
            if (!uniqueCategoriesSet.has(CategoryIdName)) {
                uniqueCategoriesSet.add(CategoryIdName);
                uniqueCategories.push({ 'category_id': product.category_id, 'category_name': product.category_name });
            }
        });

        return uniqueCategories;
    } catch (error) {
        console.error("Error parsing JSON:", error);
        return [];
    }


}
const [lstResultClasses,setResultClasses]=useState([]);
const prepareClassList=(lstSearchProducts:any)=>
{

    const uniqueclassSet: Set<string> = new Set();
    const uniqueclasses: any = [];
    for(let i=0;i<lstSearchProducts.length;i++)
    {
         
       if((+ (lstSearchProducts[i] as any).product_configurable)==1)
       {
     let indp=   lstSclProducts.findIndex((product:any)=>
        {
    
            return ( ( (+product.product_id)===(+ (lstSearchProducts[i] as any).product_id) ))// &&    product.class==sclass)
        });

if(indp>-1)
{

    const  classIdName = `${(lstSclProducts[indp] as any).class}`;
            if (!uniqueclassSet.has(classIdName)) {
                uniqueclassSet.add(classIdName);
                uniqueclasses.push({ 'class':classIdName });
            }

}

    }
    }
    

    
    return uniqueclasses;

}
const  onCategoryClick=(categoryName:any)=>
{
$('#ctrProductSearchClose').click();
  navigate('/products/'+JSON.stringify( {'type':'category','name':categoryName}));
  
}
const onClassClicked=(className:any)=>
{
    $('#ctrProductSearchClose').click();
  navigate('/products/'+JSON.stringify( {'type':'class','name':className}));
}

const onProductClick=((products:any)=>
{
//debugger;;
 
    try{
    var product_feature_feilds=(((products.product_feature_feilds)));
    products.product_feature_feilds=commonFunctionality.stringFormatForJson(product_feature_feilds)
    }catch(e)
    {
////debugger;
    }
    try{
    var product_resources=(((products.product_resources)));
    products.product_resources=commonFunctionality.stringFormatForJson(product_resources)
}catch(e)
    {
      //  //debugger; 
    }
    try{
    var product_variants=(((products.product_variants)));
    products.product_variants=commonFunctionality.stringFormatForJson(product_variants)
    


}catch(e)
{
    ////debugger; 
}


try{
    var productDiscounts=(((products.product_discounts)));
    products.product_discounts=commonFunctionality.stringFormatForJson(productDiscounts)
     

}catch(e)
{
    ////debugger; 
}



//debugger;;


let selectedProduct:any=lstSearchProducts.filter((lproduct:any)=>
{
   return  (+lproduct.product_id)===(+products.product_id)
})
selectedProduct.map((product:any)=>{
    
    product.selected_product_details_id=products.product_details_id

});

    dispatch({
        type: ActionType.STORE,
        payload: {
            storeData:selectedProduct,
            viewName:'selectedProduct',
        },
      });
      $('#ctrProductSearchClose').click();
navigate('/productdetails');
window.location.reload();

});
    const imagePath= repository.ecommerceImagePath;
    return {onCategoryClick,onClassClicked,onProductClick,lstResultClasses,lstResultCategories,lstSearchProducts,onProductSearch,imagePath,lstCategories,lstClasses}
}